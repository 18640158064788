import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
import { CircularProgress } from "@mui/material";
import { Button, Typography, Snackbar, Alert } from "@mui/material";
import {
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  Box,
} from "@mui/material";
import CreateSeccionElectoralModal from "src/modal/CreateSeccionElectoralModal.js";
import BorrarSeccionElectModal from "src/modal/child/BorrarSeccionElectModal.js";
import { useMediaQuery, useTheme } from "@mui/material";
import { initialInfoActions } from "../redux/Initial_info_slice";
import { useSelector, useDispatch} from "react-redux";

export default function SeccionElectoralPage() {
  const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  if (!user_data) window.location.replace("/login");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const token = localStorage.getItem("token");
  const [modalOpen, setOpenModal] = useState(false);
  const [modalDelete, setOpenModalDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [SeccionesElectorales, setSeccionesElectorales] = useState([]);
  const [selectedSeccionElectoral, setSelectedSeccionElectoral] =
    useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const dispatch = useDispatch();

  // Alertas sucess - err
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");

  // Función para manejar clic en la fila de la tabla
  const handleOpenModalEdit = (seccion_electoral) => {
    setSelectedSeccionElectoral(seccion_electoral);
    setIsEdit(true);
    setOpenModal(true);
  };

  // MOSTRAR REGION
  const get_secciones_electorales = () => {
    axios
      .get(SERVER_URL + "/get_secciones_electorales/", {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        setLoading(false);
        setSeccionesElectorales(response.data.secciones_electorales);
        dispatch(initialInfoActions.addSeccionesElectorales({
          secciones_electorales: response.data.secciones_electorales
        }));
        console.log(SeccionesElectorales);
      })
      .catch((error) => {
        setLoading(false);
        setOpenSnackbar(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(error.response.data.error);
      });
  };

  //CREAR REGION
  const handleCreateSeccionElectoral = (nombre) => {
    axios
      .post(
        SERVER_URL + "/crear/seccion_electoral/",
        {
          nombre_seccion_electoral: nombre,
        },
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response) => {
        setOpenSnackbar(true);
        setSnackbarSeverity("success");
        setSnackbarMessage(response.data.msg);
        get_secciones_electorales();
      })
      .catch((error) => {
        setOpenSnackbar(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(error.response.data.error);
      });
  };

  //EDITAR REGION
  const handleEditSeccionElectoral = (seccion_electoral, nombre) => {
    axios
      .put(
        SERVER_URL + "/actualizar/seccion_electoral/",
        {
          id: seccion_electoral.id,
          nombre_seccion_electoral: nombre,
        },
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response) => {
        setOpenSnackbar(true);
        setSnackbarSeverity("success");
        setSnackbarMessage(response.data.msg);
        get_secciones_electorales();
      })
      .catch((error) => {
        setOpenSnackbar(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(error.response.data.error);
        console.log(error.error);
      });
  };

  //BORRAR REGION
  const handleDeleteSeccionElectoral = (id) => {
    axios
      .put(
        SERVER_URL + "/eliminar/seccion_electoral/",
        {
          id,
        },
        { headers: { Authorization: "Token " + token } }
      )
      .then((response) => {
        setOpenSnackbar(true);
        setSnackbarSeverity("success");
        setSnackbarMessage(response.data.msg);
        dispatch(initialInfoActions.addSeccionesElectorales({
          secciones_electorales: SeccionesElectorales.filter((seccionDB) => seccionDB.id !== id)
        }));

        setSeccionesElectorales(
          SeccionesElectorales.filter((seccionDB) => seccionDB.id !== id)
        );
      })
      .catch((error) => {
        setSnackbarSeverity("error");
        setSnackbarMessage("¡Hubo un error inesperado!");
      });
  };

  useEffect(() => {
    get_secciones_electorales();
  }, []);

  return (
    <>
      <Helmet>
        <title> Secciones | Lista </title>
      </Helmet>

      <Container
        maxWidth="xl"
        className="MuiContainer-root MuiContainer-fullWidth"
      >
          <Stack
					direction={isMobile ? 'column' : 'row'} 
					alignItems={isMobile ? 'flex-start' : 'center'} 	
      		justifyContent={isMobile ? 'flex-start' : 'space-between'} en dispositivos móviles
          mt={isMobile ? 4 : 0} 
					mb={5}
				>  
          <Typography variant="h4" gutterBottom>
            Secciones Electorales
          </Typography>

          {user_data.group === "administrator" && (
            <Button
              variant="contained"
              onClick={() => {
                setOpenModal(true);
                setIsEdit(false);
              }}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Nueva seccion electoral
            </Button>
          )}

          <CreateSeccionElectoralModal
            open={modalOpen}
            onClose={() => setOpenModal(false)}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            selectedSeccionElectoral={selectedSeccionElectoral}
            title={
              isEdit ? "Editar seccion electoral" : "Crear seccion electoral"
            }
            handleEditSeccionElectoral={handleEditSeccionElectoral}
            handleCreateSeccionElectoral={handleCreateSeccionElectoral}
          />
        </Stack>

        <Card
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 2, height: 540 }}>
              <CircularProgress />
            </Box>
          ) : SeccionesElectorales && SeccionesElectorales.length > 0 ? (
            <Scrollbar sx={{ p: 1 }}>
              <TableContainer sx={{ overflow: "auto" }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ height: "83px" }}>
                      <TableCell>Nombre</TableCell>
                      <TableCell
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "83px",
                        }}
                      >
                        Acciones
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {SeccionesElectorales.map((seccion_electoral) => (
                      <React.Fragment key={seccion_electoral.id}>
                        <TableRow
                          sx={{
                            "&:hover": {
                              backgroundColor: "rgba(99, 115, 129, 0.08)",
                            },
                            height: "35px",
                          }}
                        >
                          <TableCell
                            onClick={() => handleOpenModalEdit(seccion_electoral)}
                            label
                          >
                            {seccion_electoral.nombre}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <Button
                              sx={{ m: 1 }}
                              variant="outlined"
                              color="error"
                              onClick={(e) => {
                                setSelectedSeccionElectoral(seccion_electoral);
                                setOpenModalDelete(true);
                              }}
                            >
                              <Iconify icon="bi:trash" />
                            </Button>
                            <Button sx={{ m: 1 }}variant="outlined" color="primary" onClick={() => handleOpenModalEdit(seccion_electoral)}>
                              <Iconify icon="bi:pencil" />
                            </Button>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 2, height: 540 }}>
              <Typography variant="subtitle1">No hay secciones electorales</Typography>
            </Box>
          )}
        </Card>



        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={() => setOpenSnackbar(false)}
          message={snackbarMessage}
        >
          <Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
        </Snackbar>
        <BorrarSeccionElectModal
          open={modalDelete}
          onClose={() => setOpenModalDelete(false)}
          handleDeleteSeccionElectoral={handleDeleteSeccionElectoral}
          selectedSeccionElectoral={selectedSeccionElectoral}
        />
      </Container>
    </>
  );
}
