import Iconify from '../iconify/Iconify';
import { useRef, useState, useEffect } from 'react';
import DateTime from 'react-datetime';
import { Card, Table, InputLabel, Stack, Alert, FormControl, Chip, Snackbar, Select, TableHead, Paper, Avatar, Button, Popover, Box, Checkbox, Grid, TableRow, TextField, MenuItem, TableBody, TableCell, Container, Typography, IconButton, TableContainer, TablePagination, Modal, } from '@mui/material';
import CommonFiltersBar from '../../common/common-filters-bar';
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import CommonReports from 'src/common/common-reports';
import { useMediaQuery, useTheme } from "@mui/material";


export default function FiltrosSolicitud(props) {
    const store_municipios = useSelector((state) => state.initial_info.municipios);
    const store_areas = useSelector((state) => state.initial_info.areas);
    const store_secciones_electorales = useSelector((state) => state.initial_info.secciones_electorales);
    const store_user = useSelector((state) => state.user);

    const [fechaDesde, setFechaDesde] = useState(new Date(Date.now() - 21 * 24 * 60 * 60 * 1000));
    const [fechaHasta, setFechaHasta] = useState(new Date());
    const [reRender, setReRender] = useState(0);
    const [municipioFilter, setMunicipioFilter] = useState('todos');
    const [areaResponsableFilter, setAreaResponsableFilter] = useState('todas las areas');
    const [estadoFilter, setEstadoFilter] = useState('todos');
    const [seccionElectoral, setSeccionElectoral] = useState('todos');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const token = localStorage.getItem("token");

    function convertirFecha(fecha) {
        const fechaDt = new Date(fecha);
        const fechaStr = fechaDt.toISOString().replace('T', ' ').replace('Z', ' GMT+0000');
        return fechaStr;
    }


    const dataToFetch = {
        municipio: municipioFilter !== 'todos' ? municipioFilter : null,
        area_responsable: areaResponsableFilter !== 'todas las areas' ? areaResponsableFilter : null,
        fecha_desde: fechaDesde ? convertirFecha(fechaDesde) : null,
        fecha_hasta: fechaHasta ? convertirFecha(fechaHasta) : null,
        estado_solicitud: estadoFilter !== 'todos' ? estadoFilter : null,
        seccion_electoral: seccionElectoral !== 'todos' ? seccionElectoral : null
    }

    useEffect(() => {
    }, [store_areas])


    const cleanFilters = () => {
        setMunicipioFilter('todos');
        setAreaResponsableFilter('todas las areas');
        setFechaDesde(new Date(Date.now() - 21 * 24 * 60 * 60 * 1000));
        setFechaHasta(new Date());
        setEstadoFilter('todos');
        setSeccionElectoral('todos');
        setReRender(reRender + 1);
    }


    const handleSearchAction = () => {
        props.setIsLoadingFiltros(true)
        const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV
        const token = localStorage.getItem('token');
        axios.get(SERVER_URL + '/' + props.urlToFetch, {
            params: dataToFetch,
            headers: {
                'Authorization': 'Token ' + token
            }
        }).then(function (response) {
            props.executeCallback(response)
            props.setIsLoadingFiltros(false)
        })
            .catch(function (error) {
                // Manejar el error aquí
                props.setIsLoadingFiltros(false)
            });
    }



    const filtrosToRender = () => {
        return (
            <>
                <Grid container sx={{ display: 'flex', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'left' : 'flex-end', }}>

                    <FormControl variant="outlined" sx={{ m: 1, width: 220 }}>
                        <InputLabel id="filtro-label">Filtrar por estado</InputLabel>
                        <Select
                            labelId="filtro-label"
                            value={estadoFilter}
                            onChange={(event) => setEstadoFilter(event.target.value)}
                            label="Filtrar por estado"
                            startAdornment={
                                <Iconify
                                    style={{ width: '35px', height: '35px', color: '#2065D1' }}
                                    icon="material-symbols:filter-list" />
                            }
                        >
                            <MenuItem value="todos">Todos</MenuItem>
                            <MenuItem value="Analisis">En Analisis</MenuItem>
                            <MenuItem value="Aprobado">Aprobado</MenuItem>
                            <MenuItem value="Desaprobado">Desaprobado</MenuItem>
                            <MenuItem value="Finalizado">Finalizado</MenuItem>
                            <MenuItem value="Otro">Otros</MenuItem>

                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" sx={{ m: 1, width: 220 }}>
                        <InputLabel id="filtro-label">Filtrar por Municipio</InputLabel>
                        <Select
                            labelId="filtro-label"
                            value={municipioFilter}
                            onChange={(event) => setMunicipioFilter(event.target.value)}
                            label="Filtrar por Municipio"
                            startAdornment={
                                <Iconify
                                    style={{ width: '35px', height: '35px', color: '#2065D1' }}
                                    icon="material-symbols:filter-list" />
                            }
                        >
                            <MenuItem value="todos">Todos</MenuItem>
                            {store_municipios &&
                                store_municipios
                                    .slice()
                                    .sort((a, b) => a.nombre.localeCompare(b.nombre))
                                    .map((municipio) => {
                                        return <MenuItem key={municipio.id} value={municipio.nombre}>{municipio.nombre}</MenuItem>
                                    })}

                        </Select>
                    </FormControl>


                    <FormControl variant="outlined" sx={{ m: 1, width: 220 }}>
                        <InputLabel id="filtro-label">Filtrar por Seccion Electoral</InputLabel>
                        <Select
                            labelId="filtro-label"
                            value={seccionElectoral}
                            onChange={(event) => setSeccionElectoral(event.target.value)}
                            label="Filtrar por Seccion Electoral"
                            startAdornment={
                                <Iconify
                                    style={{ width: '35px', height: '35px', color: '#2065D1' }}
                                    icon="material-symbols:filter-list" />
                            }
                        >
                            <MenuItem value="todos">Todas las secciones</MenuItem>
                            {store_secciones_electorales && store_secciones_electorales.map((seccion_electoral) => {
                                return <MenuItem key={seccion_electoral.id} value={seccion_electoral.nombre}>{seccion_electoral.nombre}</MenuItem>
                            })}
                        </Select>
                    </FormControl>

                    {
                        store_user.user_group == 'administrator' ?
                            <FormControl variant="outlined" sx={{ m: 1, width: 220, fontSize: '12px' }}>
                                <InputLabel id="filtro-label">Filtrar por Área</InputLabel>
                                <Select
                                    labelId="filtro-label"
                                    value={areaResponsableFilter}
                                    onChange={(event) => setAreaResponsableFilter(event.target.value)}
                                    label="Filtrar por Area"
                                    startAdornment={
                                        <Iconify
                                            style={{ width: '35px', height: '35px', color: '#2065D1' }}
                                            icon="material-symbols:filter-list" />
                                    }
                                >
                                    <MenuItem value="todas las areas">Todas las áreas</MenuItem>
                                    {store_areas && store_areas.map((area) => {
                                        return <MenuItem key={area.id} value={area.nombre}>{area.nombre}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            : ''
                    }
                    <Stack direction={isMobile ? 'column' : 'row'} >
                        <FormControl sx={{ m: 1 }} fullWidth>
                            <InputLabel shrink={true} htmlFor="fecha-desde-label" sx={{ marginTop: '-5px', marginLeft:'-6px' }}>
                                Fecha Desde
                            </InputLabel>
                            <DateTime
                                utc={true}
                                className="datetimeTable"
                                timeFormat={false}
                                // defaultValue={fechaDesde}
                                value={fechaDesde}
                                onChange={(nuevaFecha) => { setFechaDesde(nuevaFecha) }}
                                dateFormat="DD/MM/YYYY"
                                timeZone="America/Argentina/Buenos_Aires"
                                inputProps={{ placeholder: 'Fecha desde', readOnly: true, width:220 }}
                            />
                        </FormControl>

                        <FormControl sx={{ m: 1 }} fullWidth>
                            <InputLabel shrink={true} htmlFor="fecha-desde-label" sx={{ marginTop: '-5px',marginLeft:'-6px' }}>
                                Fecha Hasta
                            </InputLabel>
                            <DateTime
                                utc={true}
                                className="datetimeTable"
                                timeFormat={false}
                                // defaultValue={fechaHasta}
                                value={fechaHasta}
                                onChange={(nuevaFecha) => { setFechaHasta(nuevaFecha) }}
                                dateFormat="DD/MM/YYYY"
                                timeZone="America/Argentina/Buenos_Aires"
                                inputProps={{ placeholder: 'Fecha hasta', readOnly: true }}
                            />
                        </FormControl>

                    </Stack>

                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: isMobile ? 'left' : 'center', flexDirection: isMobile ? 'column' : 'row', ml: 1 }}>
                        <Button variant="outlined" color="primary" sx={{ width: '170px', height: 56, ml: 1, m: isMobile ? 1 : 0 }} onClick={() => handleSearchAction()}>
                            <Iconify
                                style={{ width: '23px', height: '23px', color: '#2065D1' }}
                                icon="mi:filter" />

                            Aplicar filtros
                        </Button>

                        <Button variant="outlined" color="primary" sx={{ width: '170px', height: 56, ml: 1 }} onClick={() => cleanFilters()}>
                            <Iconify
                                style={{ width: '23px', height: '23px', color: '#2065D1' }}
                                icon="mdi:remove-outline" />

                            Limpiar filtros
                        </Button>

                        {
                            store_user.user_group == 'administrator' || store_user.user_group == 'extendedvalidator' ?
                                <CommonReports
                                    dataToFetch={dataToFetch}
                                    firstSolicitud={props.firstSolicitud}
                                    lastSolicitud={props.lastSolicitud}
                                /> : ''
                        }

                    </Box>
                </Grid>


            </>
        );
    }


    return (
        <>
            <CommonFiltersBar
                filtersToRender={filtrosToRender}
                dataToFetch={dataToFetch}
                urlToFetch={props.urlToFetch}
                executeCallback={props.executeCallback}
                cleanFilters={cleanFilters}
            />
        </>
    );
}
