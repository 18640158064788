import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
import { CircularProgress } from "@mui/material";
import { Button, Typography, Snackbar, Alert } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import { initialInfoActions } from "../redux/Initial_info_slice";
import { useSelector, useDispatch} from "react-redux";

import {
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  Box,
} from "@mui/material";
import CreateAreaModal from "src/modal/CreateAreaModal.js";
import BorrarAreaModal from "src/modal/child/BorrarAreaModal.js";

export default function AreaPage() {
  const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  if (!user_data) window.location.replace("/login");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch()
  
  const token = localStorage.getItem("token");
  const [modalOpen, setOpenModal] = useState(false);
  const [Areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [modalDelete, setOpenModalDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  // Alertas sucess - err
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");

  // Función para manejar clic en la fila de la tabla
  const handleOpenModalEdit = (area) => {
    setSelectedArea(area);
    setIsEdit(true);
    setOpenModal(true);
  };

  const get_areas = () => {
    axios
      .get(SERVER_URL+"/get_areas/", {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        setLoading(false);
        dispatch(initialInfoActions.addAreas({
          areas: response.data.areas
        }));

        setAreas(response.data.areas);
        console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);
        setOpenSnackbar(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(error.response.data.error);
        console.log(error.response.data.error);
      });
  };

  //CREAR AREA
  const handleCreateArea = (nombre) => {
    axios
      .post(
        SERVER_URL+"/crear/area/",
        {
          nombre_area: nombre,
        },
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response) => {
        setOpenSnackbar(true);
        setSnackbarSeverity("success");
        setSnackbarMessage("El area ha sido creada con éxito.");
        get_areas();
        console.log("Recurso creado exitosamente:", response.status);
      })
      .catch((error) => {
        setOpenSnackbar(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(error.response.data.error);
        console.log(error.response.data.error, error.response.status);
      });
  };

  //EDITAR AREA
  const handleEditArea = (area, nombre) => {
    axios
      .put(
        SERVER_URL+"/actualizar/area/",
        {
          id: area.id,
          nombre_area: nombre,
        },
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response) => {
        setOpenSnackbar(true);
        setSnackbarSeverity("success");
        setSnackbarMessage("¡El area se ha actualizado con éxito!");
        get_areas();
        console.log("Recurso actualizado exitosamente:", response.status);
      })
      .catch((error) => {
        setOpenSnackbar(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(error.response.data.error);
        console.log(error.error);
      });
  };

  //BORRAR AREA
  const handleDeleteArea = (id) => {
    axios
      .put(
        SERVER_URL+"/eliminar/area/",
        { id },
        { headers: { Authorization: "Token " + token } }
      )
      .then((response) => {
        setOpenSnackbar(true);
        setSnackbarSeverity("success");
        setSnackbarMessage("¡El area se ha borrado con éxito!");
        dispatch(initialInfoActions.addAreas({
          areas: Areas.filter((areaDB) => areaDB.id !== id)
        }));

        setAreas(Areas.filter((areaDB) => areaDB.id !== id));
      })
      .catch((error) => {
        setOpenSnackbar(true);
        setSnackbarSeverity("error");
        setSnackbarMessage("¡Hubo un error inesperado!");
        console.log(error);
      });
  };

  useEffect(() => {
    get_areas();
  }, []);

  return (
    <>
      <Helmet>
        <title> Area | Lista </title>
      </Helmet>

      <Container
        maxWidth="xl"
        className="MuiContainer-root MuiContainer-fullWidth"
      >
          <Stack
					direction={isMobile ? 'column' : 'row'} 
					alignItems={isMobile ? 'flex-start' : 'center'} 	
      		justifyContent={isMobile ? 'flex-start' : 'space-between'} en dispositivos móviles
          mt={isMobile ? 4 : 0} 

					mb={5}
				>  
          <Typography variant="h4" gutterBottom>
            Areas
          </Typography>

          {user_data.group === "administrator" && (
            <Button
              variant="contained"
              onClick={() => {
                setOpenModal(true);
                setIsEdit(false);
              }}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Nueva area
            </Button>
          )}

          <CreateAreaModal
            open={modalOpen}
            onClose={() => setOpenModal(false)}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            selectedArea={selectedArea}
            title={isEdit ? "Editar area" : "Crear area"}
            handleEditArea={handleEditArea}
            handleCreateArea={handleCreateArea}
          />
        </Stack>

        <Card
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {loading ? (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 2, height: 540 }}>
          <CircularProgress />
        </Box>
      ) : Areas && Areas.length > 0 ? (
        <Scrollbar sx={{ p: 1 }}>
          <TableContainer sx={{ overflow: "auto" }}>
            <Table>
              <TableHead>
                <TableRow sx={{ height: "83px" }}>
                  <TableCell>Nombre</TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "83px",
                    }}
                  >
                    Acciones
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Areas.map((area) => (
                  <React.Fragment key={area.id}>
                    <TableRow
                      sx={{
                        "&:hover": {
                          backgroundColor: "rgba(99, 115, 129, 0.08)",
                        },
                        height: "35px",
                      }}
                    >
                      <TableCell
                        onClick={() => handleOpenModalEdit(area)}
                        label
                      >
                        {area.nombre}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <Button
                          sx={{m:1}} 
                          variant="outlined"
                          color="error"
                          onClick={(e) => {
                            setSelectedArea(area);
                            setOpenModalDelete(true);
                          }}
                        >
                          <Iconify icon="bi:trash" />
                        </Button>
                        <Button sx={{m:1}} variant="outlined" color="primary" onClick={() => handleOpenModalEdit(area)}>
																		<Iconify icon="bi:pencil" />
																	</Button>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 2, height: 540 }}>
          <Typography variant="subtitle1">No hay areas</Typography>
        </Box>
      )}
    </Card>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          message={snackbarMessage}
        >
          <Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
        </Snackbar>
        <BorrarAreaModal 
            open={modalDelete}
            onClose={() => setOpenModalDelete(false)}
            handleDeleteArea={handleDeleteArea}
            selectedArea = {selectedArea}
            />
      </Container>
    </>
  );
}
