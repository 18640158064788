// @mui
import { styled } from '@mui/material/styles';
import { useRef, useState } from 'react';
import axios from 'axios';
import DateTime from 'react-datetime';
import { Card, Table, InputLabel, Stack, Alert, FormControl, Chip, Snackbar, Select, TableHead, Paper, Avatar, Button, Popover, Box, Checkbox, Grid, TableRow, TextField, MenuItem, TableBody, TableCell, Container, Typography, IconButton, TableContainer, TablePagination, Modal, } from '@mui/material';



// ----------------------------------------------------------------------

const textFieldSearchBarStyles = {
  width: '500px',
  m: 2,
  ml: 0
}

const containerStyles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}

// ----------------------------------------------------------------------

export default function CommonFiltersBar(props) {
  const token = localStorage.getItem('token');
  const handleSearchAction = () => {
  const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV

    axios.get(SERVER_URL + '/' + props.urlToFetch, {
      params: props.dataToFetch,
      headers: {
        'Authorization': 'Token ' + token
      }
    })
      .then(function (response) {
        props.executeCallback(response)
      })
      .catch(function (error) {
        // Manejar el error aquí
      });
  }

  return (
    <>
      <Box sx={containerStyles}>
        {
          props.filtersToRender()
        }

        {/* <Button variant="outlined" color="primary" sx={{ width: '200px', height:56, m:1 }} onClick={() => handleSearchAction()}>
          <Iconify
            style={{ width: '30px', height: '30px', color: '#2065D1' }}
            icon="material-symbols:filter-list" />

          Aplicar filtros
        </Button> */}

        {/* <Button variant="outlined" color="primary" sx={{ width: '200px', height:56, m:1 }} onClick={() => props.cleanFilters()}>
          <Iconify
            style={{ width: '30px', height: '30px', color: '#2065D1' }}
            icon="material-symbols:filter-list" />

          Limpiar filtros
        </Button> */}

      </Box>
    </>
  );
}
