import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Link, useNavigate } from 'react-router-dom';
import L from 'leaflet';
import { Container, Box, CircularProgress, TextField, Button } from '@mui/material';
import './mapa-de-solicitudes.css';
import axios from 'axios';
import Iconify from '../../components/iconify';

const MarkerPopup = ({ municipio }) => {
  return (
    <div>
      <h3>{municipio.nombre}</h3>
      <p>Cantidad de solicitudes finalizadas: {municipio.totalSolicitudes}</p>
      <Link to={{ pathname: `/municipio/${municipio.id}`, state: { municipio: municipio } }}>
        Ver detalles
      </Link>
    </div>
  );
};

export default function Mapa() {
  const token = localStorage.getItem('token');
  const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV;
  const navigate = useNavigate();
  const mapRef = useRef(null);

  const [municipiosBuenosAires, setMunicipiosBuenosAires] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMarkerId, setSelectedMarkerId] = useState(null);
  const [selectedMunicipio, setSelectedMunicipio] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleMarkerClick = (markerId) => {
    setSelectedMarkerId(markerId);
    const selectedMunicipio = municipiosBuenosAires.find((municipio) => municipio.id === markerId);
    setSelectedMunicipio(selectedMunicipio);

    // Hacer zoom en el municipio buscado
    if (selectedMunicipio && mapRef.current) {
      const { lat, lng } = selectedMunicipio;
      mapRef.current.flyTo([lat, lng], zoomLevel);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    const searchResults = searchMunicipios(searchTerm);
    setSearchResults(searchResults);
  };

  const handleRestoreSearch = () => {
    setSearchTerm('');
    setSearchResults([]);
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(SERVER_URL + '/get_municipios_to_map/', {
        headers: {
          Authorization: 'Token ' + token,
        },
      })
      .then((response) => {
        setMunicipiosBuenosAires(response.data);
      })
      .catch((error) => {
        console.error('Error al obtener los municipios:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const searchMunicipios = (term) => {
    const normalizedTerm = term.toLowerCase().trim();
    return municipiosBuenosAires.filter((municipio) =>
      municipio.nombre.toLowerCase().includes(normalizedTerm)
    );
  };

  const position = [-35.92545786569512, -60.9412978374629];
  const zoomLevel = 6.5;

  const markers = (searchResults.length > 0 ? searchResults : municipiosBuenosAires).map(
    (municipio) => {
      const lat = parseFloat(municipio.latitud);
      const lng = parseFloat(municipio.longitud);

      if (isNaN(lat) || isNaN(lng)) {
        return null;
      }

      return {
        lat,
        lng,
        nombre: municipio.nombre,
        totalSolicitudes: municipio.total_solicitudes,
        solicitudes: municipio.solicitudes,
        id: municipio.id,
      };
    }
  );

  const validMarkers = markers.filter((marker) => marker !== null);

  return (
    <Container>
      {isLoading ? (
        <Box
          sx={{ width: '100%', height: 500, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', mb:1 }}>
            <form onSubmit={handleSearchSubmit}>
              <TextField
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Buscar municipios"
              />
              <Button sx={{height:56}} type="submit"> <Iconify
            style={{ width: '30px', height: '30px', color: '#2065D1' }}
            icon="ic:baseline-manage-search"
          /></Button>
            </form>
            {searchResults.length > 0 && (
              <Button  sx={{height:56}} onClick={handleRestoreSearch}>Restaurar búsqueda</Button>
            )}
          </Box>
          <MapContainer
            className={'mapa'}
            center={position}
            zoom={zoomLevel}
            scrollWheelZoom={false}
            whenCreated={(map) => (mapRef.current = map)}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {validMarkers.map((marker, index) => (
              <Marker
                key={index}
                position={{ lat: marker.lat, lng: marker.lng }}
                onClick={() => handleMarkerClick(marker.id)}
              >
                <Popup>
                  <MarkerPopup municipio={marker} />
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </>
      )}
    </Container>
  );
}
