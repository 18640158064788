import React, { useState, useEffect } from "react";
import {
  Grid,
  Modal,
  TextField,
  Button,
  Box,
  Typography,
  Snackbar,
  Alert,
  CircularProgress
} from "@mui/material";

import axios from "axios";
import { userActions } from "src/redux/userSlice";
import { useDispatch, useSelector} from "react-redux";

export default function EditProfileUserModal({
  openEditProfileModal,
  setOpenEditProfileModal,
  title
}) {

  const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV
  const token = localStorage.getItem("token");
  const logedUser = useSelector((state) => state.user);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const dispatch = useDispatch();


  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = () => {
    setIsButtonLoading(true);
    axios.put(SERVER_URL + "/actualizar/perfil/",
      {
        id: logedUser.id,
        username: username,
        password: password,
        first_name: nombre,
        last_name: apellido,
        email: email

      },
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
      .then((response) => {
        setIsButtonLoading(false);
        setOpenSnackbar(true);
        setSnackbarSeverity("success");
        setSnackbarMessage(response.data.msg);
        setOpenEditProfileModal(false);
        dispatch(
          userActions.updateUserData({
            username: response.data.user.username,
            email: response.data.user.email,
            first_name: response.data.user.first_name,
            last_name: response.data.user.last_name,
            id: logedUser.id,
            user_group: logedUser.user_group
          })
        );

      })
      .catch((error) => {
        setIsButtonLoading(false);
        setOpenSnackbar(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(error.response.data.error);
        setOpenEditProfileModal(false);
      });
  };

  function onClose() {
    setOpenEditProfileModal(false);
    setNombre(logedUser.first_name);
    setApellido(logedUser.last_name);
    setUsername(logedUser.username);
    setEmail(logedUser.email);
  }

  useEffect(() => {
    setNombre(logedUser.first_name);
    setApellido(logedUser.last_name);
    setUsername(logedUser.username);
    setEmail(logedUser.email);
  }, [logedUser])
  


  return (<>
    <Modal open={openEditProfileModal} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#f4f6f8",
            padding: "40px",
            borderRadius: "5px",
            width: "90%",
            maxWidth: "600px",
          }}
        >
          <Typography variant="h4" sx={{ marginBottom: 4, textAlign: "left" }}>
            {title}
          </Typography>

          <Grid
            container
            justifyContent="center"
            direction="column"
            spacing={2}
          >
            <Grid item>
              <TextField
                label="Nombre"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                sx={{ width: "100%" }}
              />
            </Grid>

            <Grid item>
              <TextField
                label="Apellido"
                value={apellido}
                onChange={(e) => setApellido(e.target.value)}
                sx={{ width: "100%" }}
              />
            </Grid>

            <Grid item>
              <TextField
                label="Usuario"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                sx={{ width: "100%" }}
              />
            </Grid>

            <Grid item>
              <TextField
                label="Usuario"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ width: "100%" }}
              />
            </Grid>

            <Grid item>
              <TextField
                label="Contraseña"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ width: "100%" }}
              />
            </Grid>

            <Grid container justifyContent="flex-end" spacing={2} sx={{ marginTop: 4 }}>
              <Grid item>
                <Button onClick={onClose} variant="outlined" fullWidth>
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
              <Button onClick={onSubmit} type="submit" variant="contained" fullWidth disabled={isButtonLoading}>
                {isButtonLoading ? <CircularProgress size={24} /> : 'Guardar cambios'}
              </Button>
              </Grid>
            </Grid>
          </Grid>

        </Box>
      </Box>


    </Modal>

    <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={openSnackbar} autoHideDuration={3000} onMouseEnter={() => setOpenSnackbar(false)} onClose={() => setOpenSnackbar(false)}>
      <Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
    </Snackbar>
  </>
  );
}
