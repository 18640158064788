import React from "react";
import { styled } from "@mui/material/styles";
import { Fade, Backdrop, Modal, Button } from '@mui/material';


const ModalWrapper = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});


const ModalComponent = ({ isOpen, handleClose, children, title, onConfirm, onCancel }) => {
    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            closeAfterTransition
        >
            <Fade in={isOpen}>
                <div
                    style={{
                        backgroundColor: "#fff",
                        border: "2px solid #000",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        padding: "20px",
                        position: "absolute",
                        bottom: "50%",
                        left: "50%"
                    }}
                >
                    <h2>{title}</h2>
                    {children}
                    <div style={{ marginTop: "20px" }}>
                        <Button variant="contained" onClick={onConfirm}>
                            Aceptar
                        </Button>
                        <Button
                            variant="contained"
                            style={{ marginLeft: "20px" }}
                            onClick={onCancel}
                        >
                            Cancelar
                        </Button>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default ModalComponent;


//PARA USAR ESTE COMPONENTE DEBE HACERSE LO SIGUIENTE 
{/* 
COMPONENTE + BOTON 
<Box>
    <button onClick={handleOpen}>Abrir modal</button>
    <ModalComponent
        isOpen={open}
        handleClose={handleClose}
        title="Mi Modal"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
    >
        <p>Contenido del modal</p>
    </ModalComponent>
</Box> 

FUNCIONES DE LAS PROPS: 
 const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    console.log("Se ha confirmado el modal");
    handleClose();
  };

  const handleCancel = () => {
    console.log("Se ha cancelado el modal");
    handleClose();
  };

*/}