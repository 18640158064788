import React, { useState } from "react";
import { Grid, Modal, TextField, Button, Box, Typography } from "@mui/material";


export default function CreateGobiernoModal({
  open,
  onClose,
  isEdit,
  selectedGobierno,
  title,
  handleEditGobierno,
  handleCreateGobierno,
}) {
  const [nombre, setNombre] = useState("");

  //Indistintamente si isEdit o no , setNombre se establece vacio
  function onSubmit() {
    setNombre("");
    if (isEdit) {
      handleEditGobierno(selectedGobierno, selectedGobierno.nombre);
      onClose();
    } else {
      handleCreateGobierno(nombre);
      onClose();
    }
  }

  return (         
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#f4f6f8",
            padding: "40px",
            borderRadius: "5px",
            width: "100%",
            maxWidth: "600px",
          }}
        >
          <Typography variant="h4" sx={{ marginBottom: 4, textAlign: "left" }}>
            {title}
          </Typography>

          <Grid
            container
            justifyContent="center"
            direction="column"
            spacing={2}
          >
            <Grid item>
              <TextField
                label="Nombre"
                defaultValue={isEdit ? selectedGobierno.nombre : ""}
                onChange={(event) => setNombre(event.target.value)}
                fullWidth
              />
            </Grid>

            <Grid container justifyContent="flex-end" spacing={2} sx={{ marginTop: 4 }}>
              <Grid item>
                <Button onClick={onClose} variant="outlined" fullWidth>
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={onSubmit} type="submit" variant="contained" fullWidth>
                  {isEdit ? "Editar" : "Crear"}
                </Button>
              </Grid>
            </Grid>
          </Grid>

        </Box>
      </Box>
    </Modal>
  );
}
