import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, Box } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';
import ModalComponent from 'src/modal/Modal';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Logo from '../images/logo_gba_footer_blanco_3.png'
// ----------------------------------------------------------------------
const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const is_authenticated = useSelector(state => state.user.is_authenticated);

  useEffect(() => {
    if (is_authenticated) {
      navigate('/solicitudes', { replace: true });
    }
  }, [is_authenticated, navigate]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    console.log("Se ha confirmado el modal");
    handleClose();
  };

  const handleCancel = () => {
    console.log("Se ha cancelado el modal");
    handleClose();
  };
  return (
    <>
      <Helmet>
        <title> Login | Plataforma de Gestión Cultural </title>
      </Helmet>

      <StyledRoot>


        <Container maxWidth="sm">
          <StyledContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <img style={{ width: '400px' }} src={Logo} alt="Logo" />
            </Box>

            <Typography variant="h4" gutterBottom>
              Iniciar sesión
            </Typography>
            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
