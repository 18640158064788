import { Navigate, useRoutes, Route, Routes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import BlogPage from './pages/BlogPage';
import SolicitudesPage from './pages/SolicitudesPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ValidacionesPage from './pages/ValidacionesPage';
import DashboardAppPage from './pages/DashboardAppPage';
import EvaluacionesPage from './pages/EvaluacionesPage';
import RegionPage  from './pages/RegionPage';
import SeccionElectoralPage  from './pages/SeccionElectoralPage';
import GobiernoPage  from './pages/GobiernoPage';
import AreaPage  from './pages/AreaPage';
import MunicipioPage  from './pages/MunicipioPage';
import UsuariosPage  from './pages/UsuariosPage';
import Mapa from './components/mapa-de-solicitudes/mapa-de-solicitudes';
import { useSelector } from "react-redux";
import MunicipioMapDetail from './pages/MunicipioMapDetail';
import ChangePasswordPage from './pages/ChangePasswordPage';

// ----------------------------------------------------------------------

export default function Router() {
  const is_authenticated = useSelector(state => state.user.is_authenticated);

  const routes = (
    <Routes>
      <Route
        path="/"
        element={<DashboardLayout />}
      >
        <Route index element={<Navigate to="/solicitudes" />} />
        <Route path="mapeo" element={<DashboardAppPage />} />
        <Route path="solicitudes" element={<SolicitudesPage />} />
        <Route path="validaciones" element={<ValidacionesPage />} />
        <Route path="evaluaciones" element={<EvaluacionesPage />} />
        <Route path="regiones" element={<RegionPage />} />
        <Route path="secciones-electorales" element={<SeccionElectoralPage />} />
        <Route path="gobiernos" element={<GobiernoPage />} />
        <Route path="areas" element={<AreaPage />} />
        <Route path="usuarios" element={<UsuariosPage />} />
        <Route path="municipios" element={<MunicipioPage />} />
        <Route path="municipio/:id" element={<MunicipioMapDetail />} />
      </Route>
      <Route
        path="login"
        element={is_authenticated ? <Navigate to="/" /> : <LoginPage />}
      />
        <Route path="/change_password" element={<ChangePasswordPage />} />

      <Route
        path="*"
        element={
          <SimpleLayout>
            <Route index element={<Navigate to="/" />} />
            <Route path="*" element={<Page404 />} />
          </SimpleLayout>
        }
      />
    </Routes>
  );

  return routes;
}