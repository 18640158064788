import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import ModalPropuesta from 'src/modal/ModalPropuesta';
import axios from 'axios'
import { Card, Table, useMediaQuery, useTheme, InputLabel, Stack, Alert, FormControl, Chip, Snackbar, Select, TableHead, Paper, Avatar, Button, Popover, Box, Checkbox, Grid, TableRow, TextField, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, TableBody, TableCell, Container, Typography, IconButton, TableContainer, TablePagination, Modal, } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import { propuestasActions } from "../redux/propuestaSlice";
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import ModalValidacion from 'src/modal/ModalValidacion';
import CommonSearchBar from 'src/common/common-search-bar';
import CommonFiltersBar from 'src/common/common-filters-bar';
import CommonReports from 'src/common/common-reports';
import FiltrosSolicitud from '../components/filtros-solicitud/filtros-solicitud'
import '../modal/modal.css'
import ModalEvaluacion from 'src/modal/ModalEvaluacion';
import ModalSolicitudFinalizada from 'src/modal/ModalSolicitudFinalizada';

export default function SolicitudesPage() {
	const store_solicitudes = useSelector((state) => state.propuestas.propuestas);
	const store_cambio_solicitudes = useSelector((state) => state.propuestas.cambio_solicitudes);
	const [solicitudes, setSolicitudes] = useState()
	const [selectedSolicitudToEvaluate, setSelectedSolicitudToEvaluate] = useState(null);
	const [filtro, setFiltro] = useState('');
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [isLoadingFiltros, setIsLoadingFiltros] = useState(false);

	const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV
	const NGINX_BACKEND_PATH = process.env.NGINX_BACKEND_PATH;
	//const NGINX_BACKEND_PATH = 'https://plataformadegestioncultural.gba.gob.ar/prueba'
	const token = localStorage.getItem('token')
	const user_data = JSON.parse(localStorage.getItem('user_data'))
	if (!user_data) window.location.replace('/login')

	// if (!user_data) {
	// 	window.location.replace('/login')
	// }

	const dispatch = useDispatch()

	const [solicitudEditada, setSolicitudEditada] = useState({});
	const [edit, setEdit] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [openModalPropuesta, setOpenModalPropuesta] = useState(false);

	const [hora, setHora] = useState(null);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState('');
	const [snackbarSeverity, setSnackbarSeverity] = useState('success');
	const [selectedFile, setSelectedFile] = useState(null);
	const [editarSolicitud, setEditarSolicitud] = useState({});
	const [nextPage, setNextPage] = useState(null);
	const [previousPage, setPreviousPage] = useState(null);
	const [selectedValidacion, setSelectedValidacion] = useState(null);
	const [solicitudData, setSolicitudData] = useState({});
	const [modalOpenTextoPlano, setModalOpenTextoPlano] = useState(false);
	const [pageData, setPageData] = useState(null)
	const [openModalEvaluacion, setOpenModalEvaluacion] = useState(false);
	const [busqueda, setBusqueda] = useState('');
	const [reRender, setReRender] = useState(0);
	const [solicitudIdToDelete, setSolicitudIdToDelete] = useState(null);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

	const coloresPorEstado = {
		analisis: { color: 'warning', etiqueta: 'En Analisis' },
		Analisis: { color: 'warning', etiqueta: 'En Analisis' },
		Aprobado: { color: 'success', etiqueta: 'Aprobado' },
		APROBADO: { color: 'success', etiqueta: 'Aprobado' },
		Desaprobado: { color: 'error', etiqueta: 'Desaprobado' },
		DESAPROBADO: { color: 'error', etiqueta: 'Desaprobado' },
		SUSPENDIDA: { color: 'error', etiqueta: 'Suspendido' },
		SUSPENDIDO: { color: 'error', etiqueta: 'Suspendido' },
		Suspendida: { color: 'error', etiqueta: 'Suspendido' },
		Suspendido: { color: 'error', etiqueta: 'Suspendido' },
		Otros: { color: 'info', etiqueta: 'Otros' },
		Otro: { color: 'info', etiqueta: 'Otros' },
		OTROS: { color: 'info', etiqueta: 'Otros' },
		Finalizado: { color: 'secondary', etiqueta: 'Finalizado' },

	};

	const solicitudesFiltradas = solicitudes ? solicitudes.filter((solicitud) => {
		return (
			//solicitud.municipio.toLowerCase().includes(busqueda.toLowerCase()) ||
			solicitud.actividad.toLowerCase().includes(busqueda.toLowerCase()) ||
			solicitud.area_responsable.toLowerCase().includes(busqueda.toLowerCase()) ||
			solicitud.artistica_sugerida.toLowerCase().includes(busqueda.toLowerCase()) ||
			solicitud.descripcion.toLowerCase().includes(busqueda.toLowerCase()) ||
			solicitud.estado_solicitud.toLowerCase().includes(busqueda.toLowerCase()) ||
			solicitud.fecha_evento.toLowerCase().includes(busqueda.toLowerCase()) ||
			solicitud.horario_estimado.toLowerCase().includes(busqueda.toLowerCase())
		);
	}) : [];

	// Editar una solicitud
	const handleEditSolicitud = (solicitud) => {
		if (solicitud.estado_solicitud !== "Finalizado") {
			setEditarSolicitud(solicitud);
			setOpenModal(true)
			setOpenModalPropuesta(true)
			setEdit(true)
		}

	};

	// Eliminar una solicitud
	const handleDeleteSolicitud = (id) => {
		const data = {
			solicitud_id: id,
		};

		axios
			.post(SERVER_URL + `/eliminar/solicitud/`, data, {
				headers: { Authorization: "Token " + token },
			})
			.then((response) => {
				setOpenDeleteModal(false)
				setReRender(reRender + 1)
				setOpenSnackbar(true);
				setSnackbarMessage("Solicitud eliminada exitosamente!");
				setSnackbarSeverity("success");
			})
			.catch((error) => {
				setOpenDeleteModal(false);
				console.log("error", error);
				handleError(error);
			});
	};

	// -------Manejadores de apertura y cierre de modales---------
	const handleCloseModal = () => {
		setOpenModal(false);
		setOpenModalPropuesta(false);
		setEdit(false);
	};

	const handleCloseModalEvaluacion = () => {
		setOpenModalEvaluacion(false)
	};

	const handleOpenAddPropuesta = () => {
		setOpenModalPropuesta(true);
		setEdit(false)

	};

	const handleOpenModalEvaluacion = (solicitud) => {
		setOpenModalEvaluacion(true);
		setSelectedSolicitudToEvaluate(solicitud)
	}

	const handleViewSolicitud = (solicitud) => {
		setSolicitudData(solicitud);
		setModalOpenTextoPlano(true);

	};

	const handleSolicitudSeleccionada = (solicitud) => {
		if (solicitud.estado_solicitud !== "Finalizado") {
			setOpenModal(true);
			setSelectedValidacion(solicitud);

		}
	};

	// - ---------------------------------------

	// -------Popup de errores---------
	const handleSnackbarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackbar(false);
	};

	const handleSuccess = (response) => {
		edit ? setSnackbarMessage('Solicitud editada exitosamente!') : setSnackbarMessage('Solicitud creada exitosamente!');
		setSnackbarSeverity('success');
		setOpenSnackbar(true);
	};

	const handleError = (error) => {
		setSnackbarMessage(`Error al crear solicitud: ${error.response.data.error}`);
		setSnackbarSeverity('error');
		setOpenSnackbar(true);
	};

	// -------Paginacion--------
	const handleNextPage = (tipe_of_request, url) => {
		axios.get(url,
			{ headers: { 'Authorization': 'Token ' + token } }
		)
			.then((response) => {

				const urlNext = response.data.page_data.next;
				const urlPrevious = response.data.page_data.previous;

				const nextRelativeUrl = urlNext ? "/prueba" + urlNext.substring(urlNext.indexOf('/get_solicitudes')) : null;
				const previousRelativeUrl = urlPrevious ? "/prueba" + urlPrevious.substring(urlPrevious.indexOf('/get_solicitudes')) : null;

				setNextPage(nextRelativeUrl)
				setPreviousPage(previousRelativeUrl)

				setSolicitudes(response.data.results)
				setPageData(response.data.page_data);

				// dispatch(propuestasActions.addPropuestas(response.data.results));
			})
			.catch((error) => {
				console.log(error);
			});
	}


	const get_solicitudes = () => {
		axios.get(SERVER_URL + "/get_solicitudes/", {
			headers: { 'Authorization': 'Token ' + token }
		})
			.then((response) => {
				dispatch(propuestasActions.addPropuestas(response.data.results));

				const urlNext = response.data.page_data.next;
				const urlPrevious = response.data.page_data.previous;

				const nextRelativeUrl = urlNext ? "/prueba" + urlNext.substring(urlNext.indexOf('/get_solicitudes')) : null;
				const previousRelativeUrl = urlPrevious ? "/prueba" + urlPrevious.substring(urlPrevious.indexOf('/get_solicitudes')) : null;

				setNextPage(nextRelativeUrl);
				setPreviousPage(previousRelativeUrl);

				console.log(nextRelativeUrl)
				console.log(previousRelativeUrl)

				setSolicitudes(response.data.results);
				setPageData(response.data.page_data);
			})
			.catch((error) => {
				console.log(error);
			});
	}


	// Trae todas las solicitudes al iniciar
	useEffect(() => {
		get_solicitudes();
	}, [store_solicitudes, store_cambio_solicitudes, reRender])

	//Limpia datos del modal create solicitud
	useEffect(() => {
		if (!openModal) {
			setSelectedFile(null);
			setHora(null);
		}
	}, [openModal]);

	useEffect(() => {
		if (editarSolicitud) {
			setSolicitudEditada(editarSolicitud);
		}
	}, [editarSolicitud]);


	const callbackForSearchComponent = (response) => {
		console.log(response.data.page_data.next)
		setSolicitudes(response.data.results)
		// setNextPage(response.data.page_data.next)
		// setPreviousPage(response.data.page_data.previous)

		const urlNext = response.data.page_data.next;
		const urlPrevious = response.data.page_data.previous;

		const nextRelativeUrl = urlNext ? "/prueba" + urlNext.substring(urlNext.indexOf('/get_solicitudes')) : null;
		const previousRelativeUrl = urlPrevious ? "/prueba" + urlPrevious.substring(urlPrevious.indexOf('/get_solicitudes')) : null;

		setNextPage(nextRelativeUrl);
		setPreviousPage(previousRelativeUrl);

		setPageData(response.data.page_data)
	}

	const handleDownloadExcel = (url) => {
		const token = localStorage.getItem('token');
		const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV;

		const nombreArchivo = url.substring(url.lastIndexOf('/') + 1).split('.')[0];

		axios
			.get(SERVER_URL + '/pdf' + url + '/', {
				headers: {
					Authorization: 'Token ' + token,
				},
				responseType: 'blob',
			})
			.then(function (response) {
				const contentType = response.headers['content-type'];
				let fileExtension;

				if (contentType === 'application/pdf') {
					fileExtension = 'pdf';
				} else if (contentType === 'application/msword' || contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
					fileExtension = 'docx'; // Cambiar a 'doc' si deseas la extensión .doc
				} else {
					console.log('Tipo de archivo no compatible');
					return;
				}

				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', nombreArchivo + '.' + fileExtension);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			})
			.catch(function (error) {
				console.log(error);
				// Manejar el error aquí
			})
			.finally(function () {
				// setIsLoading(false);
				// setGeneratingText('Generar reporte'); // Restaurar el texto original
			});
	};




	return (
		<>
			<Helmet>
				<title> Solicitudes de gestión | Lista </title>
			</Helmet>

			<Container maxWidth="xxl" className="MuiContainer-root MuiContainer-fullWidth">

				<Stack
					direction={isMobile ? 'column' : 'row'}
					alignItems={isMobile ? 'flex-start' : 'center'}
					justifyContent={isMobile ? 'flex-start' : 'space-between'} en dispositivos móviles
					mt={isMobile ? 4 : 0}

					mb={5}
				>
					<Typography variant="h4" gutterBottom>
						Solicitudes de gestión
					</Typography>

					{user_data.group && user_data.group !== "consultant" && (
						<Button variant="contained" onClick={handleOpenAddPropuesta} startIcon={<Iconify icon="eva:plus-fill" />}>
							Nueva solicitud
						</Button>
					)}

				</Stack>

				<Card >
					<Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'column', p: 1, mt: 2 }}>
						<Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center', width: '100%' }} >
							<CommonSearchBar
								urlToFetch={'buscar_solicitudes/'}
								executeCallback={callbackForSearchComponent}
							/>

							{/* <CommonReports /> */}
						</Box>
						<Box>
							<FiltrosSolicitud
								urlToFetch={'get_solicitudes/'}
								executeCallback={callbackForSearchComponent}
								/* 	PUEDE SER CONFUSO, PERO LOS IDS ESTAN INVERTIDOS,
									ASÍ QUE LA ÚLTIMA SOLICITUD EN REALIDAD ES LA PRIMERA EN LA TABLA
								*/
								firstSolicitud={solicitudes && solicitudes.length > 0 ? solicitudes[solicitudes.length - 1].id : null}
								lastSolicitud={solicitudes && solicitudes.length > 0 ? solicitudes[0].id : null}
								setIsLoadingFiltros={setIsLoadingFiltros}
								isLoadingFiltros={isLoadingFiltros}
							/>
						</Box>



					</Box>

					{!isLoadingFiltros ? (

						solicitudes && solicitudes.length > 0 ?
							<Scrollbar sx={{ p: 1 }}>
								<TableContainer sx={{ overflow: 'auto', minHeight: 555 }}>
									<Table>
										<TableHead>
											<TableRow sx={{ height: '83px' }}>
												<TableCell>Municipio</TableCell>
												<TableCell>Actividad</TableCell>
												<TableCell>Área responsable</TableCell>
												<TableCell>Artística sugerida</TableCell>
												<TableCell>Descripción</TableCell>
												<TableCell>Estado de solicitud</TableCell>
												<TableCell>Fecha del evento</TableCell>
												<TableCell>F. Creacion de solicitud</TableCell>
												<TableCell>Horario estimado</TableCell>
												<TableCell>PDF/WORD</TableCell>
												<TableCell align="center" >Acciónes</TableCell>
											</TableRow>
										</TableHead>
										<TableBody >
											{
												solicitudesFiltradas && solicitudesFiltradas.length > 0 ? solicitudesFiltradas.map((solicitud) => (
													<TableRow
														sx={{ '&:hover': { backgroundColor: 'rgba(99, 115, 129, 0.08)' } }}
														onClick={() => {
															if (user_data.group === "validator" || user_data.group === "administrator" || user_data.group === "extendedvalidator") {
																handleSolicitudSeleccionada(solicitud);
															}
															else if (user_data.group === "uploader") {
																handleEditSolicitud(solicitud);

															}
														}}
														key={solicitud.id}>
														<TableCell>{solicitud.municipio_nombre}</TableCell>
														<TableCell sx={{ height: '80px' }}>{solicitud.actividad}</TableCell>
														<TableCell>{solicitud.area_responsable}</TableCell>
														<TableCell>{solicitud.artistica_sugerida}</TableCell>
														<TableCell>{solicitud.descripcion.length > 50 ? `${solicitud.descripcion.substring(0, 50)}...` : solicitud.descripcion}</TableCell>

														<TableCell>
															{solicitud.estado_solicitud in coloresPorEstado ? (
																<Chip
																	label={coloresPorEstado[solicitud.estado_solicitud].etiqueta}
																	color={coloresPorEstado[solicitud.estado_solicitud].color}
																	sx={{ color: 'white' }}
																/>
															) : (
																solicitud.estado_solicitud
															)}
														</TableCell>
														<TableCell sx={{ width: 200 }}>{solicitud.fecha_evento === 'Invalid date' || solicitud.fecha_evento === 'Invalid Date' || solicitud.fecha_evento === null ? '-' : moment.utc(solicitud.fecha_evento).format('DD-MM-YYYY')}</TableCell>
														<TableCell>{moment.utc(solicitud.fecha_solicitud_recibida).format('DD-MM-YYYY')}</TableCell>
														<TableCell>{solicitud.horario_estimado === 'Invalid date' || solicitud.horario_estimado === 'Invalid Date' || solicitud.horario_estimado === '' || solicitud.horario_estimado === null ? '-' : solicitud.horario_estimado}</TableCell>
														<TableCell>
															{solicitud.url_nota_pdf ? (
																<a onClick={(event) => { event.stopPropagation(); handleDownloadExcel(solicitud.url_nota_pdf) }}>
																	<Iconify color="#FF0000" icon="fa-file-pdf-o" />
																</a>
															) : (
																<span>-</span>
															)}
														</TableCell>
														<TableCell>
															<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

																{solicitud.estado_solicitud !== 'Finalizado' && user_data.group !== "consultant" && (
																	<Button title="Editar" variant="outlined" color="primary" onClick={(e) => { e.stopPropagation(); handleEditSolicitud(solicitud); handleSolicitudSeleccionada(solicitud) }}>
																		<Iconify icon="bi:pencil" />
																	</Button>
																)}

																{((solicitud.estado_solicitud === 'Finalizado') || (user_data.group === 'consultant')) && (
																	<Button
																		title="Ver solicitud"
																		variant="contained"
																		color="primary"
																		onClick={(e) => {
																			e.stopPropagation();
																			handleViewSolicitud(solicitud);
																		}}
																	>
																		<Iconify icon="bi:eye" />
																	</Button>
																)}





																{user_data.group === "administrator" && (
																	<Button
																		title="Eliminar"
																		variant="outlined"
																		color='error'
																		onClick={(e) => { e.stopPropagation(); setSolicitudIdToDelete(solicitud); setOpenDeleteModal(true) }} sx={{ marginLeft: 1 }}
																	>
																		<Iconify icon="bi:trash" />
																	</Button>
																)}

																{/* {user_data.group === "administrator" && solicitud.estado_solicitud !== 'Finalizado' && (
																	<Button
																		variant="outlined"
																		color='warning'
																		sx={{ marginLeft: 1 }}
																		onClick={(e) => { e.stopPropagation(); handleEditSolicitud(solicitud); handleSolicitudSeleccionada(solicitud) }}
																	>
																		A
																	</Button>
																)} */}

																{solicitud.estado_solicitud === "Aprobado" && (user_data.group === "uploader" || user_data.group === "administrator") && (
																	<Button
																		title="Evaluar"
																		variant="outlined"
																		color="success"
																		sx={{ marginLeft: 1, height: 32 }}
																		onClick={(e) => {
																			e.stopPropagation();
																			handleOpenModalEvaluacion(solicitud);
																		}}
																	>
																		<Iconify icon="grommet-icons:validate" />
																	</Button>
																)}

															</Box>
														</TableCell>

													</TableRow>
												)) :
													<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
														<Typography
															variant="h6"
															align="center"
															sx={{ display: 'flex', fontWeight: 'bold', marginTop: '2rem', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}
														>
															<Iconify icon="ic:baseline-error-outline" />
															No se encontraron resultados
														</Typography>

													</Box>
											}
										</TableBody>


									</Table>
									<Grid container spacing={2} sx={{ display: 'flex', padding: 2, justifyContent: 'flex-end', alignItems: 'center' }}>
										<Grid item>
											<Typography variant="b">
												Pagina {pageData.current_page_number} de {pageData.total_pages}
											</Typography>
											{previousPage && (
												<Button onClick={() => handleNextPage('previous', previousPage)}>
													<Iconify icon="material-symbols:arrow-circle-left" />
												</Button>
											)}
											{nextPage && (
												<Button onClick={() => handleNextPage('next', nextPage)}>
													<Iconify icon="material-symbols:arrow-circle-right" />
												</Button>
											)}
										</Grid>

									</Grid>
								</TableContainer>
							</Scrollbar>
							: <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 2, height: 540 }}>
								{solicitudes ? <Typography variant="subtitle1">No hay solicitudes</Typography> : <CircularProgress />}
							</Box>) : (<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 2, height: 540 }}>
								<CircularProgress />
							</Box>)
					}
				</Card>


				{(user_data && (user_data.group === "uploader" || user_data.group === "administrator" || user_data.group === "validator" || user_data.group === "extendedvalidator")) && (
					<Modal sx={{ width: "100%", height: "100%" }} open={openModalPropuesta} onClose={handleCloseModal}>
						<ModalPropuesta
							token={token}
							user_data={user_data}
							handleSuccess={handleSuccess}
							handleError={handleError}
							handleOpenAddPropuesta={handleOpenAddPropuesta}
							handleCloseModal={handleCloseModal}
							setOpenModalPropuesta={setOpenModalPropuesta}
							editarSolicitud={editarSolicitud}
							edit={edit}
							get_solicitudes={get_solicitudes}
							setReRender={setReRender}
						/>
					</Modal>
				)}



				{(user_data.group === "uploader" || user_data.group === "administrator") && (
					<ModalEvaluacion
						open={openModalEvaluacion}
						onClose={handleCloseModalEvaluacion}
						selectedSolicitudToEvaluate={selectedSolicitudToEvaluate}
						get_solicitudes={get_solicitudes}
						setReRender={setReRender}
					/>
				)}

				<ModalSolicitudFinalizada
					open={modalOpenTextoPlano}
					onClose={() => setModalOpenTextoPlano(false)}
					solicitudData={solicitudData}
				/>

				{(user_data.group === "consultant") && (
					<ModalValidacion

					/>
				)}


				{(user_data.group === "validator" || user_data.group === "administrator" || user_data.group === "extendedvalidator") && (
					<ModalValidacion
						user_data={user_data}
						token={token}
						selectedValidacion={selectedValidacion}
						open={openModal}
						handleCloseModal={handleCloseModal}
						setOpenSnackbar={setOpenSnackbar}
						setSnackbarMessage={setSnackbarMessage}
						snackbarMessage={snackbarMessage}
						openSnackbar={openSnackbar}
						setSnackbarSeverity={setSnackbarSeverity}
						get_solicitudes={get_solicitudes}
						// get_solicitudes={get_solicitudes}
						setReRender={setReRender}
						reRender={reRender}
					/>
				)}

				{/* confirm modal delete */}
				<Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							width: "84%",
							height: "100%",
							margin: "auto",
						}}
					>
						<Box
							sx={{
								backgroundColor: "#f4f6f8",
								padding: "40px",
								borderRadius: "5px",
							}}
						>
							<Typography variant="h4" sx={{ marginBottom: 2, textAlign: "center", color: "#333333" }}>
								¿Estás seguro de que quieres borrar la solicitud {solicitudIdToDelete && solicitudIdToDelete.actividad} ?
							</Typography>
							<Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", mt: 2 }}>
								<Button sx={{ m: 1 }} onClick={() => setOpenDeleteModal(false)}>Cancelar</Button>
								<Button variant="outlined" color="error" sx={{ m: 1 }} onClick={() => handleDeleteSolicitud(solicitudIdToDelete.id)}>Borrar</Button>
							</Box>
						</Box>
					</Box>
				</Modal>

				{/* Popups reutilizables */}
				<Snackbar onMouseEnter={() => setOpenSnackbar(false)} open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
					<Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
				</Snackbar>

			</Container>

		</>
	);
}
