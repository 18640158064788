import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';

import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Mapa from 'src/components/mapa-de-solicitudes/mapa-de-solicitudes';

import { useMediaQuery } from '@mui/material';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const token = localStorage.getItem('token');
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <>
      <Helmet>
        <title> Mapa de solicitudes | Plataforma de Gestión Cultural </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mt: 5, mb:3 }}>
          Mapa de solicitudes por municipio
        </Typography>

        <Mapa/>
      
      </Container>
    </>
  );
}
