import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
import { CircularProgress } from "@mui/material";
import { Button, Typography, Snackbar, Alert } from "@mui/material";
import {
	Container,
	Stack,
	Table,
	ToggleButton,
	ButtonGroup,
	TableBody,
	TableCell,
	Modal,
	TableContainer,
	TableHead,
	TableRow,
	Card,
	Box,
} from "@mui/material";
import CreateUsuarioModal from "src/modal/CreateUsuarioModal.js"
import { userActions } from "src/redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery, useTheme } from "@mui/material";

export default function UsuariosPage() {
	const user_data = JSON.parse(localStorage.getItem("user_data"));
	const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV;
	if (!user_data) window.location.replace("/login");
	const token = localStorage.getItem("token");
	const dispatch = useDispatch()
	const [modalOpen, setOpenModal] = useState(false);
	const [Usuarios, setUsuarios] = useState([]);
	const [selectedUsuario, setSelectedUsuario] = useState(null);
	const [isEdit, setIsEdit] = useState(false);
	const [loading, setLoading] = useState(true); // Agregamos un estado para indicar si se está cargando la lista de usuarios
	const [usuariosDesactivados, setUsuariosDesactivados] = useState([]);
	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

	// Alertas success - error
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("");

	const [filter, setFilter] = useState("activados");
	const [usuariosFiltrados, setUsuariosFiltrados] = useState([]);
	// const [reRender, setReRender] = useState(0);

	const handleOpenConfirmModal = (usuario) => {
		setSelectedUsuario(usuario);
		setOpenConfirmModal(true);
	};

	const getUsuariosActivados = () => {
		axios
			.get(SERVER_URL + "/get_usuarios/", {
				headers: {
					Authorization: "Token " + token,
				},
				params: {
					deleted: false,
				},
			})
			.then((response) => {
				setUsuarios(response.data.usuarios);
				setLoading(false);
				console.log(response.data);

				filtrarUsuarios();
			})
			.catch((error) => {
				console.log(error.response.data.error);
			});
	};

	const getUsuariosDesactivados = () => {
		axios
			.get(SERVER_URL + "/get_usuarios/", {
				headers: {
					Authorization: "Token " + token,
				},
				params: {
					deleted: true,
				},
			})
			.then((response) => {
				setUsuariosDesactivados(response.data.usuarios);
				setLoading(false);
				console.log(response.data);

				filtrarUsuarios();
			})
			.catch((error) => {
				console.log(error.response.data.error);
			});
	};


	const handleActivateUsuario = (usuarioId) => {
		axios.put(SERVER_URL + '/cambiar_estado/usuario/', { id: usuarioId })
			.then((response) => {
				console.log(response.data)
				setOpenSnackbar(true);
				setSnackbarSeverity("success");
				setSnackbarMessage("¡El usuario se ha activado con éxito!");
				getUsuariosDesactivados();

				// El usuario se activó exitosamente, puedes realizar las acciones necesarias en tu aplicación
			})
			.catch((error) => {
				setOpenSnackbar(true);
				setSnackbarSeverity("error");
				setSnackbarMessage("Hubo un problema al activar el usuario");
				// Ocurrió un error al intentar activar el usuario, puedes manejarlo en tu aplicación
			});
	};
	// Función para manejar clic en la fila de la tabla
	const handleOpenModalEdit = (usuario) => {
		setSelectedUsuario(usuario);
		setIsEdit(true);
		setOpenModal(true);
	};

	// const get_usuarios = () => {
	// 	axios
	// 		.get(SERVER_URL + "/get_usuarios/", {
	// 			headers: {
	// 				Authorization: "Token " + token,
	// 			},
	// 		})
	// 		.then((response) => {
	// 			setUsuarios(response.data.usuarios);
	// 			setLoading(false); // Cuando se obtiene la lista de usuarios, indicamos que la carga ha finalizado
	// 			console.log(response.data);
	// 		})
	// 		.catch((error) => {
	// 			console.log(error.response.data.error);
	// 		});
	// };

	// CREAR USUARIO
	const handleCreateUsuario = (usuario) => {
		setIsButtonLoading(true);
		axios.post(
				SERVER_URL + "/crear/usuario/",
				{
					nombre: usuario.nombre,
					apellido: usuario.apellido,
					username: usuario.username,
					password: usuario.password,
					mail: usuario.mail,
					user_area: usuario.user_area,
					user_group: usuario.user_group,
				},
				{
					headers: {
						Authorization: "Token " + token,
					},
				}
			)
			.then((response) => {
				setIsButtonLoading(false);
				setOpenSnackbar(true);
				setSnackbarSeverity("success");
				setSnackbarMessage("¡El usuario se ha creado con éxito!");
				setIsEdit(false);
				setOpenModal(false);
				getUsuariosActivados()
			})
			.catch((error) => {
				setIsButtonLoading(false);
				setOpenSnackbar(true);
				setSnackbarSeverity("error");
				setSnackbarMessage(error.response.data.error);
				console.log(error.response.data.error);
			});
	};

	// EDITAR USUARIO
	const handleEditUsuario = (usuario) => {
		setIsButtonLoading(true);
		axios
			.put(
				SERVER_URL + "/actualizar/usuario/",
				{
					user_id: usuario.user_id,
					nombre: usuario.nombre,
					apellido: usuario.apellido,
					username: usuario.username,
					password: usuario.password,
					mail: usuario.mail,
					user_area: usuario.user_area,
					user_group: usuario.user_group,
				},
				{
					headers: {
						Authorization: "Token " + token,
					},
				}
			)
			.then((response) => {
				setIsButtonLoading(false);
				setOpenSnackbar(true);
				setSnackbarSeverity("success");
				setSnackbarMessage("¡El usuario se ha actualizado con éxito!");
				setIsEdit(false);
				setOpenModal(false);
				//get_usuarios();
				getUsuariosActivados()
				dispatch(userActions.updateUserData({
					first_name: response.data.user.first_name,
					last_name: response.data.user.last_name,
					email: response.data.user.email,
					username: response.data.user.username,
					id: response.data.user.id,
					user_group: response.data.user.user_group,
					user_area: response.data.user.user_area
					// is_authenticated: true
				}));

			})
			.catch((error) => {
				setIsButtonLoading(false);
				setOpenSnackbar(true);
				setSnackbarSeverity("error");
				setSnackbarMessage(error.response.data.error);
				console.log(error.response.data.error);
			});
	};

	// BORRAR USUARIO
	const handleDeleteUsuario = (id) => {
		axios
			.put(
				SERVER_URL + "/eliminar/usuario/",
				{
					id,
				},
				{ headers: { Authorization: "Token " + token } }
			)
			.then((response) => {
				setOpenSnackbar(true);
				setSnackbarSeverity("success");
				setSnackbarMessage("¡El usuario se ha borrado con éxito!");
				setUsuarios(Usuarios.filter((userDB) => userDB.id !== id));
			})
			.catch((error) => {
				setSnackbarSeverity("error");
				setSnackbarMessage("¡Hubo un error inesperado!");
				console.log(error);
			});
	};

	const onDeleteUsuario = (usuario) => {
		// Realiza la acción de eliminación del usuario aquí
		handleDeleteUsuario(usuario.id); // Llama a la función handleDeleteUsuario existente y pasa el usuario seleccionado como parámetro
		setOpenConfirmModal(false); // Cierra el modal de confirmación
	};

	useEffect(() => {
		getUsuariosActivados();

		getUsuariosDesactivados();
	}, []);

	useEffect(() => {
		filtrarUsuarios();
	}, [Usuarios, usuariosDesactivados]);

	const filtrarUsuarios = () => {
		if (filter === "activados") {
			setUsuariosFiltrados(Usuarios.filter(usuario => !usuario.deleted));
		} else if (filter === "desactivados") {
			setUsuariosFiltrados(usuariosDesactivados.filter(usuario => usuario.deleted));
		}
	};

	useEffect(() => {
		if (filter === "activados") {
			getUsuariosActivados();
		} else if (filter === "desactivados") {
			getUsuariosDesactivados();
		}

		filtrarUsuarios(); // Llama a la función para filtrar usuarios aquí
	}, [filter]);

	return (
		<>
			<Helmet>
				<title>Usuarios | Lista</title>
			</Helmet>

			<Container maxWidth="xl" className="MuiContainer-root MuiContainer-fullWidth">
				<Stack
					direction={isMobile ? 'column' : 'row'}
					alignItems={isMobile ? 'flex-start' : 'center'}
					justifyContent={isMobile ? 'flex-start' : 'space-between'} en dispositivos móviles
					mt={isMobile ? 4 : 0}
					mb={5}
				>
					<Typography variant="h4" gutterBottom>
						Usuarios
					</Typography>

					{user_data.group === "administrator" && (
						<Button variant="contained" onClick={() => { setOpenModal(true); setIsEdit(false); }} startIcon={<Iconify icon="eva:plus-fill" />}>
							Nuevo usuario
						</Button>
					)}

					<CreateUsuarioModal
						open={modalOpen}
						onClose={() => setOpenModal(false)}
						isEdit={isEdit}
						setIsEdit={setIsEdit}
						selectedUsuario={selectedUsuario}
						title={isEdit ? "Editar usuario" : "Crear usuario"}
						handleEditUsuario={handleEditUsuario}
						handleCreateUsuario={handleCreateUsuario}
						isButtonLoading={isButtonLoading}
					/>
				</Stack>

				<ButtonGroup sx={{ mb: 5 }} variant="outlined" color="primary" aria-label="outlined primary button group">
					<Button onClick={() => setFilter("activados")} variant={filter === "activados" ? "contained" : "outlined"}>
						Activados
					</Button>
					<Button onClick={() => setFilter("desactivados")} variant={filter === "desactivados" ? "contained" : "outlined"}>
						Desactivados
					</Button>
				</ButtonGroup>
				<Card sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					{loading ? (
						<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", p: 2 }}>
							<CircularProgress />
						</Box>
					) : (
						<React.Fragment>
							{(Usuarios.length > 0 || usuariosDesactivados.length > 0) ? (
								<Scrollbar sx={{ p: 1 }}>
									<TableContainer sx={{ overflow: "auto" }}>
										<Table>
											<TableHead>
												<TableRow sx={{ height: "83px" }}>
													<TableCell>Nombre</TableCell>
													<TableCell>Email</TableCell>
													<TableCell>Area</TableCell>
													<TableCell>Rol</TableCell>
													<TableCell>Estado de usuario</TableCell>
													<TableCell sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "83px" }}>
														Acciones
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{usuariosFiltrados.map((usuario) => (
													<React.Fragment key={usuario.id}>
														<TableRow sx={{ "&:hover": { backgroundColor: "rgba(99, 115, 129, 0.08)" } }}>
															<TableCell onClick={() => handleOpenModalEdit(usuario)} label>
																{usuario.username}
															</TableCell>
															<TableCell onClick={() => handleOpenModalEdit(usuario)} label>
																{usuario.email ? usuario.email : "-"}
															</TableCell>
															<TableCell onClick={() => handleOpenModalEdit(usuario)} label>
																{usuario.area_name}
															</TableCell>
															<TableCell onClick={() => handleOpenModalEdit(usuario)} label>
															{usuario && usuario.group_name
																? (usuario.group_name.toLowerCase() === "administrator"
																	? "Administrador"
																	: usuario.group_name.toLowerCase() === "validator"
																		? "Validador"
																		: usuario.group_name.toLowerCase() === "uploader"
																		? "Carga"
																		: usuario.group_name.toLowerCase() === "extendedvalidator"
																			? "Validador y Carga"
																			: usuario.group_name.toLowerCase() === "consultant"
																			? "Consultor"
																			: "Sin rol asignado")
																: "Sin rol asignado"}
															</TableCell>

															<TableCell>
																{usuario.deleted ? "Desactivado" : "Activado"}
															</TableCell>
															<TableCell style={{ textAlign: "center" }}>
																{usuario.deleted ? (
																	<Button variant="outlined" color="success" onClick={(e) => { handleActivateUsuario(usuario.id); }}>
																		<Iconify icon="bi:check-circle" />
																	</Button>
																) : (<Box>
																	<Button sx={{ m: 1 }} variant="outlined" color="error" onClick={() => handleOpenConfirmModal(usuario)}>
																		<Iconify icon="bi:trash" />
																	</Button>
																	<Button sx={{ m: 1 }} variant="outlined" color='primary' onClick={() => handleOpenModalEdit(usuario)}>
																		<Iconify icon="bi:pencil" />
																	</Button>
																</Box>
																)}
															</TableCell>
														</TableRow>
													</React.Fragment>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Scrollbar>
							) : (
								<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", p: 2 }}>
									<Typography variant="h6" align="center">
										<Iconify icon="ic:baseline-error-outline" />
										No hay usuarios
									</Typography>
								</Box>
							)}
						</React.Fragment>
					)}
				</Card>

				<Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={openSnackbar} autoHideDuration={3000} onMouseEnter={() => setOpenSnackbar(false)} onClose={() => setOpenSnackbar(false)}>
					<Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
				</Snackbar>

				{openConfirmModal && (
					<Modal open={openConfirmModal} onClose={() => setOpenConfirmModal(false)}>
						<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "84%", height: "100%", margin: "auto" }}>
							<Box sx={{ backgroundColor: "#f4f6f8", padding: "40px", borderRadius: "5px" }}>
								<Typography variant="h4" sx={{ marginBottom: 2, textAlign: "center", color: "#333333" }}>
									¿Estás seguro de que quieres borrar el usuario {selectedUsuario && selectedUsuario.username}?
								</Typography>
								<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 2 }}>
									<Button sx={{ m: 1 }} onClick={() => setOpenConfirmModal(false)}>Cancelar</Button>
									<Button variant="outlined" color="error" sx={{ m: 1 }} onClick={() => onDeleteUsuario(selectedUsuario)}>Borrar</Button>
								</Box>
							</Box>
						</Box>
					</Modal>
				)}

			</Container>
		</>

	);
}
