import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Grid,
  Modal,
  TextField,
  Button,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress
} from "@mui/material";

export default function CreateUsuarioModal(props) {

  const token = localStorage.getItem("token");
  const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV;

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");

  const [Areas, setAreas] = useState([]);

  const [usuario, setUsuario] = useState({
    nombre: '',
    apellido: '',
    username: '',
    email: '',
    password: '',
    areaUsuario: 1,
    rolUsuario: 'uploader',
  });

  const getAreas = () => {
    axios
      .get(SERVER_URL + "/get_areas/", {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        setAreas(response.data.areas);
      })
      .catch((error) => {
        setOpenSnackbar(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(error.response.data.error);
        console.log(error.response.data.error);
      });
  };

  useEffect(() => {
    getAreas();
  }, []);

  useEffect(() => {
    if (props.selectedUsuario && props.isEdit) {
      setUsuario({
        nombre: props.selectedUsuario.first_name,
        apellido: props.selectedUsuario.last_name,
        username: props.selectedUsuario.username,
        email: props.selectedUsuario.email,
        password: '',
        areaUsuario: props.selectedUsuario.area_id,
        rolUsuario: props.selectedUsuario.group_name,
      });
    } else {
      setUsuario({
        nombre: '',
        apellido: '',
        username: '',
        email: '',
        password: '',
        areaUsuario: 1,
        rolUsuario: 'uploader',
      });
    }
  }, [props.selectedUsuario, props.isEdit]);
  function onSubmit() {
    if (props.isEdit) {
      props.handleEditUsuario({
        user_id: props.selectedUsuario.id,
        nombre: usuario.nombre,
        apellido: usuario.apellido,
        username: usuario.username,
        password: usuario.password,
        mail: usuario.email,
        user_area: usuario.areaUsuario,
        user_group: usuario.rolUsuario,
      });
    } else {

      props.handleCreateUsuario({
        nombre: usuario.nombre,
        apellido: usuario.apellido,
        username: usuario.username,
        password: usuario.password,
        mail: usuario.email,
        user_area: usuario.areaUsuario,
        user_group: usuario.rolUsuario,
      });
  
    }

    // onCloseModal(); # al parecer no hace falta
  }


  const onCloseModal = () => {
    if(props.selectedUsuario && props.isEdit){
      setUsuario({
        nombre: props.selectedUsuario.first_name,
        apellido: props.selectedUsuario.last_name,
        username: props.selectedUsuario.username,
        email: props.selectedUsuario.email,
        password: '',
        areaUsuario: props.selectedUsuario.area_id,
        rolUsuario: props.selectedUsuario.group_name,
      });

    }
    props.onClose();
  };

  return (
    <Modal open={props.open} onClose={onCloseModal}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          flexWrap: "wrap",
          overflow: 'auto' 
        }}
      >
        <Box
          sx={{
            backgroundColor: "#f4f6f8",
            padding: "40px",
            borderRadius: "5px",
            height: "600",
            margin: "auto",
            width: "800px",
          }}
        >
          <Typography variant="h4" sx={{ mb: 2 }}>
            {props.title}
          </Typography>

          <Grid
            container
            justifyContent="center"
            direction="column"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item>
              <TextField
                label="Nombre"
                value={usuario.nombre}
                onChange={(e) => setUsuario({ ...usuario, nombre: e.target.value })}
                sx={{ width: "100%" }}
              />
            </Grid>

            <Grid item>
              <TextField
                label="Apellido"
                value={usuario.apellido}
                onChange={(e) => setUsuario({ ...usuario, apellido: e.target.value })}
                sx={{ width: "100%" }}
              />
            </Grid>

            <Grid item>
              <TextField
                label="Usuario"
                value={usuario.username}
                onChange={(e) => setUsuario({ ...usuario, username: e.target.value })}
                sx={{ width: "100%" }}
              />
            </Grid>

            <Grid item>
              <TextField
                label="Contraseña"
                type="password"
                value={usuario.password}
                onChange={(e) => setUsuario({ ...usuario, password: e.target.value })}
                sx={{ width: "100%" }}
              />
            </Grid>

            <Grid item>
              <TextField
                label="Email"
                value={usuario.email}
                onChange={(e) => setUsuario({ ...usuario, email: e.target.value })}
                sx={{ width: "100%" }}
              />
            </Grid>

            <Grid item>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="area-usuario-label">Área de usuario</InputLabel>
                <Select
                  id="area-usuario-label"
                  label="Área de usuario"
                  value={usuario.areaUsuario}
                  onChange={(e) => setUsuario({ ...usuario, areaUsuario: e.target.value })}
                >
                  {Areas && Areas.length > 0 ? (
                    Areas.map((area) => (
                      <MenuItem key={area.id} value={area.id}>
                        {area.nombre}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No se encontraron áreas</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="rol-usuario-label">Rol del usuario</InputLabel>
                <Select
                  id="rol-usuario-label"
                  label="Rol del usuario"
                  value={usuario.rolUsuario}
                  onChange={(e) => setUsuario({ ...usuario, rolUsuario: e.target.value })}
                >
                  <MenuItem value="consultant">Rol de consultor</MenuItem>
                  <MenuItem value="validator">Rol de validador</MenuItem>
                  <MenuItem value="extendedvalidator">Rol de validador y carga</MenuItem>
                  <MenuItem value="uploader">Rol de carga</MenuItem>
                  <MenuItem value="administrator">Rol de administración</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid container justifyContent="flex-end" spacing={2} sx={{ marginTop: 4 }}>
              <Grid item>
                <Button onClick={onCloseModal} variant="outlined" fullWidth sx={{ marginRight: 2 }}>
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
              <Button onClick={onSubmit} type="submit" variant="contained" fullWidth disabled={props.isButtonLoading}>
                {props.isButtonLoading ? <CircularProgress size={24} /> : 'Enviar'}
              </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}