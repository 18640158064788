import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
import { CircularProgress } from "@mui/material";
import { Button, Typography, Snackbar, Alert } from "@mui/material";
import {
	Container,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Card,
	Box,
} from "@mui/material";
import CreateGobiernoModal from "src/modal/CreateGobiernoModal.js";
import BorrarGobiernoModal from "src/modal/child/BorrarGobiernoModal.js"
import { useMediaQuery, useTheme } from "@mui/material";

export default function GobiernoPage() {
	const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV
	const user_data = JSON.parse(localStorage.getItem("user_data"));
	if (!user_data) window.location.replace("/login");
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

	const token = localStorage.getItem("token");
	const [modalOpen, setOpenModal] = useState(false);
	const [Gobiernos, setGobiernos] = useState([]);
	const [loading, setLoading] = useState(true);
	const [modalDelete, setOpenModalDelete] = useState(false);
	const [selectedGobierno, setSelectedGobierno] = useState(null);
	const [isEdit, setIsEdit] = useState(false);

	// Alertas sucess - err
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("");

	// Función para manejar clic en la fila de la tabla
	const handleOpenModalEdit = (gobierno) => {
		setSelectedGobierno(gobierno);
		setIsEdit(true);
		setOpenModal(true);
	};

	const get_gobiernos = () => {
		axios
			.get(SERVER_URL + "/get_gobiernos/", {
				headers: {
					Authorization: "Token " + token,
				},
			})
			.then((response) => {
				setGobiernos(response.data.gobiernos);
				setLoading(false);
				console.log(response.data);
			})
			.catch((error) => {
				console.log(error.response.data.error);
				setLoading(false);
			});
	};

	//CREAR GOBIERNO
	const handleCreateGobierno = (nombre) => {
		axios
			.post(
				SERVER_URL + "/crear/gobierno/",
				{
					nombre_gobierno: nombre,
				},
				{
					headers: {
						Authorization: "Token " + token,
					},
				}
			)
			.then((response) => {
				setOpenSnackbar(true);
				setSnackbarSeverity("success");
				setSnackbarMessage(response.data.msg);
				get_gobiernos();
				console.log(response.data.msg, "Codigo de estado " + response.status);
			})
			.catch((error) => {
				setOpenSnackbar(true);
				setSnackbarSeverity("error");
				setSnackbarMessage(error.response.data.error);
				console.log(error.response.data.error, error.response.status);
			});
	};

	//EDITAR REGION
	const handleEditGobierno = (gobierno, nombre) => {
		console.log(gobierno, nombre, "hola XD");
		axios
			.put(
				SERVER_URL + "/actualizar/gobierno/",
				{
					id: gobierno.id,
					nombre_gobierno: nombre,
				},
				{
					headers: {
						Authorization: "Token " + token,
					},
				}
			)
			.then((response) => {
				setOpenSnackbar(true);
				setSnackbarSeverity("success");
				setSnackbarMessage(response.data.msg);
				get_gobiernos();
				console.log(response.data.msg, response.status);
			})
			.catch((error) => {
				setOpenSnackbar(true);
				setSnackbarSeverity("error");
				setSnackbarMessage(error.response.data.error);
				console.log(error.response.data.error, error.response.status);
			});
	};

	//BORRAR GOBIERNO
	const handleDeleteGobierno = (id) => {
		axios
			.put(
				SERVER_URL + "/eliminar/gobierno/",
				{
					id,
				},
				{ headers: { Authorization: "Token " + token } }
			)
			.then((response) => {
				setOpenSnackbar(true);
				setSnackbarSeverity("success");
				setSnackbarMessage(response.data.msg);
				setGobiernos(Gobiernos.filter((gobiernoDB) => gobiernoDB.id !== id));
			})
			.catch((error) => {
				setSnackbarSeverity("error");
				setSnackbarMessage(error.response.data.error);
				console.log(error);
			});
	};

	useEffect(() => {
		get_gobiernos();
	}, []);

	return (
		<>
			<Helmet>
				<title> Gobiernos | Lista </title>
			</Helmet>

			<Container
				maxWidth="xl"
				className="MuiContainer-root MuiContainer-fullWidth"
			>
				<Stack
					direction={isMobile ? 'column' : 'row'}
					alignItems={isMobile ? 'flex-start' : 'center'}
					justifyContent={isMobile ? 'flex-start' : 'space-between'} en dispositivos móviles
					mt={isMobile ? 4 : 0}
					mb={5}
				>
					<Typography variant="h4" gutterBottom>
						Gobiernos
					</Typography>

					{user_data.group === "administrator" && (
						<Button
							variant="contained"
							onClick={() => {
								setOpenModal(true);
								setIsEdit(false);
							}}
							startIcon={<Iconify icon="eva:plus-fill" />}
						>
							Nuevo gobierno
						</Button>
					)}

					<CreateGobiernoModal
						open={modalOpen}
						onClose={() => setOpenModal(false)}
						isEdit={isEdit}
						setIsEdit={setIsEdit}
						selectedGobierno={selectedGobierno}
						title={isEdit ? "Editar gobierno" : "Crear gobierno"}
						handleEditGobierno={handleEditGobierno}
						handleCreateGobierno={handleCreateGobierno}
					/>
				</Stack>

				<Card
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{loading ? (
						<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 2, height: 540 }}>
							<CircularProgress />
						</Box>
					) : Gobiernos && Gobiernos.length > 0 ? (
						<Scrollbar sx={{ p: 1 }}>
							<TableContainer sx={{ overflow: "auto" }}>
								<Table>
									<TableHead>
										<TableRow sx={{ height: "83px" }}>
											<TableCell>Nombre</TableCell>
											<TableCell
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													height: "83px",
												}}
											>
												Acciones
											</TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										{Gobiernos.map((gobierno) => (
											<React.Fragment key={gobierno.id}>
												<TableRow
													sx={{
														"&:hover": {
															backgroundColor: "rgba(99, 115, 129, 0.08)",
														},
														height: "35px",
													}}
												>
													<TableCell
														onClick={() => handleOpenModalEdit(gobierno)}
														label
													>
														{gobierno.nombre}
													</TableCell>
													<TableCell style={{ textAlign: "center" }}>
														<Button
															sx={{ m: 1 }}
															variant="outlined"
															color="error"
															onClick={(e) => {
																setSelectedGobierno(gobierno);
																setOpenModalDelete(true);
															}}
														>
															<Iconify icon="bi:trash" />
														</Button>
														<Button sx={{ m: 1 }}
															variant="outlined" color="primary" onClick={() => handleOpenModalEdit(gobierno)}
														>
															<Iconify icon="bi:pencil" />
														</Button>
													</TableCell>
												</TableRow>
											</React.Fragment>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Scrollbar>
					) : (
						<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 2, height: 540 }}>
							<Typography variant="subtitle1">No hay gobiernos</Typography>
						</Box>
					)}
				</Card>

				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={openSnackbar}
					autoHideDuration={3000}
					onClose={() => setOpenSnackbar(false)}
					message={snackbarMessage}
				>
					<Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
				</Snackbar>
				<BorrarGobiernoModal
					open={modalDelete}
					onClose={() => setOpenModalDelete(false)}
					handleDeleteGobierno={handleDeleteGobierno}
					selectedGobierno={selectedGobierno}
				/>
			</Container>
		</>
	);
}
