
import { useEffect, useRef, useState } from 'react';
import { InputLabel, FormControl, Select, CircularProgress, Button, Box, Grid, TextField, MenuItem, Typography } from '@mui/material';
import TextareaAutosize from '@mui/base/TextareaAutosize';

import "./modal.css"
import DateTime from 'react-datetime';
import moment from 'moment';
import "react-datetime/css/react-datetime.css";
import dayjs from 'dayjs';
import { useSelector, useDispatch } from "react-redux";
import { propuestasActions } from "../redux/propuestaSlice";
import Iconify from '../components/iconify';
import axios from 'axios'
import { useActualizarPropuestas } from "../redux/propuestaSlice";
import { useTheme } from '@mui/material/styles';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const ModalPropuesta = ({ token, user_data, handleSuccess, get_solicitudes, handleError, handleCloseModal, setOpenModalPropuesta, edit, editarSolicitud }) => {
    const theme = useTheme();
    const isMobile = theme.breakpoints.down('sm'); // Define el tamaño máximo para dispositivos móviles

    const dispatch = useDispatch()
    const store_cambio_solicitudes = useSelector((state) => state.propuestas.cambio_solicitudes);

    const municipios = useSelector((state) => state.initial_info.municipios);
    const [hora, setHora] = useState(null);
    const [fechaEvento, setFechaEvento] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [areaResponsable, setAreaResponsable] = useState('');
    const [region, setRegion] = useState(null)
    const [gobierno, setGobierno] = useState(null)
    const [seccionElectoral, SetSeccionElectoral] = useState(null)
    const gestionRef = useRef();
    const [modalidad, setModalidad] = useState(null)
    const actividadRef = useRef();
    const descripcionRef = useRef();
    const lugarRef = useRef();
    const nombreResponsableRef = useRef();
    const responsableRef = useRef();
    const contactoRef = useRef();
    const artisticaRef = useRef();
    const espacioRef = useRef();
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);
    const [Areas, setAreas] = useState([]);


    const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV

    const [municipioSeleccionado, setMunicipioSeleccionado] = useState(null);

    const handleChangeMunicipio = event => {
        const idMunicipio = event.target.value;
        const municipio = municipios.find(m => m.id === idMunicipio);

        setMunicipioSeleccionado(municipio);
        setRegion(municipio.region_nombre)
        setGobierno(municipio.gobierno_nombre)
        SetSeccionElectoral(municipio.seccion_electoral_nombre)
    };

    const handleSubmitNewSolicitud = (e) => {
        if (user_data.group === "uploader" || user_data.group === "administrator" || user_data.group === "validator" || user_data.group === "extendedvalidator") {
            e.preventDefault();

            const actividad = actividadRef.current.value;
            const gestionSolicitud = gestionRef.current.value;
            const notapdf = selectedFile;
            const descripcion = descripcionRef.current.value;
            const artistica = artisticaRef.current.value;
            const responsableNombre = nombreResponsableRef.current.value;
            const responsable = responsableRef.current.value;
            const contacto = contactoRef.current.value;
            const lugar = lugarRef.current.value ? lugarRef.current.value : null;
            const espacio = espacioRef.current.value;
            const horario_estimado = hora ? dayjs(hora).format('HH:mm') : '';

            const data = {
                area_responsable: areaResponsable,
                descripcion_solicitud: descripcion,
                modalidad: modalidad,
                actividad: actividad,
                gestion_solicitud: gestionSolicitud,
                nota_pdf: notapdf, //nota pdf envia solo el nombre 
                fecha_evento: fechaEvento !== 'Invalid date' ? moment.utc(fechaEvento).format('DD-MM-YYYY') : null,
                horario_estimado: horario_estimado,
                lugar: lugar,
                artistica_sugerida: artistica,
                nombre_responsable_local: responsableNombre,
                cargo_responsable_local: responsable,
                contacto_responsable_local: contacto,
                espacio_colectivo: espacio,
                id_municipio: municipioSeleccionado.id,
            };

            setIsLoadingBtn(true)

            axios.post(SERVER_URL + "/crear/solicitud/", data,
                { headers: { 'Authorization': 'Token ' + token, 'Content-Type': 'multipart/form-data' } }
            )
                .then((response) => {
                    setIsLoadingBtn(false)
                    console.log(response.data);
                    handleSuccess(response);
                    // setHora(null);
                    setSelectedFile(null);
                    setOpenModalPropuesta(false)
                    dispatch(propuestasActions.agregarPropuesta({
                        propuesta: response.data.solicitud
                    }));
                    get_solicitudes();
                })
                .catch((error) => {
                    setIsLoadingBtn(false)
                    console.log('error', error);
                    handleError(error);

                });
        } else {
            console.log('No tienes permisos para crear solicitudes')
        }
    };

    const handleSubmitEditSolicitud = (e) => {
        if (user_data.group === "uploader" || user_data.group === "extendedvalidator") {
            e.preventDefault();
            const horarioFormateado = hora ? dayjs(hora).format('HH:mm') : '';
            const espacio = espacioRef.current.value ? espacioRef.current.value : null;

            const data = {
                id_solicitud: editarSolicitud.id,
                id_municipio: municipioSeleccionado.id,
                area_responsable: areaResponsable,
                descripcion_solicitud: descripcionRef.current.value,
                modalidad: modalidad,
                actividad: actividadRef.current.value,
                gestion_solicitud: gestionRef.current.value,
                nota_pdf: selectedFile,
                fecha_evento: fechaEvento !== 'Invalid date' ? moment.utc(fechaEvento).format('DD-MM-YYYY') : '',
                horario_estimado: horarioFormateado,
                lugar: lugarRef.current.value,
                artistica_sugerida: artisticaRef.current.value,
                nombre_responsable_local: nombreResponsableRef.current.value,
                cargo_responsable_local: responsableRef.current.value,
                contacto_responsable_local: contactoRef.current.value,
                seccionElectoral: editarSolicitud.seccionElectoral,
                region: editarSolicitud.region,
                gobierno: editarSolicitud.gobierno,
                municipio: editarSolicitud.municipio,
                espacio_colectivo: editarSolicitud.espacio

            };
            axios.put(SERVER_URL + '/actualizar/solicitud/', data,
                { headers: { 'Authorization': 'Token ' + token, 'Content-Type': 'multipart/form-data' } }
            )
                .then((response) => {
                    console.log(response.data);
                    handleSuccess(response);
                    dispatch(propuestasActions.changeCambioSolicitudes({ cambio_solicitudes: !store_cambio_solicitudes }));
                    setSelectedFile(null);
                    setOpenModalPropuesta(false);
                    get_solicitudes();
                })
                .catch((error) => {
                    console.log('error', error);
                    handleError(error);
                });
        } else {
            console.log('No tienes permisos para editar solicitudes')
        }
    };

    const get_areas = () => {
        axios
            .get(SERVER_URL + "/get_areas/", {
                headers: {
                    Authorization: "Token " + token,
                },
            })
            .then((response) => {
                console.log(response.data.areas)
                setAreas(response.data.areas);
            })
            .catch((error) => {
                console.log(error.response.data.error);
            });
    };

    useEffect(() => {
        if (editarSolicitud && edit === true) {
            // console.log(editarSolicitud)
            descripcionRef.current.value = editarSolicitud.descripcion;
            setAreaResponsable(editarSolicitud.area_responsable);  
            gestionRef.current.value = editarSolicitud.gestion_solicitud;
            setModalidad(editarSolicitud.modalidad)
            actividadRef.current.value = editarSolicitud.actividad;
            lugarRef.current.value = editarSolicitud.lugar;
            nombreResponsableRef.current.value = editarSolicitud.nombre_responsable_local;
            responsableRef.current.value = editarSolicitud.cargo_responsable_local;
            contactoRef.current.value = editarSolicitud.contacto_responsable_local;
            artisticaRef.current.value = editarSolicitud.artistica_sugerida;

            if (editarSolicitud.contacto_responsable_local) {
                contactoRef.current.value = `${editarSolicitud.contacto_responsable_local}`;
            }

            const fechaEventoMoment = moment.utc(editarSolicitud.fecha_evento);
            setFechaEvento(fechaEventoMoment.format('DD-MM-YYYY'));
            setHora(moment(editarSolicitud.horario_estimado, 'HH:mm'));

            const municipio = municipios.find(m => m.id === editarSolicitud.municipio);
            setMunicipioSeleccionado(municipio);
            setRegion(municipio.region_nombre);
            setGobierno(municipio.gobierno_nombre);
            SetSeccionElectoral(municipio.seccion_electoral_nombre);


        }
    }, [editarSolicitud]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                handleCloseModal();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleCloseModal]);

    useEffect(() => {
        if (editarSolicitud && edit === false) {
            contactoRef.current.value = '549';
        }
        get_areas();
    }, []);


    const handleChangeDate = (event) => {
        const selectedDate = event.target.value; // Obtener la fecha seleccionada
        setFechaEvento(selectedDate); // Actualizar el estado con la fecha seleccionada
    };



    return (
        <>
            <Box className="modal-box" sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", padding: 16 }}>
                <Box sx={{
                    backgroundColor: "#f4f6f8", padding: "40px",
                    borderRadius: "5px",
                    maxWidth: "100%",
                    width: "100%",
                    height: "100%",
                    overflow: "auto"
                }}>
                    <Typography variant="h4" sx={{ marginBottom: 5 }}>{edit ? 'Editar solictud' : 'Nueva solicitud'}</Typography>

                    <FormControl sx={{ mb: 1, flexDirection: isMobile ? 'column' : 'row' }} >
                        <Grid container spacing={2} sx={{ mb: 1 }}>
                            <Grid item xs={12} md={3}>
                                <FormControl variant="standard" sx={{ width: '100%' }}>
                                    <InputLabel id="demo-simple-select-standard-label">Municipio</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        label="Municipio"
                                        // ref={municipioRef}
                                        value={municipioSeleccionado ? municipioSeleccionado.id : ''}
                                        onChange={handleChangeMunicipio}
                                    >
                                        {municipios.map((municipio) => (
                                            <MenuItem key={municipio.id} value={municipio.id}>
                                                {municipio.nombre}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField label="Región" variant="outlined" fullWidth value={region} disabled InputLabelProps={{ shrink: true }} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField label="Gobierno" variant="outlined" fullWidth value={gobierno} disabled InputLabelProps={{ shrink: true }} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label="Sección electoral"
                                    disabled
                                    variant="outlined"
                                    fullWidth
                                    value={seccionElectoral}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>

                            {/* Grupo de datos de la solicitud */}
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id="area-responsable-label">Área Responsable</InputLabel>
                                    <Select
                                        labelId="area-responsable-label"
                                        label="Área Responsable"
                                        value={areaResponsable}
                                        onChange={(e) => setAreaResponsable(e.target.value)}
                                    >
                                        {Areas.map((area) => (
                                            <MenuItem key={area.id} value={area.nombre}>
                                                {area.nombre}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Modalidad</InputLabel>
                                    <Select
                                        label="Modalidad"
                                        variant="outlined"
                                        fullWidth
                                        value={edit ? editarSolicitud.modalidad : modalidad}
                                        onChange={(event) => {
                                            setModalidad(event.target.value);
                                            if (edit) {
                                                editarSolicitud.modalidad = event.target.value;
                                            }
                                        }}
                                    >
                                         <MenuItem value="asistencia">Asistencia</MenuItem>
                                                    <MenuItem value="propuesta">Propuesta</MenuItem>
                                                    <MenuItem value="programacion">Programación</MenuItem>
                                                    <MenuItem value="subsidio">Subsidio</MenuItem>
                                                    <MenuItem value="capacitación">Capacitación</MenuItem>
                                                    <MenuItem value="declaración">Declaración</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField label="Actividad" variant="outlined" fullWidth inputRef={actividadRef} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField label="Artistica sugerida" variant="outlined" fullWidth inputRef={artisticaRef} />
                            </Grid>

                            {/* Grupo de datos del evento */}
                            {/* <Grid item xs={6} md={3}>
      <TextField label="Descripción de la Solicitud" variant="outlined" fullWidth inputRef={descripcionRef} />
    </Grid> */}
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth>
                                    {/* <DateTime
                                        utc={true}
                                        className="datetime"
                                        timeFormat={false}
                                        value={edit ? fechaEvento : null}
                                        inputProps={{
                                        placeholder: 'Fecha del evento',
                                        readOnly: true,
                                        onKeyDown: handleKeyDown // Agregar controlador de eventos onKeyDown
                                        }}
                                        onChange={(nuevaFecha) => {
                                        setFechaEvento(nuevaFecha);
                                        }}
                                        dateFormat="DD-MM-YYYY"
                                        timeZone="America/Argentina/Buenos_Aires"
                                        allowDelete={true} // Habilitar la opción de borrar el contenido del campo de fecha
                                    /> */}

                                    <TextField
                                        type="date"
                                        value={fechaEvento}
                                        onChange={handleChangeDate}
                                        min="yyyy-MM-dd"
                                        max="yyyy-MM-dd"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField label="Lugar" variant="outlined" fullWidth inputRef={lugarRef} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DateTime
                                    dateFormat={false}
                                    timeFormat="HH:mm"
                                    initialValue={edit ? moment(editarSolicitud.horario_estimado, 'HH:mm') : null}
                                    onChange={(momento) => setHora(momento)}
                                    className="datetime"
                                    inputProps={{
                                        //readOnly: true,
                                        placeholder: 'Hora',
                                        onKeyPress: (event) => {
                                            if (!/[0-9:]/.test(event.key) || event.target.value.length >= 5) {
                                                event.preventDefault();
                                            }
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label="Nombre Responsable Local"
                                    variant="outlined"
                                    fullWidth
                                    inputRef={nombreResponsableRef}
                                />
                            </Grid>
                        </Grid>

                        {/* 5 - Datos del equipo técnico */}
                        <Grid container spacing={2} sx={{ mb: 1 }}>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label="Cargo Responsable Local"
                                    variant="outlined"
                                    fullWidth
                                    inputRef={responsableRef}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    type="number"
                                    label="Numero de Responsable Local"
                                    variant="outlined"
                                    fullWidth
                                    inputRef={contactoRef}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label="Espacio o colectivo"
                                    variant="outlined"
                                    fullWidth
                                    inputRef={espacioRef}
                                />
                            </Grid>
                        </Grid>

                        {/* 6 - Botones de envío y cancelación */}
                        <Grid container spacing={2} sx={{ mb: 1 }}>
                        </Grid>
                        <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    aria-label="Gestión de la Solicitud"
                                    placeholder="Gestión de la solicitud..."
                                    rows={4}
                                    multiline={true}
                                    sx={{ width: '100%', height: '200px' }}
                                    inputRef={gestionRef}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    sx={{ width: '100%' }}
                                    multiline={true}
                                    rows={4}
                                    aria-label="Descripción de la Solicitud"
                                    placeholder="Descripción de la solicitud..."
                                    minRows={4}
                                    inputRef={descripcionRef}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
                            <Grid item>
                                <Button
                                    onClick={handleCloseModal}
                                    variant="outlined"
                                    color="primary"
                                    sx={{ mr: 1 }}
                                >
                                    Cancelar
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    component="label"
                                    accept=".pdf,.doc,.docx"
                                    fullWidth
                                    style={{
                                        width: 190,
                                        height: 36,
                                        border: '1px solid #2065d1',
                                        backgroundColor: '#f4f6f8',
                                        color: '#2065d1',
                                        boxShadow: 'none',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    <Iconify icon="material-symbols:upload-file-outline" />
                                    Cargar PDF/DOC
                                    <input
                                        accept=".pdf, .doc, .docx"
                                        style={{ display: 'none' }}
                                        type="file"
                                        onChange={(e) => {
                                            setSelectedFile(e.target.files[0]);
                                            setFileName(e.target.files[0].name);
                                        }}
                                    />
                                </Button>

                            </Grid>
                            <Grid item>
                                {edit ? (
                                    <Button
                                        onClick={handleSubmitEditSolicitud}
                                        variant="contained"
                                        color="primary"
                                        sx={{ mr: 1 }}
                                    >
                                        Editar
                                    </Button>
                                ) : (
                                    <Button
                                        disabled={isLoadingBtn}
                                        onClick={handleSubmitNewSolicitud}
                                        variant="contained"
                                        color="primary"
                                        sx={{ mr: 1 }}
                                    >
                                        {isLoadingBtn ? (
                                            <CircularProgress color="inherit" size={24} />
                                        ) : (
                                            'Crear'
                                        )}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>

                        <Grid>
                            {/* Muestra pdf viejo y lo cambia por nuevo en editar, y en crear muestra el nombre del pdf */}
                            {editarSolicitud && edit && (
                                <small>
                                    {fileName
                                        ? ` ${fileName}`
                                        : editarSolicitud.url_nota_pdf
                                            ? ` ${editarSolicitud.url_nota_pdf}`
                                            : ''}
                                </small>
                            )}
                            {selectedFile ? (edit === false ? <small>{selectedFile.name}</small> : '') : ''}
                        </Grid>
                    </FormControl>

                </Box>
            </Box>
        </>

    )
}

export default ModalPropuesta