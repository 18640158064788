import React, { useState } from 'react';
import { Container, Typography, Box, Stack, TextField, Button, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import Logo from '../images/logo_gba_footer_blanco_3.png';
import { Link, useNavigate } from 'react-router-dom';

const ChangePasswordPage = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const user_data = JSON.parse(localStorage.getItem('user_data'));
  if (user_data) window.location.replace('/login');

  const handleResetPassword = () => {
    setIsLoading(true);
    setSnackbarOpen(false);
    axios
      .post(process.env.REACT_APP_API_BACKEND_SV + '/resetear_contrasenia_usuario/', {
        email: email,
      })
      .then((response) => {
        setIsLoading(false);
        setSnackbarSeverity('success');
        setSnackbarMessage('Te vamos a enviar una nueva contraseña en unos minutos.');
        setSnackbarOpen(true);
        // Redirige al usuario al login después de 3 segundos
        setTimeout(() => {
          navigate('/login');
        }, 5000);
      })
      .catch((error) => {
        setIsLoading(false);
        setSnackbarSeverity('error');
        setSnackbarMessage(error.response.data.error);
        setSnackbarOpen(true);
      });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const isEmailValid = () => {
    // Expresión regular para validar un correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return email.trim() !== '' && emailRegex.test(email);
  };

  return (
    <Container maxWidth="sm" sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <img style={{ width: '400px' }} src={Logo} alt="Logo" />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'left', width: '100%' }}>
        <Typography variant="h4">Restaurar contraseña</Typography>
      </Box>
      <Stack spacing={1} sx={{ width: '100%', mt: 1 }}>
        <TextField
          name="email"
          label="Correo electrónico"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
        />
        <Link to={'/login'}> Volver al login </Link>
        <Button
          variant="contained"
          onClick={handleResetPassword}
          disabled={isLoading || !isEmailValid()}
          style={{ textTransform: 'none' }}
        >
          {isLoading ? 'Enviando...' : 'Enviar email de restablecimiento'}
        </Button>
        <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Stack>
    </Container>
  );
};

export default ChangePasswordPage;
