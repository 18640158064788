import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import Scrollbar from '../components/scrollbar';
import { useDispatch } from 'react-redux';
import { Card, Table, TableHead, Box, Button, TableBody, TableContainer, Snackbar, Grid, Alert, Stack, TableRow, TableCell, Container, Typography, CircularProgress } from '@mui/material';
import Iconify from '../components/iconify';
import ModalValidacion from 'src/modal/ModalValidacion';
import ModalEvaluacion from 'src/modal/ModalEvaluacion';
import { useMediaQuery, useTheme } from "@mui/material";
import moment from 'moment';

export default function EvaluacionesPage() {
    // const NGINX_BACKEND_PATH = NGINX_BACKEND_PATH;

    // const NGINX_BACKEND_PATH = 'http://localhost:8081';
    const NGINX_BACKEND_PATH = 'https://plataformadegestioncultural.gba.gob.ar/prueba'
    const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV;
    const token = localStorage.getItem('token');
    const user_data = JSON.parse(localStorage.getItem('user_data'));
    const [openModal, setOpenModal] = useState(false);
    const [evaluaciones, setEvaluaciones] = useState([]);
    const [selectedEvaluacion, setSelectedEvaluacion] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false); // Estado para controlar la carga
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    /* PAGINACIÓN*/
    const [pageData, setPageData] = useState();
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);

    if (!user_data) {
        window.location.replace('/login');
    }

    // Alertas success - error
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    // Función para manejar clic en la fila de la tabla
    const handleOpenModalEdit = (evaluacion) => {
        setSelectedEvaluacion(evaluacion);
        setIsEdit(true);
        setOpenModal(true);
    };

    // Delete evaluacion
    const handleDeleteEvaluacion = (id) => {
        axios
            .put(`${SERVER_URL}/eliminar/evaluacion/`, { evaluacion_id: id }, {
                headers: { Authorization: 'Token ' + token },
            })
            .then((response) => {
                setOpenSnackbar(true);
                setSnackbarMessage('¡La evaluación se ha borrado con éxito!');
                setEvaluaciones(evaluaciones.filter((evaluacion) => evaluacion.id !== id));
            })
            .catch((error) => {
                setSnackbarSeverity('error');
                setSnackbarMessage('¡Hubo un error inesperado!');
                console.log(error);
            });
    };


    const handleNextPage = (tipe_of_request, url) => {
        setLoading(true); // Mostrar spinner de carga
        axios.get(url,
            { headers: { 'Authorization': 'Token ' + token } }
        )
            .then((response) => {
                setEvaluaciones(response.data.results);
                const urlNext = response.data.page_data.next;
                // const urlPrevious = response.data.page_data.previous;
                // const nextRelativeUrl = urlNext ? NGINX_BACKEND_PATH + urlNext.substring(urlNext.indexOf('/get_evaluaciones')) : null;
                // const previousRelativeUrl = urlPrevious ? NGINX_BACKEND_PATH + urlPrevious.substring(urlPrevious.indexOf('/get_evaluaciones')) : null;
                // setNextPage(nextRelativeUrl);
                // setPreviousPage(previousRelativeUrl);
                const urlPrevious = response.data.page_data.previous;
                const nextRelativeUrl = urlNext ? "/prueba" + urlNext.substring(urlNext.indexOf('/get_evaluaciones')) : null;
                const previousRelativeUrl = urlPrevious ? "/prueba" + urlPrevious.substring(urlPrevious.indexOf('/get_evaluaciones')) : null;
                setNextPage(nextRelativeUrl);
                setPreviousPage(previousRelativeUrl);
                setPageData(response.data.page_data);
                setLoading(false); // Ocultar spinner de carga
            })
            .catch((error) => {
                console.log(error);
                setLoading(false); // Ocultar spinner de carga en caso de error
            });
    };

    //get Evaluaciones
    function cargarEvaluaciones() {
        setLoading(true); // Mostrar spinner de carga
        axios.get(SERVER_URL + "/get_evaluaciones/",
            { headers: { 'Authorization': 'Token ' + token } }
        )
            .then((response) => {
                console.log(response.data.page_data);
                setEvaluaciones(response.data.results);
                const urlNext = response.data.page_data.next;
                // const urlPrevious = response.data.page_data.previous;
                // const nextRelativeUrl = urlNext ? NGINX_BACKEND_PATH + urlNext.substring(urlNext.indexOf('/get_evaluaciones')) : null;
                // const previousRelativeUrl = urlPrevious ? NGINX_BACKEND_PATH + urlPrevious.substring(urlPrevious.indexOf('/get_evaluaciones')) : null;
                // setNextPage(nextRelativeUrl);
                // setPreviousPage(previousRelativeUrl);
                const urlPrevious = response.data.page_data.previous;
                const nextRelativeUrl = urlNext ? "/prueba" + urlNext.substring(urlNext.indexOf('/get_evaluaciones')) : null;
                const previousRelativeUrl = urlPrevious ? "/prueba" + urlPrevious.substring(urlPrevious.indexOf('/get_evaluaciones')) : null;
                setNextPage(nextRelativeUrl);
                setPreviousPage(previousRelativeUrl);
                setPageData(response.data.page_data);
                setLoading(false); // Ocultar spinner de carga
            })
            .catch((error) => {
                console.log(error);
                setLoading(false); // Ocultar spinner de carga en caso de error
            });
    }

    useEffect(() => {
        cargarEvaluaciones();
    }, []);

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    console.log(evaluaciones && evaluaciones)

    return (
        <>
            <Helmet>
                <title>Evaluaciones | Lista</title>
            </Helmet>

            <Container maxWidth="xl" className="MuiContainer-root MuiContainer-fullWidth">

                <Stack
                    direction={isMobile ? 'column' : 'row'}
                    alignItems={isMobile ? 'flex-start' : 'center'}
                    justifyContent={isMobile ? 'flex-start' : 'space-between'} en dispositivos móviles
                    mt={isMobile ? 4 : 0}
                    mb={5}
                >
                    <Typography variant="h4">
                        Evaluaciones
                    </Typography>
                </Stack>

                {/* Tabla de Evaluaciones */}
                {user_data.group === "uploader" || user_data.group === 'administrator' ? (
                    <Card sx={{ p: 1 }}>
                        {loading ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 2 }}>
                                <CircularProgress />
                            </Box>
                        ) : evaluaciones && evaluaciones.length > 0 ? (
                            <Scrollbar>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Fecha del Evento</TableCell>
                                                <TableCell>Municipio</TableCell>
                                                <TableCell>Actividad</TableCell>
                                                <TableCell>Descripción</TableCell>
                                                <TableCell>Artística Definida</TableCell>
                                                <TableCell>Inversión Estimada</TableCell>
                                                <TableCell>Cantidad de Asistentes Estimada</TableCell>
                                                {user_data.group === "administrator" && (
                                                    <TableCell align="center">Acciones</TableCell>
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {evaluaciones.map((evaluacion) => (
                                                <TableRow
                                                    key={evaluacion.id}
                                                    onClick={() => handleOpenModalEdit(evaluacion)}
                                                    sx={{ '&:hover': { backgroundColor: 'rgba(99, 115, 129, 0.08)' } }}
                                                >
                                                    <TableCell>
                                                        {moment.utc(evaluacion.solicitud.fecha_evento).format('DD-MM-YYYY')}
                                                    </TableCell>
                                                    <TableCell>{evaluacion.solicitud.municipio_nombre}</TableCell>
                                                    <TableCell>{evaluacion.solicitud.actividad}</TableCell>
                                                    <TableCell>{evaluacion.solicitud.descripcion}</TableCell>
                                                    <TableCell>{evaluacion.artistica_definida}</TableCell>
                                                    <TableCell>${evaluacion.inversion_estimada}</TableCell>
                                                    <TableCell>{evaluacion.cantidad_asistentes_estimada}</TableCell>

                                                    {user_data.group === "administrator" && (
                                                        <TableCell>
                                                            <Box sx={{ display: 'flex' }}>
                                                                <Button title="Editar" variant="outlined" color='primary'>
                                                                    <Iconify icon="bi:pencil" />
                                                                </Button>
                                                            </Box>
                                                        </TableCell>
                                                    )}
                                                </TableRow>
                                            ))}
                                        </TableBody>

                                    </Table>
                                    <Grid container spacing={2} sx={{ display: 'flex', padding: 2, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                        <Grid item>
                                            <Typography variant="b">
                                                Página {pageData.current_page_number} de {pageData.total_pages}
                                            </Typography>
                                            {previousPage && (
                                                <Button onClick={() => handleNextPage('previous', previousPage)}>
                                                    <Iconify icon="material-symbols:arrow-circle-left" />
                                                </Button>
                                            )}
                                            {nextPage && (
                                                <Button onClick={() => handleNextPage('next', nextPage)}>
                                                    <Iconify icon="material-symbols:arrow-circle-right" />
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                </TableContainer>
                            </Scrollbar>
                        ) : (
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>No hay evaluaciones</Box>
                        )}
                    </Card>
                ) : (
                    <div>No tienes permisos de evaluador</div>
                )}

                <ModalEvaluacion
                    evaluacion={selectedEvaluacion}
                    onClose={handleCloseModal}
                    isEdit={isEdit}
                    open={openModal}
                    cargarEvaluaciones={cargarEvaluaciones}
                />

                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setOpenSnackbar(false)}
                    message={snackbarMessage}
                    onMouseEnter={() => setOpenSnackbar(false)}
                >
                    <Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
                </Snackbar>

            </Container>
        </>
    );
}
