import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    municipios: [],
    areas: [],
    secciones_electorales: []
};

export const initialInfoSlice = createSlice({
    name: "initial_info",
    initialState: initialState,
    reducers: {
        addMunicipios: (state, action) => {
            state.municipios = action.payload.municipios;
        },
        addAreas: (state, action) => {
            state.areas = action.payload.areas;
        },
        addSeccionesElectorales: (state, action) => {
            state.secciones_electorales = action.payload.secciones_electorales;
        }
    },
});

export const initialInfoActions = initialInfoSlice.actions;
export default initialInfoSlice.reducer;