import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios'
import { Card, Table, TableHead, Snackbar, Divider, useMediaQuery, useTheme, Select, CircularProgress, InputLabel, Checkbox, FormControlLabel, FormControl, Box, Chip, Button, Grid, Stack, Scr, TableRow, TextField, MenuItem, TableBody, TableCell, Container, Typography, IconButton, TableContainer, TablePagination, Modal, } from '@mui/material';
import DateTime from 'react-datetime';
import moment from 'moment';
import dayjs from 'dayjs';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ModalValidacion = ({ open, snackbarMessage, selectedSolicitud, cargarValidaciones, get_municipio_detail, setValidaciones, executeFetch, setSnackbarMessage, get_solicitudes, openSnackbar, setOpenSnackbar, handleCloseModal, token, user_data, selectedValidacion, setSnackbarSeverity, editarSolicitud, isEdit }) => {


    const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV
    const dispatch = useDispatch();
    const solicitudRef = useRef(null);
    const justificacionRef = useRef(null);
    const areaAccionRef = useRef(null);
    const responsableProduccionRef = useRef(null);
    const [evaluacionAprobada, setEvaluacionAprobada] = useState(selectedValidacion ? selectedValidacion.estado_validacion : '');
    const [fechaEvento, setFechaEvento] = useState(null);
    const [hora, setHora] = useState(null);
    const [buttonLoadingValidacion, setButtonLoadingValidacion] = useState(false)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const [Areas, setAreas] = useState([]);
    const [areaResponsable, setAreaResponsable] = useState('');


    // Info de solicitudes
    const actividadRef = useRef(selectedValidacion && selectedValidacion.actividad);
    const artisticaSugeridaRef = useRef(selectedValidacion && selectedValidacion.artistica_sugerida);
    const cargoResponsableLocalRef = useRef(selectedValidacion && selectedValidacion.cargo_responsable_local);
    const contactoResponsableLocalRef = useRef(selectedValidacion && selectedValidacion.contacto_responsable_local);
    const descripcionRef = useRef(selectedValidacion && selectedValidacion.descripcion);
    const espacioRef = useRef(selectedValidacion && selectedValidacion.espacio_colectivo);
    const lugarRef = useRef(selectedValidacion && selectedValidacion.lugar);
    const [selectedModalidad, setSelectedModalidad] = useState(
        selectedValidacion && selectedValidacion.modalidad
            ? selectedValidacion.modalidad
            : ''
    ); const estadoSolicitudRef = useRef(selectedValidacion && selectedValidacion.estado_solicitud);
    const gestionSolicitudRef = useRef(selectedValidacion && selectedValidacion.gestion_solicitud);
    const nombreResponsableLocalRef = useRef(selectedValidacion && selectedValidacion.nombre_responsable_local);
    const urlNotaPdfRef = useRef(selectedValidacion && selectedValidacion.url_nota_pdf);
    const municipioNombreRef = useRef(selectedValidacion && selectedValidacion.municipio_nombre);
    const fechaEventoRef = useRef(selectedValidacion && selectedValidacion.fecha_evento);
    const horarioEstimadoRef = useRef(selectedValidacion && selectedValidacion.horario_estimado);

    // Municipios
    const municipios = useSelector((state) => state.initial_info.municipios);
    const [municipioSeleccionado, setMunicipioSeleccionado] = useState(null);
    const [region, setRegion] = useState(null)
    const [gobierno, setGobierno] = useState(null)
    const [seccionElectoral, SetSeccionElectoral] = useState(null)

    // LOGICA PARA EDITAR SOLICITUD EN MODAL VALIDACION
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const handleChangeMunicipio = event => {
        const idMunicipio = event.target.value;
        const municipio = municipios.find(m => m.id === idMunicipio);

        setMunicipioSeleccionado(municipio);
        setRegion(municipio.region_nombre)
        setGobierno(municipio.gobierno_nombre)
        SetSeccionElectoral(municipio.seccion_electoral_nombre)

        if (isEdit && selectedValidacion) {
            selectedValidacion.solicitud.municipio = event.target.value;
        }

        if (!isEdit && selectedValidacion) {
            selectedValidacion.municipio = event.target.value;
        }

    };


    const handleEditarSolicitud = () => {
        const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV
        setLoading(true);

        const modalidadValue = selectedModalidad !== "" ? selectedModalidad : isEdit ? selectedValidacion.solicitud.modalidad : selectedValidacion.modalidad;
        const areaValue = areaResponsable !== "" ? areaResponsable : isEdit ? selectedValidacion.solicitud.area_responsable : selectedValidacion.area_responsable;
        const idSolicitud = isEdit ? selectedValidacion.solicitud.id : selectedValidacion.id;
       

        const data = {
            id_solicitud: idSolicitud || selectedValidacion.id,
            actividad: actividadRef.current.value,
            area_responsable: areaValue,
            artistica_sugerida: artisticaSugeridaRef.current.value,
            cargo_responsable_local: cargoResponsableLocalRef.current.value,
            contacto_responsable_local: contactoResponsableLocalRef.current.value,
            descripcion_solicitud: descripcionRef.current.value,
            lugar: lugarRef.current.value,
            estado_solicitud: estadoSolicitudRef.current.value,
            gestion_solicitud: gestionSolicitudRef.current.value,
            nombre_responsable_local: nombreResponsableLocalRef.current.value,
            fecha_evento: dayjs(fechaEvento).format('DD-MM-YYYY'),
            horario_estimado: hora ? dayjs(hora).format('HH:mm') : '',
            // id_municipio:municipioSeleccionado ? municipioSeleccionado.id : selectedValidacion.solicitud.municipio,
            id_municipio: municipioSeleccionado ? municipioSeleccionado.id : (isEdit ? selectedValidacion.solicitud.municipio : selectedValidacion.municipio),
            espacio_colectivo: espacioRef.current.value,
            modalidad: modalidadValue,

        };

        axios.put(SERVER_URL + '/actualizar/solicitud/', data, {
            headers: {
                'Authorization': 'Token ' + token
            },
        })
            .then((response) => {
                if (executeFetch) {
                    // Ejecutar la función correspondiente para el fetch en el componente Mapa
                    get_municipio_detail();
                } else {
                    if (isEdit) {
                        cargarValidaciones();
                    } else {
                        get_solicitudes();
                    }
                }
                setLoading(false);
                setSnackbarSeverity('success')
                setSnackbarMessage('Solicitud editada con éxito!');
                setOpenSnackbar(true);

            })
            .catch((error) => {
                setSnackbarSeverity('error')
                setSnackbarMessage(error.response.data.error);
                setOpenSnackbar(true);
                setLoading(false);
            });
    };

    function validarCampos(justificacion, areaAccion, responsableProduccion) {
        const errors = {};
        if (!justificacion) {
            errors.justificacion = 'El campo justificación es obligatorio';
        } else if (justificacion.length > 255) {
            errors.justificacion = 'El campo justificación no puede tener más de 255 caracteres';
        }

        if (!areaAccion) {
            errors.areaAccion = 'El campo área de acción es obligatorio';
        } else if (areaAccion.length > 255) {
            errors.areaAccion = 'El campo área de acción no puede tener más de 255 caracteres';
        }

        if (!responsableProduccion) {
            errors.responsableProduccion = 'El campo responsable de producción es obligatorio';
        } else if (responsableProduccion.length > 255) {
            errors.responsableProduccion = 'El campo responsable de producción no puede tener más de 255 caracteres';
        }

        return Object.keys(errors).length === 0 ? true : errors;
    }

    function validarCorreoElectronico(email) {
        // Expresión regular para verificar el formato del correo electrónico
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }


    function handleAddValidacion(event) {
        event.preventDefault();
        if (user_data.group === "validator" || user_data.group === "administrator" || user_data.group === "extendedvalidator") {
            const justificacion = justificacionRef.current.value;
            const areaAccion = areaAccionRef.current.value;


            const responsableProduccion = responsableProduccionRef.current.value;
            if (!validarCorreoElectronico(responsableProduccion)) {
                setSnackbarSeverity('error');
                setSnackbarMessage('El correo electrónico ingresado no es válido.');
                setOpenSnackbar(true);
                return; // Detener la ejecución si el correo electrónico no es válido
            }

            const validationResult = validarCampos(justificacion, areaAccion, responsableProduccion);
            if (validationResult === true) {
                const formData = {
                    id_solicitud: selectedValidacion.id,
                    evaluacion_aprobada: evaluacionAprobada,
                    justificacion: justificacion,
                    area_accion: areaAccion,
                    responsable_produccion: responsableProduccion,
                    fecha_evento: moment.utc(fechaEvento, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                    actividad: actividadRef.current.value,
                    area_responsable: areaResponsable,
                    artistica_sugerida: artisticaSugeridaRef.current.value,
                    cargo_responsable_local: cargoResponsableLocalRef.current.value,
                    contacto_responsable_local: contactoResponsableLocalRef.current.value,
                    descripcion: descripcionRef.current.value,
                    //municipio_nombre: municipioNombreRef.current.value,
                    lugar: lugarRef.current.value,
                    horario_estimado: dayjs(hora).format('HH:mm'),
                    modalidad: selectedModalidad,
                    estado_solicitud: estadoSolicitudRef.current.value,
                    gestion_solicitud: gestionSolicitudRef.current.value,
                    nombre_responsable_local: nombreResponsableLocalRef.current.value,
                };
                setButtonLoadingValidacion(true);

                axios.post(SERVER_URL + "/crear/validacion/", formData, {
                    headers: { 'Authorization': 'Token ' + token }
                })
                    .then((response) => {
                        if (executeFetch) {
                            get_municipio_detail();
                        } else {
                            get_solicitudes();

                        }
                        setButtonLoadingValidacion(false);

                        // setReRender(reRender + 1)
                        setSnackbarSeverity('success')
                        setSnackbarMessage('¡Validación creada con éxito!');
                        setOpenSnackbar(true);
                        handleCloseModal();

                    })
                    .catch((error) => {
                        setButtonLoadingValidacion(false);
                        console.log('error', error.response.data.error);
                        setSnackbarSeverity('error')
                        setSnackbarMessage(error.response.data.error);
                        setOpenSnackbar(true);
                    })

            } else {
                setSnackbarSeverity('error')
                setOpenSnackbar(true);
                Object.keys(validationResult).forEach((fieldName) => {
                    const errorMessage = validationResult[fieldName];
                    setSnackbarMessage(`${errorMessage}`);
                });
            }
        } else {
            console.log('No tienes permisos de validator');
        }
    }


    function handleEditValidacion(validacion) {

        const justificacion = justificacionRef.current.value;
        const areaAccion = areaAccionRef.current.value;
        const responsableProduccion = responsableProduccionRef.current.value;
        if (!validarCorreoElectronico(responsableProduccion)) {
            setSnackbarSeverity('error');
            setSnackbarMessage('El correo electrónico ingresado no es válido.');
            setOpenSnackbar(true);
            return; // Detener la ejecución si el correo electrónico no es válido
        }

        const validationResult = validarCampos(justificacion, areaAccion, responsableProduccion);

        if (validationResult === true) {
            const formData = {
                id_validacion: validacion.id,
                evaluacion_aprobada: evaluacionAprobada,
                justificacion: justificacion,
                area_accion: areaAccion,
                responsable_produccion: responsableProduccion,
            };
            setButtonLoadingValidacion(true);

            axios.put(SERVER_URL + '/actualizar/validacion/', formData, {
                headers: { 'Authorization': 'Token ' + token }
            })
                .then((response) => {
                    cargarValidaciones(response.data);
                    setButtonLoadingValidacion(false);

                    setSnackbarSeverity('success')
                    setSnackbarMessage('¡Validación actualizada con éxito!');
                    setOpenSnackbar(true);
                    handleCloseModal();
                    cargarValidaciones()
                    // setReRender(reRender + 1)

                })
                .catch((error) => {
                    setButtonLoadingValidacion(false);

                    console.log(error)
                    setSnackbarSeverity('error')
                    setSnackbarMessage('¡Hubo un error al actualizar la validación!');
                    setOpenSnackbar(true);
                });
        } else {
            setSnackbarSeverity('error')
            setOpenSnackbar(true);
            Object.keys(validationResult).forEach((fieldName) => {
                const errorMessage = validationResult[fieldName];
                setSnackbarMessage(`${errorMessage}`);
            });
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            handleCloseModal();
            setEvaluacionAprobada('')
        }
    };



    const get_areas = () => {
        axios
            .get(SERVER_URL + "/get_areas/", {
                headers: {
                    Authorization: "Token " + token,
                },
            })
            .then((response) => {
                console.log(response.data.areas)
                setAreas(response.data.areas);
            })
            .catch((error) => {
                console.log(error.response.data.error);
            });
    };

    function convertDateToInputFormat(dateString) {
        if (dateString === null || dateString === '') {
            return null;
        }

        const date = new Date(dateString);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    useEffect(() => {
        if (isEdit && selectedValidacion) {
            setEvaluacionAprobada(selectedValidacion.estado_validacion);
            setSelectedModalidad(selectedValidacion.solicitud.modalidad);
            setAreaResponsable(selectedValidacion.solicitud.area_responsable);

        }
    }, [isEdit, selectedValidacion]);

    useEffect(() => {
        if (selectedValidacion) {
            isEdit ? setFechaEvento(convertDateToInputFormat(selectedValidacion.solicitud.fecha_evento)) : setFechaEvento(convertDateToInputFormat(selectedValidacion.fecha_evento));
        }
    }, [selectedValidacion]);

    useEffect(() => {
        get_areas();
    }, []);


    return (
        <Modal onKeyDown={handleKeyDown} open={open} handleCloseModal={handleCloseModal}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "90%", height: "100%", margin: "auto", overflow: 'auto' }}>
                <Box sx={{
                    backgroundColor: "#f4f6f8",
                    padding: "40px",
                    borderRadius: "5px",
                    maxWidth: "100%", // Mantener medida anterior o tomar medida nueva
                    width: "100%",
                    height: "90%",
                    overflow: "auto"
                }}>                    <Typography variant="h4" sx={{ color: '#1c3c6a', marginBottom: 5 }}>{isEdit ? 'Editar solicitud' : 'Edición de solicitud'}</Typography>
                    {
                        selectedValidacion ?
                            <Box sx={{ display: 'flex', width: '100%' }}>
                                {
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                inputRef={actividadRef}
                                                label="Actividad"
                                                defaultValue={isEdit ? selectedValidacion.solicitud.actividad : selectedValidacion.actividad}
                                                sx={{ m: 1 }}
                                            />


                                            <TextField
                                                inputRef={artisticaSugeridaRef}
                                                label="Artística sugerida"
                                                defaultValue={isEdit ? selectedValidacion.solicitud.artistica_sugerida : selectedValidacion.artistica_sugerida}
                                                sx={{ m: 1 }}
                                            />

                                            <Select
                                                defaultValue={isEdit ? selectedValidacion.solicitud.area_responsable : selectedValidacion.area_responsable}
                                                onChange={(e) => setAreaResponsable(e.target.value)}
                                                sx={{ m: 1 }}
                                            >
                                                {Areas.map((area) => (
                                                    <MenuItem key={area.id} value={area.nombre}>
                                                        {area.nombre}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <TextField
                                                inputRef={nombreResponsableLocalRef}
                                                label="Nombre del responsable local"
                                                defaultValue={isEdit ? selectedValidacion.solicitud.nombre_responsable_local : selectedValidacion.nombre_responsable_local}
                                                sx={{ m: 1 }}
                                            />
                                            <TextField
                                                inputRef={cargoResponsableLocalRef}
                                                label="Cargo responsable local"
                                                defaultValue={isEdit ? selectedValidacion.solicitud.cargo_responsable_local : selectedValidacion.cargo_responsable_local}
                                                sx={{ m: 1 }}
                                            />
                                            <TextField
                                                inputRef={contactoResponsableLocalRef}
                                                type="number"
                                                label="Numero de responsable local"
                                                defaultValue={isEdit ? selectedValidacion.solicitud.contacto_responsable_local : selectedValidacion.contacto_responsable_local}
                                                sx={{ m: 1 }}
                                            />
                                            <TextField
                                                inputRef={gestionSolicitudRef}
                                                label="Gestión de la solicitud"
                                                defaultValue={isEdit ? selectedValidacion.solicitud.gestion_solicitud : selectedValidacion.gestion_solicitud}
                                                sx={{ m: 1 }}
                                            />
                                            <TextField
                                                inputRef={descripcionRef}
                                                label="Descripción"
                                                defaultValue={isEdit ? selectedValidacion.solicitud.descripcion : selectedValidacion.descripcion}
                                                sx={{ m: 1 }}
                                            />
                                            <TextField
                                                inputRef={espacioRef}
                                                label="Espacio/Colectivo"
                                                defaultValue={isEdit ? selectedValidacion.solicitud.espacio_colectivo : selectedValidacion.espacio_colectivo}
                                                sx={{ m: 1 }}
                                            />

                                        </Grid>
                                        <Grid item xs={12} sm={6}>

                                            <TextField
                                                inputRef={lugarRef}
                                                label="Lugar"
                                                defaultValue={isEdit ? selectedValidacion.solicitud.lugar : selectedValidacion.lugar}
                                                sx={{ m: 1 }}
                                            />

                                            <FormControl sx={{ m: 0, pt: 0, width: 220, ml: 1, mt: 1 }}>
                                                <InputLabel id="modalidad-select-label">Modalidad</InputLabel>
                                                <Select
                                                    labelId="modalidad-select-label"
                                                    value={isEdit ? selectedValidacion.solicitud.modalidad : selectedValidacion.modalidad}
                                                    label='modalidad'
                                                    onChange={(event) => {
                                                        const newValue = event.target.value;
                                                        setSelectedModalidad(newValue);
                                                        if (isEdit && selectedValidacion) {
                                                            selectedValidacion.solicitud.modalidad = newValue;
                                                        }
                                                        if (!isEdit && selectedValidacion) {
                                                            selectedValidacion.modalidad = newValue;
                                                        }
                                                    }}
                                                >

                                                    <MenuItem value="asistencia">Asistencia</MenuItem>
                                                    <MenuItem value="propuesta">Propuesta</MenuItem>
                                                    <MenuItem value="programacion">Programación</MenuItem>
                                                    <MenuItem value="subsidio">Subsidio</MenuItem>
                                                    <MenuItem value="capacitación">Capacitación</MenuItem>
                                                    <MenuItem value="declaración">Declaración</MenuItem>

                                                </Select>
                                            </FormControl>


                                            <TextField
                                                inputRef={estadoSolicitudRef}
                                                label="Estado de la solicitud"
                                                defaultValue={isEdit ? selectedValidacion.solicitud.estado_solicitud : selectedValidacion.estado_solicitud}
                                                sx={{ p: 1, mt: isMobile ? 2 : 0, width: 236 }}
                                                disabled
                                            />

                                            <Box sx={{ display: 'flex', alignItems: isMobile ? 'left' : 'flex-end', flexDirection: isMobile ? 'column' : 'row', width: isMobile ? 220 : 'auto' }}>

                                                <FormControl sx={{ p: 0, mt: 2, ml: 1, width: 220 }}>
                                                    <DateTime
                                                        dateFormat={false}
                                                        timeFormat="HH:mm"
                                                        initialValue={isEdit ? moment(selectedValidacion.solicitud.horario_estimado, 'HH:mm') : moment(selectedValidacion.horario_estimado, 'HH:mm')}
                                                        onChange={(momento) => setHora(momento)}
                                                        className="datetime"
                                                        inputProps={{ placeholder: 'Hora', onKeyPress: (event) => { if (!/[0-9:]/.test(event.key) || event.target.value.length >= 5) { event.preventDefault(); } } }}
                                                    />

                                                </FormControl>
{console.log(fechaEvento)}
                                                <FormControl sx={{ p: 0, mt: 1, ml: 1, width: 220 }}>
                                                    <TextField
                                                        type="date"
                                                        value={fechaEvento}                                                      
                                                        onChange={(e) => setFechaEvento(e.target.value)}
                                                        min="yyyy-MM-dd"
                                                        max="yyyy-MM-dd"

                                                    />

                                                </FormControl>

                                                <FormControl variant="standard" sx={{ ml: 1.3, mt: 2 }}>
                                                    <InputLabel id="demo-simple-select-standard-label">Municipio</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        label="Municipio"
                                                        // ref={municipioRef}
                                                        //value={municipioSeleccionado ? municipioSeleccionado.id : ''}
                                                        value={isEdit ? selectedValidacion.solicitud.municipio : selectedValidacion.municipio}
                                                        onChange={handleChangeMunicipio}
                                                    >
                                                        {municipios.map(municipio => (
                                                            <MenuItem key={municipio.id} value={municipio.id}>
                                                                {municipio.nombre}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Box sx={{ display: 'flex', m: 1, alignItems: 'flex-end', }}>
                                            <Button
                                                variant="contained"
                                                disabled={loading}
                                                onClick={handleEditarSolicitud}
                                                sx={{ width: isMobile ? '220px' : 'auto' }}
                                            // style={{ backgroundColor: success ? 'green' : error ? 'red' : null }}
                                            >
                                                {loading ? (
                                                    <CircularProgress size={24} color="inherit" />
                                                ) : (
                                                    'Editar solicitud'
                                                )}
                                            </Button>
                                        </Box>
                                    </Grid>

                                }
                            </Box>

                            : ''
                    }
                    <Divider sx={{ mt: 2 }} />
                    <Typography variant="h4" sx={{ color: '#1c3c6a', marginBottom: 5 }}>{isEdit ? 'Editar validacion' : 'Validar solicitud'}</Typography>
                    <FormControl sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormControl sx={{ m: 0, pt: 0, width: 200 }}>
                                    <InputLabel id="evaluacion-aprobada-select-label">Solicitud aprobada</InputLabel>
                                    <Select
                                        labelId="evaluacion-aprobada-select-label"
                                        value={evaluacionAprobada}
                                        label={'Solicitud aprobada'}
                                        onChange={(event) => {
                                            const newValue = event.target.value;
                                            setEvaluacionAprobada(newValue);
                                            if (isEdit && selectedValidacion) {
                                                selectedValidacion.evaluacion_aprobada = newValue;
                                            }
                                        }}
                                    >
                                        <MenuItem value="">Seleccionar</MenuItem>
                                        <MenuItem value="Aprobado">Aprobado</MenuItem>
                                        <MenuItem value="Desaprobado">Desaprobado</MenuItem>
                                        <MenuItem value="Otro">Otro</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Responsable de producción"
                                    // defaultValue={isEdit && selectedValidacion ? selectedValidacion.creator_email : ''}
                                    defaultValue={selectedValidacion ? selectedValidacion.responsable_produccion : ''}
                                    variant="outlined"
                                    fullWidth
                                    inputRef={responsableProduccionRef}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Área de acción"
                                    defaultValue={isEdit && selectedValidacion ? selectedValidacion.area_accion : ''}
                                    variant="outlined"
                                    fullWidth
                                    inputRef={areaAccionRef}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ mt: 0, p: 0 }}
                                    label="Justificación"
                                    defaultValue={isEdit && selectedValidacion ? selectedValidacion.justificacion : ''}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    inputRef={justificacionRef}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="flex-end">
                                    <Button sx={{ m: 1 }} onClick={handleCloseModal} type="button" variant="outlined" color="primary">
                                        Cancelar
                                    </Button>

                                    <Button
                                        sx={{ m: 1 }}
                                        onClick={isEdit && selectedValidacion ? () => handleEditValidacion(selectedValidacion) : handleAddValidacion}
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        disabled={buttonLoadingValidacion} // Desactivar el botón mientras se carga
                                    >
                                        {buttonLoadingValidacion ? <CircularProgress color="inherit" size={24} /> : (isEdit ? "Actualizar validación" : "Validar solicitud")}
                                    </Button>



                                </Grid>
                            </Grid>
                        </Grid>
                    </FormControl>
                </Box>
            </Box>
        </Modal>
    );
};


export default ModalValidacion