import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { CircularProgress ,useMediaQuery, useTheme ,  Card, Stack, Container, Modal, Box, Button, Snackbar, Alert, Typography, TableContainer, TableBody, Table, TableHead, TableRow, TableCell } from '@mui/material';
import axios from 'axios';
import Scrollbar from '../components/scrollbar';
import Iconify from "../components/iconify";
import CreateMunicipioModal from "src/modal/CreateMunicipioModal.js";
import EditMunicipioModal from 'src/modal/EditMunicipioModal';
import '../modal/modal.css'
import { initialInfoActions } from "../redux/Initial_info_slice";
import { useSelector, useDispatch} from "react-redux";

const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV;
const user_data = JSON.parse(localStorage.getItem("user_data"));

const MunicipioPage = () => {
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [municipioIdToDelete, setMunicipioIdToDelete] = useState(null);
  const [municipios, setMunicipios] = useState([]);
  const token = localStorage.getItem('token');
  const [initialInfo, setInitialInfo] = useState();

  const [selectedMunicipio, setSelectedMunicipio] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch()

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };


  const getMunicipios = () => {
    axios.get(`${SERVER_URL}/get_municipios/`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        setMunicipios(response.data.municipios);
        setInitialInfo(response.data);
        setLoading(false);
        dispatch(initialInfoActions.addMunicipios({
          municipios: response.data.municipios
        }));
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setMunicipioIdToDelete(null);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedMunicipio(null);
  };

  const handleOpenModalEdit = (municipio) => {

    setSelectedMunicipio(municipio);
    setIsEditModalOpen(true);
  };



  const handleDeleteMunicipio = () => {
    if (municipioIdToDelete) {
      axios.post(`${SERVER_URL}/eliminar/municipio/`, {
        municipioId: municipioIdToDelete,
      }, {
        headers: { Authorization: `Token ${token}` },
      })
        .then((response) => {
          showSnackbar('El municipio ha sido eliminado correctamente','success')
          console.log(`Municipio eliminado con ID: ${municipioIdToDelete}`);
          getMunicipios();
        })
        .catch((error) => {
          console.log(error);
        });
    }
    handleCloseModal();
  };

  useEffect(() => {
    getMunicipios();
  }, []);

  const renderTable = () => {
    if (municipios.length === 0) {
      return <Typography variant="body1">No hay municipios</Typography>;
    }

    return (
      <Card>
        <Scrollbar sx={{ p: 1 }}>
          <TableContainer sx={{ overflow: 'auto', minHeight: 555 }}>

            <Table>
              <TableHead>
                <TableRow sx={{ height: '83px' }}>
                  <TableCell>Municipio</TableCell>
                  <TableCell>Gobierno</TableCell>
                  <TableCell>Intendente</TableCell>
                  <TableCell>Región</TableCell>
                  <TableCell>Sección Electoral</TableCell>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {municipios.map((municipio) => (
                  <TableRow
                    sx={{ '&:hover': { backgroundColor: 'rgba(99, 115, 129, 0.08)' } }}
                    onClick={() => handleOpenModalEdit(municipio)}
                    key={municipio.id}
                  >
                    <TableCell>{municipio.nombre}</TableCell>
                    <TableCell>{municipio.gobierno_nombre}</TableCell>
                    <TableCell>{municipio.intendente}</TableCell>
                    <TableCell>{municipio.region_nombre}</TableCell>
                    <TableCell>{municipio.seccion_electoral_nombre}</TableCell>
                    <TableCell align="center">
                      {user_data && user_data.group === "administrator" && (
                        <Box>
                          <Button
                            sx={{ m: 1 }}
                            variant="outlined"
                            color="error"
                            onClick={(e) => {
                              e.stopPropagation();
                              setMunicipioIdToDelete(municipio.id);
                            }}
                          >
                            <Iconify icon="bi:trash" />
                          </Button>
                          <Button variant="outlined" color="primary" sx={{ m: 1 }} onClick={() => handleOpenModalEdit(municipio)}>
                            <Iconify icon="bi:pencil" />
                          </Button>
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    );
  };

  return (
    <>
      <Helmet>
        <title>Municipios | Lista</title>
      </Helmet>

      <Container maxWidth="xl" className="MuiContainer-root MuiContainer-fullWidth">
      <Stack
					direction={isMobile ? 'column' : 'row'} 
					alignItems={isMobile ? 'flex-start' : 'center'} 	
      		justifyContent={isMobile ? 'flex-start' : 'space-between'} en dispositivos móviles
          mt={isMobile ? 4 : 0} 

					mb={5}
				>         
          <Typography variant="h4" gutterBottom>
            Municipios
          </Typography>

          {user_data && user_data.group === "administrator" && (
            <Button
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                setIsModalOpen(true);
              }}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Nuevo municipio
            </Button>
          )}
        </Stack>

        {loading ? (
          <Box sx={{ overflow: 'auto', minHeight: 555, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : renderTable()}

        {/* MOdal de create municipio */}
        <CreateMunicipioModal
          getMunicipios={getMunicipios}
          initialInfo={initialInfo}
          open={isModalOpen}
          onClose={handleCloseModal}
        />

        {/* MOdal de edit municipio */}
        {selectedMunicipio && (
          <EditMunicipioModal
            municipio={selectedMunicipio}
            open={isEditModalOpen}
            onClose={handleCloseEditModal}
            initialInfo={initialInfo}
            getMunicipios={getMunicipios}
          />
        )}

        {municipioIdToDelete && (
          <Modal open={true} onClose={handleCloseModal}>
            <Box className="modal-box" sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "84%", height: "100%", margin: "auto", }}>
              <Box sx={{ backgroundColor: "#f4f6f8", padding: "40px", borderRadius: "5px" }}>
                <Typography variant="body1">
                  ¿Estás seguro de que deseas eliminar el municipio?
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", mt: 2 }}>
                  <Button sx={{ m: 1 }} onClick={() => handleCloseModal()}>
                    Cancelar
                  </Button>
                  <Button variant="outlined" color="error" sx={{ m: 1 }} onClick={() => handleDeleteMunicipio()}>
                    Eliminar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        )}

        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
          <Alert autoHideDuration={6000}  onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

      </Container>
    </>
  );
};

export default MunicipioPage;
