import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import { useSelector, useDispatch} from "react-redux";
import { userActions } from "./redux/userSlice";
import { initialInfoActions } from "./redux/Initial_info_slice";
import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import ScrollToTop from './components/scroll-to-top';
import axios from 'axios';


// ----------------------------------------------------------------------

export default function App() {
  const token = localStorage.getItem('token')

  const dispatch = useDispatch()
  const is_authenticated = useSelector(state => state.user.is_authenticated);
  const [isLoading, setIsLoading] = useState(true);
  const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV

  const get_initial_info = () => {

    let data = {
      modules: true,
      municipios: true,
      areas: true,
      secciones_electorales: true,
      user_data: true
    }


    axios.post(SERVER_URL+"/initial_info/", data,
      { headers: { 'Authorization': 'Token ' + token} }
    )
      .then((response) => {
        setIsLoading(false); // Cambiar el estado de isLoading a false después de cargar la información
        // console.log(response.data.initial_info.municipios)

        dispatch(userActions.addUserModules({ 
          user_modules: response.data.initial_info.modules
        }));

        dispatch(userActions.updateUserData({ 
            first_name: response.data.initial_info.user_data.first_name,
            last_name: response.data.initial_info.user_data.last_name,
            email: response.data.initial_info.user_data.email,
            username: response.data.initial_info.user_data.username,
            id: response.data.initial_info.user_data.id,
            user_group: response.data.initial_info.user_data.user_groups[0],
            is_authenticated: true
        }));


        dispatch(initialInfoActions.addMunicipios({
          municipios: response.data.initial_info.municipios
        }));

        dispatch(initialInfoActions.addAreas({
          areas: response.data.initial_info.areas
        }));

        dispatch(initialInfoActions.addSeccionesElectorales({
          secciones_electorales: response.data.initial_info.secciones_electorales
        }));

      })
      .catch((error) => {
        setIsLoading(false); // Cambiar el estado de isLoading a false después de cargar la información
        console.log('error', error);
      });
  };

  useEffect(() => {
    get_initial_info()
  }, [is_authenticated]);


  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Router /> 
        </ThemeProvider>
      </BrowserRouter>
  </HelmetProvider>
  );
}
