import { Helmet } from "react-helmet-async";
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { Table, TableBody, TableCell, TextField, TableContainer, InputLabel, FormControl, TableHead, ButtonGroup, Select, MenuItem, TableRow, Paper, Card, Button, Box, Typography, Chip, CircularProgress, Container, Stack, Snackbar, Alert } from '@mui/material';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import Iconify from "../components/iconify";
import '../modal/modal.css'
import ModalValidacion from "src/modal/ModalValidacion";
import { useMediaQuery, useTheme } from "@mui/material";
import moment from 'moment';
import ModalSolicitudFinalizada from "src/modal/ModalSolicitudFinalizada";


const MunicipioMapDetail = () => {
    const { id } = useParams();
    const token = localStorage.getItem('token');
    const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV;
    // const NGINX_BACKEND_PATH = process.env.NGINX_BACKEND_PATH;
    const NGINX_BACKEND_PATH = 'https://plataformadegestioncultural.gba.gob.ar/prueba'
    // const NGINX_BACKEND_PATH = 'http://localhost:8081';
    const [openDetalleModal, setOpenDetalleModal] = useState(false);

    const user_data = JSON.parse(localStorage.getItem('user_data'))
    if (!user_data) window.location.replace('/login')

    const [isLoading, setIsLoading] = useState(true);
    const [municipiosMatcheados, setMunicipiosMatcheados] = useState([]);
    const [selectedSolicitud, setSelectedSolicitud] = useState(null); // Estado para almacenar la solicitud seleccionada

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [openModal, setOpenModal] = useState(false); // Estado para controlar la apertura del modal de validación
    const [selectedEstado, setSelectedEstado] = useState('');

    const [executeFetch, setExecuteFetch] = useState(false); // Estado para controlar qué función se ejecuta en el ModalValidacion

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [nextPageUrl, setNextPageUrl] = useState(''); // Agregado: Estado para almacenar la URL de la página siguiente
    const [previousPageUrl, setPreviousPageUrl] = useState(''); // Agregado: Estado para almacenar la URL de la página anterior
    const [paginationData, setPaginationData] = useState()
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState(municipiosMatcheados);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const [filteredEstado, setFilteredEstado] = useState('');

    const handleViewSolicitud = (solicitud) => {
        setSelectedSolicitud(solicitud);
        setOpenDetalleModal(true);
    };


    const handleSearch = () => {
        fetchData(1, searchQuery); // Call the fetchData function with 'searchQuery' as the 'actividadQuery'
    };

    const fetchPreviousPage = async () => {
        try {
            // const response = await axios.get(previousPageUrl);
            const response = await axios.get(previousPageUrl, {
                headers: {
                    Authorization: 'Token ' + token,
                },
            });
            const data = response.data;
            setMunicipiosMatcheados(data.results);
            const urlNext = data.next;
            const urlPrevious = data.previous;

            const nextRelativeUrl = urlNext ? "/prueba" + urlNext.substring(urlNext.indexOf('/get_solicitudes_to_detail_map')) : null;
            const previousRelativeUrl = urlPrevious ? "/prueba" + urlPrevious.substring(urlPrevious.indexOf('/get_solicitudes_to_detail_map')) : null;

            setNextPageUrl(nextRelativeUrl);
            setPreviousPageUrl(previousRelativeUrl);
            setPaginationData(data.pagination)
            setCurrentPage(data.pagination.page_number); // Actualizar el número de página actual

        } catch (error) {
            console.log(error);
        }
    };

    const fetchNextPage = async () => {
        try {
            const response = await axios.get(nextPageUrl, {
                headers: {
                    Authorization: 'Token ' + token,
                },
            });
            // const response = await axios.get(nextPageUrl);
            const data = response.data;
            setMunicipiosMatcheados(data.results);
            const urlNext = data.next;
            const urlPrevious = data.previous;

            const nextRelativeUrl = urlNext ? "/prueba" + urlNext.substring(urlNext.indexOf('/get_solicitudes_to_detail_map')) : null;
            const previousRelativeUrl = urlPrevious ? "/prueba" + urlPrevious.substring(urlPrevious.indexOf('/get_solicitudes_to_detail_map')) : null;

            setNextPageUrl(nextRelativeUrl);
            setPreviousPageUrl(previousRelativeUrl);
            setPaginationData(data.pagination)
            setCurrentPage(data.pagination.page_number); // Actualizar el número de página actual

        } catch (error) {
            console.log(error);
        }
    };

    const fetchData = (page, actividadQuery) => { // Add the 'actividadQuery' parameter
        setIsLoading(true);

        axios
            .get(NGINX_BACKEND_PATH + '/get_solicitudes_to_detail_map/', {
                params: {
                    municipio: id,
                    page: page,
                    estado: filteredEstado,
                    actividad: actividadQuery, // Include the 'actividad' parameter in the API call
                },
                headers: {
                    Authorization: 'Token ' + token,
                },
            })
            .then((response) => {
                const municipios = response.data;
                const municipiosMatcheados = municipios.results;
                setMunicipiosMatcheados(municipiosMatcheados);
                setTotalPages(municipios.total_pages);

                const urlNext = municipios.next;
                const urlPrevious = municipios.previous;

                const nextRelativeUrl = urlNext ? "/prueba" + urlNext.substring(urlNext.indexOf('/get_solicitudes_to_detail_map')) : null;
                const previousRelativeUrl = urlPrevious ? "/prueba" + urlPrevious.substring(urlPrevious.indexOf('/get_solicitudes_to_detail_map')) : null;

                setNextPageUrl(nextRelativeUrl);
                setPreviousPageUrl(previousRelativeUrl);
                setCurrentPage(municipios.pagination.page_number);
                setPaginationData(municipios.pagination)
            })
            .catch((error) => {
                console.error('Error al obtener los municipios:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };


    useEffect(() => {
        fetchData(1);
    }, []);

    const handleDeleteSolicitud = (id) => {
        const data = { solicitud_id: id, };

        axios.post(SERVER_URL + `/eliminar/solicitud/`, data, {
            headers: { Authorization: "Token " + token },
        })
            .then((response) => {
                fetchData(currentPage);
                setOpenSnackbar(true);
                setSnackbarMessage("Solicitud eliminada exitosamente!");
                setSnackbarSeverity("success");
            })
            .catch((error) => {
                console.log("error", error);
            });
    };


    const handleEstadoChange = (event) => {
        setSelectedEstado(event.target.value);
        setFilteredEstado(event.target.value)
    };


    const coloresPorEstado = {
        analisis: { color: 'warning', etiqueta: 'En Análisis' },
        Analisis: { color: 'warning', etiqueta: 'En Análisis' },
        Aprobado: { color: 'success', etiqueta: 'Aprobado' },
        APROBADO: { color: 'success', etiqueta: 'Aprobado' },
        Desaprobado: { color: 'error', etiqueta: 'Desaprobado' },
        DESAPROBADO: { color: 'error', etiqueta: 'Desaprobado' },
        SUSPENDIDA: { color: 'error', etiqueta: 'Suspendido' },
        SUSPENDIDO: { color: 'error', etiqueta: 'Suspendido' },
        Suspendida: { color: 'error', etiqueta: 'Suspendido' },
        Suspendido: { color: 'error', etiqueta: 'Suspendido' },
        Otros: { color: 'info', etiqueta: 'Otros' },
        Otro: { color: 'info', etiqueta: 'Otros' },
        OTROS: { color: 'info', etiqueta: 'Otros' },
        Finalizado: { color: 'secondary', etiqueta: 'Finalizado' },
    };

    const handleOpenModal = (solicitud) => {
        setSelectedSolicitud(solicitud); // Almacenar la solicitud seleccionada en el estado
        setOpenModal(true); // Abrir el modal de validación
        setExecuteFetch(true)
        console.log('so ',solicitud)
    };

    const handleCloseModal = () => {
        setSelectedSolicitud(null); // Limpiar la solicitud seleccionada
        setOpenModal(false); // Cerrar el modal de validación
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };


    return (
        <>
            <Container
                maxWidth="xxl"
                className="MuiContainer-root MuiContainer-fullWidth"
            >
                <Helmet>
                    <title>Solicitudes de un municipio | Lista</title>
                </Helmet>

                <Button sx={{ ml: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'none' }} variant="outlined">
                    <Link className="link-back-map" to="/Mapeo" sx={{ textDecoraton: 'none', color: 'red' }}>
                        <Iconify className="icon-back" icon="pajamas:go-back" />
                        Volver al mapa
                    </Link>
                </Button>

                <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 5, flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'left' : 'center' }}>

                    <Box sx={{ display: 'flex', alignItems: 'center', borderRadius: '5px', p: 1, }}>
                        <TextField
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Actividad"
                            sx={{
                                "& fieldset": {
                                    borderBottomRightRadius: 0,
                                    borderTopRightRadius: 0, height: 63
                                },
                            }}


                        />

                        <Button
                            onClick={handleSearch}
                            sx={{
                                padding: 0,
                                minWidth: 0,
                                width: '60px',
                                outline: '1px solid rgba(32, 101, 209, 0.5);',
                                borderLeft: 0,
                                borderBottomLeftRadius: 0,
                                borderTopLeftRadius: 0,
                                height: 56,
                                mt: 0.2


                            }}
                        >
                            <Iconify
                                style={{ height: 56, width: 33, color: '#2065D1' }}
                                icon="ic:baseline-manage-search"
                            />
                        </Button>
                    </Box>
                    <Box>

                        <FormControl variant="outlined" sx={{ m: 1, }}>
                            <InputLabel id="filtro-por-estado">Filtrar por estado</InputLabel>
                            <Select
                                labelId="filtro-por-estado"
                                value={selectedEstado}
                                defaultValue="Todos los estados"
                                onChange={handleEstadoChange}
                                variant="outlined"
                                label="Filtrar por estado"
                                sx={{ marginRight: '10px', width: isMobile ? 280 : 210, height: 56 }}
                                startAdornment={
                                    <Iconify
                                        style={{ width: '35px', height: '35px', color: '#2065D1' }}
                                        icon="material-symbols:filter-list"
                                    />
                                }
                            >
                                <MenuItem value="">Todos</MenuItem>
                                <MenuItem value="ANALISIS">En Análisis</MenuItem>
                                <MenuItem value="APROBADO">Aprobado</MenuItem>
                                <MenuItem value="DESAPROBADO">Desaprobado</MenuItem>
                                <MenuItem value="FINALIZADO">Finalizado</MenuItem>
                                <MenuItem value="OTROS">Otros</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box >

                        <Button
                            variant="outlined"
                            onClick={() => {
                                setFilteredEstado('');
                                fetchData(1);
                            }}
                            sx={{ marginRight: '10px', height: 56, m: 1 }}
                        >
                            <Iconify
                                style={{ width: '23px', height: '23px', color: '#2065D1' }}
                                icon="mi:filter" />
                            Aplicar filtros
                        </Button>

                        <Button
                            variant="outlined"
                            sx={{ height: 56, m: 1 }}
                            onClick={() => {
                                setSelectedEstado('');
                                fetchData(1);
                            }}
                        >
                            <Iconify
                                style={{ width: '23px', height: '23px', color: '#2065D1' }}
                                icon="mdi:remove-outline" />
                            Limpiar filtros
                        </Button>
                    </Box>

                </Box>




                {isLoading ? (

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: 500 }}>
                        <p>Cargando solicitudes del municipio... </p>
                        <CircularProgress sx={{ m: 1 }} />
                    </Box>

                ) : municipiosMatcheados.length === 0 ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: 500 }}>
                        <p>No hay solicitudes para este municipio.</p>
                    </Box>
                ) : (
                    <Box>
                        <Stack
                            direction={isMobile ? 'column' : 'row'}
                            alignItems={isMobile ? 'flex-start' : 'center'}
                            justifyContent={isMobile ? 'flex-start' : 'space-between'} en dispositivos móviles
                            mt={isMobile ? 4 : 0}

                            mb={5}
                        >
                            <Typography variant="h4" sx={{ p: 1 }}>Municipio: {municipiosMatcheados[0].municipio_nombre}</Typography>

                        </Stack>



                        <Scrollbar sx={{ p: 1 }}>
                            <Card sx={{ p: 1 }}>

                                <TableContainer component={Paper}>

                                    <Table>
                                        <TableHead>
                                            <TableRow
                                                sx={{
                                                    "&:hover": {
                                                        backgroundColor: "rgba(99, 115, 129, 0.08)",
                                                    },
                                                    height: "35px",
                                                }}
                                            >
                                                <TableCell>Municipio</TableCell>
                                                <TableCell>Actividad</TableCell>
                                                <TableCell>Área responsable</TableCell>
                                                <TableCell>Artística sugerida</TableCell>
                                                <TableCell>Descripción</TableCell>
                                                <TableCell>Estado de solicitud</TableCell>
                                                <TableCell>Fecha del evento</TableCell>
                                                <TableCell>Horario estimado</TableCell>
                                                <TableCell>Nota URL PDF</TableCell>
                                                <TableCell>Acciones</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {municipiosMatcheados &&
                                                municipiosMatcheados.map((solicitud) => (
                                                    <TableRow
                                                        key={solicitud.id}
                                                        sx={{
                                                            "&:hover": {
                                                                backgroundColor: "rgba(99, 115, 129, 0.08)",
                                                            },
                                                            height: "35px",
                                                        }}
                                                    >
                                                        <TableCell>{solicitud.municipio_nombre || "-"}</TableCell>
                                                        <TableCell>{solicitud.actividad || "-"}</TableCell>
                                                        <TableCell>{solicitud.area_responsable || "-"}</TableCell>
                                                        <TableCell>{solicitud.artistica_sugerida || "-"}</TableCell>
                                                        <TableCell>{solicitud.descripcion || "-"}</TableCell>
                                                        <TableCell>
                                                            {solicitud.estado_solicitud in coloresPorEstado ? (
                                                                <Chip
                                                                    label={coloresPorEstado[solicitud.estado_solicitud].etiqueta}
                                                                    color={coloresPorEstado[solicitud.estado_solicitud].color}
                                                                    sx={{ color: 'white' }}
                                                                />
                                                            ) : (
                                                                solicitud.estado_solicitud || "-"
                                                            )}
                                                        </TableCell>
                                                        <TableCell>{moment.utc(solicitud.fecha_evento).format('DD-MM-YYYY') || "-"}</TableCell>
                                                        <TableCell>{solicitud.horario_estimado || "-"}</TableCell>
                                                        <TableCell>{solicitud.url_nota_pdf || "-"}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>
                                                            <Stack direction="row" alignItems="center">
                                                                {user_data.group === "administrator" && (
                                                                    <Box sx={{ display: 'flex' }}>
                                                                        <Button
                                                                            sx={{ m: 1 }}
                                                                            variant="outlined"
                                                                            color="error"
                                                                            onClick={() => handleDeleteSolicitud(solicitud.id)}
                                                                        >
                                                                            <Iconify icon="bi:trash" />
                                                                        </Button>

                                                                        <Button
                                                                            sx={{ m: 1 }}
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            onClick={() => handleOpenModal(solicitud)}
                                                                        >
                                                                            <Iconify icon="bi:pencil" />
                                                                        </Button>
                                                                    </Box>
                                                                )}
                                                                {user_data.group === "validator" && (
                                                                    <Button
                                                                        sx={{ m: 1, display: 'flex' }}
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        onClick={() => handleOpenModal(solicitud)}
                                                                    >
                                                                        <Iconify icon="bi:pencil" />
                                                                    </Button>
                                                                )}
                                                                <Button
                                                                    title="Ver solicitud"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleViewSolicitud(solicitud);
                                                                    }}
                                                                >
                                                                    <Iconify icon="bi:eye" />
                                                                </Button>
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', m: 1 }}>
                                    <Typography>
                                        Página {paginationData.page_number} de {paginationData.last_page_number}
                                    </Typography>

                                    <Button onClick={fetchPreviousPage} disabled={!previousPageUrl}>
                                        <Iconify icon="material-symbols:arrow-circle-left" />
                                    </Button>

                                    <Button onClick={fetchNextPage} disabled={!nextPageUrl}>
                                        <Iconify icon="material-symbols:arrow-circle-right" />
                                    </Button>
                                </Box>

                            </Card>
                        </Scrollbar>
                        {/* <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                pt: 2,
                            }}
                        >
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                            />
                        </Box> */}




                    </Box>
                )}

            </Container>

            {/* Modal de validación */}
            <ModalValidacion
                open={openModal} // Asegúrate de pasar el valor de openModal aquí
                handleCloseModal={handleCloseModal}
                selectedValidacion={selectedSolicitud}
                executeFetch={executeFetch}
                setExecuteFetch={setExecuteFetch}
                setOpenSnackbar={setOpenSnackbar}
                setSnackbarMessage={setSnackbarMessage}
                token={token}
                setSnackbarSeverity={setSnackbarSeverity}
                get_municipio_detail={fetchData}
                user_data={user_data}

            />

            {/* Snackbar */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}

            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            {/* Modal solicitud info */}
            {selectedSolicitud && <ModalSolicitudFinalizada
                open={openDetalleModal}
                onClose={() => setOpenDetalleModal(false)}
                solicitudData={selectedSolicitud}
            />
            }

        </>
    );
};

export default MunicipioMapDetail;
