import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import Scrollbar from '../components/scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Table, TableHead, Box, Button, Snackbar, TableBody, Grid, Alert, Stack, Chip, TableRow, TableCell, Container, Typography, IconButton, TableContainer, TablePagination, Modal, CircularProgress, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import Iconify from '../components/iconify';
import ModalValidacion from 'src/modal/ModalValidacion';
import { useMediaQuery, useTheme } from "@mui/material";
import CommonFiltersBar from 'src/common/common-filters-bar';
import DateTime from 'react-datetime';
import moment from 'moment';

export default function ValidacionesPage() {
	// const NGINX_BACKEND_PATH = NGINX_BACKEND_PATH;
	const NGINX_BACKEND_PATH = 'https://plataformadegestioncultural.gba.gob.ar/prueba'
	// const NGINX_BACKEND_PATH = 'http://localhost:8081'
	const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV;
	const token = localStorage.getItem('token');
	const user_data = JSON.parse(localStorage.getItem('user_data'));
	const [openModal, setOpenModal] = useState(false);
	const [validaciones, setValidaciones] = useState([]);
	const [isEdit, setIsEdit] = useState(false);
	const [loading, setLoading] = useState(true);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

	const dispatch = useDispatch();

	const [pageData, setPageData] = useState();
	const [nextPage, setNextPage] = useState(null);
	const [previousPage, setPreviousPage] = useState(null);
	const [selectedValidacion, setSelectedValidacion] = useState(null);

	// Alertas sucess - err
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState('');
	const [snackbarSeverity, setSnackbarSeverity] = useState('success');

	const coloresPorEstado = {
		analisis: { color: 'warning', etiqueta: 'En Análisis' },
		Aprobado: { color: 'success', etiqueta: 'Aprobado' },
		Desaprobado: { color: 'error', etiqueta: 'Desaprobado' },
		Otro: { color: 'info', etiqueta: 'Otro' },
		finalizado: { color: 'secondary', etiqueta: 'Finalizado' },
	};

	const store_municipios = useSelector((state) => state.initial_info.municipios);
	const [municipioFilter, setMunicipioFilter] = useState('todos');
	const [estadoFilter, setEstadoFilter] = useState('todos');
	const [fechaDelEvento, setFechaDelEvento] = useState(null);

	// Función para manejar clic en la fila de la tabla
	const handleOpenModalEdit = (validacion) => {
		setSelectedValidacion(validacion);
		setIsEdit(true);
		setOpenModal(true);
	};

	// Delete validacion
	const handleDeleteValidacion = (validacionId) => {
		axios
			.put(`${NGINX_BACKEND_PATH}/eliminar/validacion/`, { validacion_id: validacionId }, {
				headers: { Authorization: 'Token ' + token },
			})
			.then((response) => {
				setOpenSnackbar(true);
				setSnackbarMessage('¡La validación se ha borrado con éxito!');
				console.log('entreaca', response)
				setValidaciones(validaciones.filter((validacion) => validacion.id !== validacionId));
			})
			.catch((error) => {
				setSnackbarSeverity('error');
				setSnackbarMessage('¡Hubo un error inesperado!');
				console.log(error);
			});
	};

	const cleanFilters = () => {
		setFechaDelEvento(new Date())
		setMunicipioFilter("todos")
		setEstadoFilter("todos")
	}

	function convertirFecha(fecha) {
		const fechaDt = new Date(fecha);
		const fechaStr = fechaDt.toISOString().replace('T', ' ').replace('Z', ' GMT+0000');
		return fechaStr;
	}


	const dataToFetch = {
		municipio: municipioFilter !== 'todos' ? municipioFilter : null,
		fecha_evento: fechaDelEvento ? convertirFecha(fechaDelEvento) : null,
		estado_validacion: estadoFilter !== 'todos' ? estadoFilter : null,
	}

	//get validaciones
	function cargarValidaciones() {
		axios
			.get(`${NGINX_BACKEND_PATH}/get_validaciones/`, {
				headers: { Authorization: 'Token ' + token },
				params: dataToFetch,
			})
			.then((response) => {
				setValidaciones(response.data.results);
				const urlNext = response.data.page_data.next;
				const urlPrevious = response.data.page_data.previous;
				const nextRelativeUrl = urlNext ? "/prueba" + urlNext.substring(urlNext.indexOf('/get_validaciones')) : null;
				const previousRelativeUrl = urlPrevious ? "/prueba" + urlPrevious.substring(urlPrevious.indexOf('/get_validaciones')) : null;
				setNextPage(nextRelativeUrl);
				setPreviousPage(previousRelativeUrl);

				setPageData(response.data.page_data);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	}

	useEffect(() => {
		cargarValidaciones();
	}, []);

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const handleNextPage = (type_of_request, url) => {
		axios
			.get(url, {
				headers: { Authorization: 'Token ' + token },
			})
			.then((response) => {
				const urlNext = response.data.page_data.next;
				const urlPrevious = response.data.page_data.previous;
				const nextRelativeUrl = urlNext ? "/prueba" + urlNext.substring(urlNext.indexOf('/get_validaciones')) : null;
				const previousRelativeUrl = urlPrevious ? "/prueba" + urlPrevious.substring(urlPrevious.indexOf('/get_validaciones')) : null;
				setNextPage(nextRelativeUrl);
				setPreviousPage(previousRelativeUrl);
				setValidaciones(response.data.results);
				setPageData(response.data.page_data);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	const filtrosToRender = () => {
		return (
			<>
				<Grid container sx={{ display: 'flex', justifyContent: 'left' }}>

					<FormControl variant="outlined" sx={{ m: 1, width: 220 }}>
						<InputLabel id="filtro-label">Filtrar por Municipio</InputLabel>
						<Select
							labelId="filtro-label"
							value={municipioFilter}
							onChange={(event) => setMunicipioFilter(event.target.value)}
							label="Filtrar por Municipio"
							startAdornment={
								<Iconify
									style={{ width: '35px', height: '35px', color: '#2065D1' }}
									icon="material-symbols:filter-list" />
							}
						>
							<MenuItem value="todos">Todos</MenuItem>
							{store_municipios &&
								store_municipios
									.slice()
									.sort((a, b) => a.nombre.localeCompare(b.nombre))
									.map((municipio) => {
										return <MenuItem key={municipio.id} value={municipio.nombre}>{municipio.nombre}</MenuItem>
									})}

						</Select>
					</FormControl>

					<FormControl variant="outlined" sx={{ m: 1, width: 220 }}>
						<InputLabel id="filtro-label">Filtrar por estado</InputLabel>
						<Select
							labelId="filtro-label"
							value={estadoFilter}
							onChange={(event) => setEstadoFilter(event.target.value)}
							label="Filtrar por estado"
							startAdornment={
								<Iconify
									style={{ width: '35px', height: '35px', color: '#2065D1' }}
									icon="material-symbols:filter-list" />
							}
						>
							<MenuItem value="todos">Todos</MenuItem>
							<MenuItem value="Analisis">En Analisis</MenuItem>
							<MenuItem value="Aprobado">Aprobado</MenuItem>
							<MenuItem value="Desaprobado">Desaprobado</MenuItem>
							<MenuItem value="Finalizado">Finalizado</MenuItem>
							<MenuItem value="Otro">Otros</MenuItem>

						</Select>
					</FormControl>


					<FormControl sx={{ m: 1 }}>
						<InputLabel shrink={true} htmlFor="fecha-desde-label" sx={{ marginTop: '-5px', marginLeft: '-6px' }}>
							Fecha del evento
						</InputLabel>
						<DateTime
							utc={true}
							className="datetimeTable"
							timeFormat={false}
							// defaultValue={fechaDesde}
							value={fechaDelEvento}
							onChange={(fechaEvento) => { setFechaDelEvento(fechaEvento) }}
							dateFormat="DD/MM/YYYY"
							timeZone="America/Argentina/Buenos_Aires"
							inputProps={{ placeholder: 'Fecha del evento', readOnly: true, width: 220 }}
						/>
					</FormControl>


					<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: isMobile ? 'left' : 'center', flexDirection: isMobile ? 'column' : 'row', ml: 1 }}>
						<Button variant="outlined" color="primary" sx={{ width: '170px', height: 56, ml: 1, m: isMobile ? 1 : 0 }} onClick={() => cargarValidaciones()}>
							<Iconify
								style={{ width: '23px', height: '23px', color: '#2065D1' }}
								icon="mi:filter" />

							Aplicar filtros
						</Button>

						<Button variant="outlined" color="primary" sx={{ width: '170px', height: 56, ml: 1 }} onClick={() => { cleanFilters() }}>
							<Iconify
								style={{ width: '23px', height: '23px', color: '#2065D1' }}
								icon="mdi:remove-outline" />

							Limpiar filtros
						</Button>
					</Box>
				</Grid>
			</>
		);
	}

	return (
		<>
			<Helmet>
				<title>Validaciones | Lista</title>
			</Helmet>

			<Container maxWidth="xl" className="MuiContainer-root MuiContainer-fullWidth">
				<Stack
					direction={isMobile ? 'column' : 'row'}
					alignItems={isMobile ? 'flex-start' : 'center'}
					justifyContent={isMobile ? 'flex-start' : 'space-between'} en dispositivos móviles
					mt={isMobile ? 4 : 0}
					mb={5}
				>
					<Typography variant="h4">Validaciones</Typography>
				</Stack>



				<CommonFiltersBar
					filtersToRender={filtrosToRender}
					dataToFetch={''}
					urlToFetch={'props.urlToFetch'}
					executeCallback={cargarValidaciones}
					cleanFilters={() => { }}
				/>

				{/* Tabla de validaciones */}
				{(user_data.group === 'validator' || user_data.group === 'administrator' || user_data.group === 'extendedvalidator') ? (
					<Card sx={{ p: 1 }}>
						{loading ? (
							<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 2 }}>
								<CircularProgress />
							</Box>
						) : validaciones && validaciones.length > 0 ? (
							<Scrollbar>
								<TableContainer>
									<Table>
										<TableHead>
											<TableRow sx={{ height: '83px' }}>
												<TableCell> Municipio</TableCell>
												<TableCell> Actividad</TableCell>
												<TableCell> Area responsable</TableCell>
												<TableCell> Fecha evento</TableCell>
												<TableCell>Responsable Producción</TableCell>
												<TableCell>Área Acción</TableCell>
												<TableCell>Justificación</TableCell>
												<TableCell>Estado Validación</TableCell>
												<TableCell align="center">Acciones</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{validaciones.map((validacion) => (
												<TableRow
													onClick={() => handleOpenModalEdit(validacion)}
													key={validacion.solicitud.id}
													sx={{ '&:hover': { backgroundColor: 'rgba(99, 115, 129, 0.08)' } }}
												>
													<TableCell>{validacion.solicitud.municipio_nombre}</TableCell>
													<TableCell>{validacion.solicitud.actividad}</TableCell>
													<TableCell>{validacion.solicitud.area_responsable}</TableCell>
													<TableCell>
														{moment.utc(validacion.solicitud.fecha_evento).format('DD-MM-YYYY')}
													</TableCell>

													<TableCell>{validacion.responsable_produccion}</TableCell>
													<TableCell>{validacion.area_accion}</TableCell>
													<TableCell>{validacion.justificacion}</TableCell>
													<TableCell>
														{validacion.estado_validacion in coloresPorEstado ? (
															<Chip
																label={coloresPorEstado[validacion.estado_validacion].etiqueta}
																color={coloresPorEstado[validacion.estado_validacion].color}
																sx={{ color: 'white' }}
															/>
														) : (
															validacion.estado_validacion
														)}
													</TableCell>
													<TableCell sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
														<Button variant="outlined" color="primary" title="Editar">
															<Iconify icon="bi:pencil" />
														</Button>
														{/* {user_data.group === 'administrator' && (
															<Button
																variant="outlined"
																color="error"
																onClick={(e) => {
																	e.stopPropagation();
																	handleDeleteValidacion(validacion.id);
																}}
																sx={{ marginLeft: 1 }}
															>
																<Iconify icon="bi:trash" />
															</Button>
														)} */}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
									<Grid container spacing={2} sx={{ display: 'flex', padding: 2, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
										<Grid item>
											<Typography variant="b">
												Página {pageData.current_page_number} de {pageData.total_pages}
											</Typography>
											{previousPage && (
												<Button onClick={() => handleNextPage('previous', previousPage)}>
													<Iconify icon="material-symbols:arrow-circle-left" />
												</Button>
											)}
											{nextPage && (
												<Button onClick={() => handleNextPage('next', nextPage)}>
													<Iconify icon="material-symbols:arrow-circle-right" />
												</Button>
											)}
										</Grid>
									</Grid>
								</TableContainer>
							</Scrollbar>
						) : (
							<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 2 }}>
								{validaciones && validaciones.length === 0 ? (
									<div>No hay validaciones</div>
								) : (
									<div>Cargando...</div>
								)}
							</Box>
						)}
					</Card>
				) : (
					<div>No tienes permisos de validador</div>
				)}

				<ModalValidacion
					open={openModal}
					handleCloseModal={handleCloseModal}
					user_data={user_data}
					token={token}
					setValidaciones={setValidaciones}
					selectedValidacion={selectedValidacion}
					isEdit={isEdit}
					setOpenSnackbar={setOpenSnackbar}
					setSnackbarMessage={setSnackbarMessage}
					snackbarMessage={snackbarMessage}
					openSnackbar={openSnackbar}
					setSnackbarSeverity={setSnackbarSeverity}
					cargarValidaciones={cargarValidaciones}
				/>

				<Snackbar
					onMouseEnter={() => setOpenSnackbar(false)}
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					autoHideDuration={6000}
					open={openSnackbar}
					onClose={() => setOpenSnackbar(false)}
					message={snackbarMessage}
				>
					<Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
				</Snackbar>
			</Container>
		</>
	);
}
