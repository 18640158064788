import React, { useState } from "react";
import {
  Grid,
  Modal,
  TextField,
  Button,
  Box,
  Typography,
} from "@mui/material";

export default function CreateRegionModal({
  open,
  onClose,
  isEdit,
  selectedRegion,
  title,
  handleEditRegion,
  handleCreateRegion,
}) {
  const [nombre, setNombre] = useState("");

  function onSubmit() {
    setNombre("");
    if (isEdit) {
      handleEditRegion(selectedRegion, nombre);
      onClose();
    } else {
      handleCreateRegion(nombre);
      onClose();
    }
  }

  return (         
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#f4f6f8",
            padding: "40px",
            borderRadius: "5px",
            width: "90%",
            maxWidth: "600px",
          }}
        >
          <Typography variant="h4" sx={{ marginBottom: 4, textAlign: "left" }}>
            {title}
          </Typography>

          <Grid
            container
            justifyContent="center"
            direction="column"
            spacing={2}
          >
            <Grid item>
              <TextField
                label="Nombre"
                defaultValue={isEdit ? selectedRegion.nombre : ""}
                onChange={(event) => setNombre(event.target.value)}
                fullWidth
              />
            </Grid>

            <Grid container justifyContent="flex-end" spacing={2} sx={{ marginTop: 4 }}>
              <Grid item>
                <Button onClick={onClose} variant="outlined" fullWidth>
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={onSubmit} type="submit" variant="contained" fullWidth>
                  {isEdit ? "Editar" : "Crear"}
                </Button>
              </Grid>
            </Grid>
          </Grid>

        </Box>
      </Box>
    </Modal>
  );
}
