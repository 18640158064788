import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
import account from '../../../_mock/account';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { userActions } from "../../../redux/userSlice";
import Iconify from '../../../components/iconify';

import EditProfileUserModal from 'src/modal/EditProfileUserModal';
import { useSelector } from "react-redux";
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV
  const user_data = JSON.parse(localStorage.getItem('user_data'))
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const dispatch = useDispatch()
  const logedUser = useSelector((state) => state.user);

  const [openEditProfileModal, setOpenEditProfileModal] = useState(false);

  const handleLogout = () => {
    axios.post(
      SERVER_URL + '/logout/',
      null,
      { headers: { 'Authorization': 'Token ' + token } }
    )
      .then(response => {
        if (response.status === 200) {
          localStorage.removeItem('token');
          localStorage.removeItem('user_data');
          dispatch(userActions.changeIsAuthenticated({
            is_authenticated: false
          }));
          navigate('/login');
        }
      })
      .catch(error => console.log(error));
  }

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user_data && user_data.username ? user_data.username : 'Sin usuario'}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {user_data && user_data.group ? (
            user_data.group === 'administrator' ? 'Rol de administración' :
              user_data.group === 'validator' ? 'Rol de validación' :
                user_data.group === 'uploader' ? 'Rol de carga' :
                  user_data.group === 'extendedvalidator' ? 'Validación y carga' :
                    user_data.group === 'consultant' ? 'Rol de consultor' :
                    'Sin rol'
          ) : 'Sin rol'}
          </Typography>
        </Box>

        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        <Divider />

        <MenuItem onClick={() => {setOpenEditProfileModal(true)}} sx={{
          m: 1,
          '&:hover': {
            backgroundColor: 'rgb(255 94 86)', // un rojizo suave con opacidad
            color: '#fff', // texto blanco al hacer hover
            '& svg': {
              color: '#fff', // cambia el color del icono a blanco al hacer hover
            },
          },
        }}>
          <Iconify icon="eva:person-outline" color="inherit" sx={{ mr: 0.5 }} />

          Editar Perfil
        </MenuItem>

        <MenuItem onClick={handleLogout} sx={{
          m: 1,
          '&:hover': {
            backgroundColor: 'rgb(255 94 86)', // un rojizo suave con opacidad
            color: '#fff', // texto blanco al hacer hover
            '& svg': {
              color: '#fff', // cambia el color del icono a blanco al hacer hover
            },
          },
        }}>
          <Iconify icon="eva:log-out-fill" color="inherit" sx={{ mr: 0.5 }} />
          Cerrar sesión
        </MenuItem>

        <EditProfileUserModal 
          openEditProfileModal={openEditProfileModal}
          setOpenEditProfileModal={setOpenEditProfileModal}
          logedUser={logedUser}
          title={'Editar perfil'}
        />


      </Popover>
    </>
  );
}
