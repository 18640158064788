import React, { useState } from "react";
import { Grid, Modal, TextField, Button, Box, Typography } from "@mui/material";

export default function CreateSeccionElectoralModal({
  open,
  onClose,
  isEdit,
  selectedSeccionElectoral,
  title,
  handleCreateSeccionElectoral,
  handleEditSeccionElectoral,
}) {
  const [nombre, setNombre] = useState("");

  function onSubmit() {
    setNombre("");
    if (isEdit) {
      handleEditSeccionElectoral(selectedSeccionElectoral, selectedSeccionElectoral.nombre);
      onClose();
    } else {
      handleCreateSeccionElectoral(nombre);
      onClose();
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#f4f6f8",
            padding: "40px",
            borderRadius: "5px",
            width: "90%",
            maxWidth: "600px",
            boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="h4" sx={{ marginBottom: 2, textAlign: "left", color: "#333333" }}>
            {title}
          </Typography>

          <Grid
            container
            direction="column"
            alignItems="center"
            spacing={2}
            sx={{ marginTop: 5 }}
          >
            <Grid item sx={{ width: "100%" }}>
              <TextField
                label="Nombre"
                defaultValue={isEdit ? selectedSeccionElectoral.nombre : ""}
                onChange={(event) => setNombre(event.target.value)}
                fullWidth
              />
            </Grid>
             <Grid container justifyContent="flex-end" spacing={2} sx={{ marginTop: 4 }}>
              <Grid item>
                <Button onClick={onClose} variant="outlined" fullWidth>
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={onSubmit} type="submit" variant="contained" fullWidth>
                  {isEdit ? "Editar" : "Crear"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}
