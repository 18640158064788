// @mui
import axios from 'axios';
import { Card, Table, InputLabel, Stack, Alert, FormControl, Chip, Snackbar, Select, TableHead, Paper, Avatar, Button, Popover, Box, Checkbox, Grid, TableRow, TextField, MenuItem, TableBody, TableCell, Container, Typography, IconButton, TableContainer, TablePagination, Modal, } from '@mui/material';
import { CircularProgress } from '@mui/material';

import Iconify from '../components/iconify';
import { useState } from 'react';
import { useMediaQuery, useTheme } from "@mui/material";

export default function CommonReports(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLargeReportLoading, setIsLargeReportLoading] = useState(false);
  const [generatingText, setGeneratingText] = useState('Generar reporte');
  const [generatingTextLarger, setGeneratingTextLarger] = useState('Generar reporte');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { municipio, area_responsable, fecha_desde, fecha_hasta, estado_solicitud, seccion_electoral } = props.dataToFetch;

  const token = localStorage.getItem('token');

  const handleDownloadButtonCharge = (large) => {
    if (large === 'large') {
      return ''
    }
  }

  const handleDownloadExcel = (large) => {

    const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV;

    let solicitudInicial = props.firstSolicitud;
    let solicitudFinal = props.lastSolicitud;
    if (!large) {
      setGeneratingText('Generando reporte...'); // Actualizar el texto de generación
      setIsLoading(true);
    }

    if (large === 'large') {
      // Si el parámetro es 'large', ajustar la solicitud final y la solicitud inicial
      setGeneratingTextLarger('Generando reporte...'); // Actualizar el texto de generación
      setIsLargeReportLoading(true)
      solicitudFinal = parseInt(props.lastSolicitud);
      solicitudInicial = Math.max(0, solicitudFinal - 950).toString();
    }

    axios.get(SERVER_URL + '/generar_excel_solicitudes/', {
        params: {
          solicitud_inicial: solicitudInicial,
          solicitud_final: solicitudFinal,
          municipio,
          area_responsable,
          fecha_desde,
          fecha_hasta,
          estado_solicitud,
          seccion_electoral
        },
        headers: {
          Authorization: 'Token ' + token,
        },
        responseType: 'blob',
      })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Listado de solicitudes.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch(function (error) {
        // Manejar el error aquí
      })
      .finally(function () {
        setIsLoading(false);
        setIsLargeReportLoading(false);
        setGeneratingText('Generar reporte'); // Restaurar el texto original
      });
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'left' : 'center' }}>
        <Button
          variant="outlined"
          color="primary"
          sx={{ width: '200px', height: 56, m: 1 }}
          onClick={() => { handleDownloadExcel() }}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <CircularProgress size={24} sx={{ mr: 1 }} />
              {' ' + generatingText} {/* Mostrar el texto actualizado */}
            </>
          ) : (
            <>
              <Iconify
                style={{ width: '23px', height: '23px', color: '#2065D1' }}
                icon="carbon:report"
              />
              {generatingText} {/* Mostrar el texto actualizado */}
            </>
          )}
        </Button>


        <Button
          variant="outlined"
          color="primary"
          sx={{ width: '300px', height: 56, m: 1 }}
          onClick={() => { handleDownloadExcel('large') }} // Pass true to indicate the large report
          disabled={isLargeReportLoading} // Disable the button when loading
        >
          {isLargeReportLoading ? (
            <>
              <CircularProgress size={24} sx={{ mr: 1 }} />
              {generatingTextLarger} {/* Show the generating text */}
            </>
          ) : (
            <>
              <Iconify
                style={{ width: '23px', height: '23px', color: '#2065D1' }}
                icon="tabler:report"
              />
              Generar reporte (hasta 1000 datos)
            </>
          )}
        </Button>



      </Box>
    </>
  );
}
