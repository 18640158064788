import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText, IconButton, Drawer, useMediaQuery, useTheme } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import Iconify from '../iconify/Iconify';
// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const handleToggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <Box {...other}>
      {isMobile ? (
        <IconButton onClick={handleToggleMenu} sx={{ ml: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      ) : null}



      <Drawer anchor="left" open={isMobile && isMenuOpen} onClose={handleToggleMenu}>
        <List disablePadding sx={{ p: 1 }}>
          {data.map((item) => (
            <NavItem key={item.name} item={item} onClick={handleToggleMenu} />
          ))}
        </List>
      </Drawer>

      {!isMobile && (
        <List disablePadding sx={{ p: 1, display: 'flex' }}>
          {data.map((item) => (
            <NavItem key={item.name} item={item} />
          ))}
        </List>
      )}
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
};

function NavItem({ item, onClick }) {
  const { name, url, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={url}
      onClick={onClick}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
        p: 2,
        display: 'flex',
        alignItems: 'center', // Agregamos esta línea para alinear verticalmente los elementos
      }}
    >
      {/* <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon> */}

      <ListItemText disableTypography primary={name} />

      {info && info}
    </StyledNavItem>
  );
}
