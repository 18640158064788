import { styled } from '@mui/material/styles';
import Iconify from '../components/iconify';
import { TextField, Button, Box, Snackbar, Alert } from '@mui/material';
import { useRef, useState } from 'react';
import axios from 'axios';

// ----------------------------_OlO_------------------------------------------//

const textFieldSearchBarStyles = {
  width: '370px',
  m: 0,
  ml: 0,
};

const containerStyles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: 1,
};

// ----------------------------------------------------------------------

export default function CommonSearchBar(props) {
  const token = localStorage.getItem('token');
  const commonSearchBarReference = useRef('');
  const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSearchAction = () => {
    const searchText = commonSearchBarReference.current.value;

    if (searchText.trim() === '') {
      // El campo de búsqueda está vacío, mostrar Snackbar de error
      setSnackbarMessage('Ingrese un término de búsqueda');
      setSnackbarOpen(true);
      return;
    }

    axios
      .get(SERVER_URL + '/' + props.urlToFetch, {
        params: {
          municipio_o_descripcion: searchText,
        },
        headers: {
          Authorization: 'Token ' + token,
        },
      })
      .then(function (response) {
        props.executeCallback(response);
      })
      .catch(function (error) {
        // Manejar el error aquí y mostrar Snackbar de error
        setSnackbarMessage('Error en la búsqueda');
        setSnackbarOpen(true);
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Box sx={containerStyles}>
        <TextField
          sx={textFieldSearchBarStyles}
          type="text"
          placeholder="Descripción, municipio o actividad"
          inputRef={commonSearchBarReference}
          InputProps={{
            style: {
              borderRight: 'none',
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },
          }}
        />

        <Button
          variant="outlined"
          color="primary"
          sx={{
            height: '56px',
            borderLeft: 'none',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            mb:0.1
          }}
          onClick={handleSearchAction}
        >
          <Iconify
            style={{ width: '30px', height: '30px', color: '#2065D1' }}
            icon="ic:baseline-manage-search"
          />
        </Button>
      </Box>

     
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onMouseEnter={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
