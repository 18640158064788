import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, TextField, Box, Typography, Grid, Snackbar, Alert } from '@mui/material';
import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import moment from 'moment';

const ModalSolicitudFinalizada = ({ open, onClose, solicitudData }) => {


    const handleCloseModal = () => {
        onClose();
    };


    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            handleCloseModal();
            onClose();
        }
    };

    return (
        <>
            <Modal onKeyDown={handleKeyDown} open={open} onClose={handleCloseModal}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "84%", height: "100%", margin: "auto" }}>
                    <Box sx={{ backgroundColor: "#f4f6f8", padding: "40px", borderRadius: "5px" }}>
                        <Typography sx={{ m: 1 }} align="center" variant="h3">Información de la solicitud</Typography>
                        <Typography><strong>Actividad:</strong> {solicitudData.actividad}</Typography>
                        <Typography><strong>Área responsable:</strong> {solicitudData.area_responsable}</Typography>
                        <Typography><strong>Artística sugerida:</strong> {solicitudData.artistica_sugerida}</Typography>
                        <Typography><strong>Cargo responsable local:</strong> {solicitudData.cargo_responsable_local}</Typography>
                        <Typography><strong>Contacto responsable local:</strong> {solicitudData.contacto_responsable_local}</Typography>
                        <Typography><strong>Descripción:</strong> {solicitudData.descripcion}</Typography>
                        <Typography><strong>Estado de la solicitud:</strong> {solicitudData.estado_solicitud}</Typography>
                        <Typography>
                            <strong>Fecha del evento:</strong> {moment(solicitudData.fecha_evento).format('DD MMMM YYYY')}
                        </Typography>
                        <Typography>
                            <strong>Fecha de solicitud recibida:</strong> {moment(solicitudData.fecha_solicitud_recibida).format('DD MMMM YYYY')}
                        </Typography>

                        <Typography><strong>Gestión de la solicitud:</strong> {solicitudData.gestion_solicitud}</Typography>
                        <Typography><strong>Horario estimado:</strong> {solicitudData.horario_estimado}</Typography>
                        <Typography><strong>Lugar:</strong> {solicitudData.lugar}</Typography>
                        <Typography><strong>Modalidad:</strong> {solicitudData.modalidad}</Typography>
                        <Typography><strong>Nombre del municipio:</strong> {solicitudData.municipio_nombre}</Typography>
                        <Typography><strong>Nombre responsable local:</strong> {solicitudData.nombre_responsable_local}</Typography>
                        <Typography><strong>Área de la solicitud:</strong> {solicitudData.solicitud_area}</Typography>
                        <Typography>
                            <strong>URL de la nota PDF:</strong>{" "}
                            {solicitudData.url_nota_pdf ? solicitudData.url_nota_pdf : "-"}
                        </Typography>                        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="contained" onClick={handleCloseModal}>
                                Cerrar
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>



        </>
    );
};

export default ModalSolicitudFinalizada;