import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import { useDispatch } from 'react-redux';

const initialState = {
  propuestas: [],
  cambio_solicitudes: false,
};

export const propuestasSlice = createSlice({
  name: "propuestas",
  initialState: initialState,
  reducers: {
    addPropuestas: (state, action) => {
      state.propuestas = action.payload.propuestas;
    },
    agregarPropuesta: (state, action) => {
      if (!state.propuestas) state.propuestas = [];
      state.propuestas.push(action.payload.propuesta);
    },

    changeCambioSolicitudes: (state, action) => {
      state.cambio_solicitudes =  action;
    }
  },
});

export const useActualizarPropuestas = () => {
  const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV
  const dispatch = useDispatch();

  const actualizarPropuestas = (token) => {
    axios.get(SERVER_URL + "/get_solicitudes/",
      { headers: { 'Authorization': 'Token ' + token } }
    )
      .then((response) => {
        console.log(response.data.results);
        dispatch(propuestasSlice.actions.addPropuestas({ propuestas: response.data.results }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return actualizarPropuestas;
};

export const propuestasActions = propuestasSlice.actions;
export default propuestasSlice.reducer;
