import React, { useEffect, useState } from 'react';
import { Modal, TextField, Typography, Button, Box, Select, InputLabel, MenuItem, FormControl, Alert, Snackbar } from '@mui/material';
import axios from 'axios'
import "./modal.css"

const CreateMunicipioModal = ({ open, onClose, initialInfo, getMunicipios }) => {

  const token = localStorage.getItem('token')
  const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV
  const user_data = JSON.parse(localStorage.getItem('user_data'))

  const [nombre, setNombre] = useState('');
  const [seccionElectoral, setSeccionElectoral] = useState('');
  const [gobierno, setGobierno] = useState('');
  const [region, setRegion] = useState('');
  const [intendente, setIntendente] = useState('');


  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };


  const handleClose = () => {
    setNombre('')
    setSeccionElectoral({})
    setRegion({})
    setGobierno('')
    setIntendente('')
    onClose();
  };

  const handleAddMunicipio = () => {

    if (!nombre || !seccionElectoral || !gobierno || !region) {
      showSnackbar('Por favor, complete todos los campos', 'error');
      return;
    }

    const data = {
      nombre: nombre,
      seccionElectoral: seccionElectoral.id,
      gobierno: gobierno.id,
      region: region.id,
      intendente: intendente
    };

    axios.post(SERVER_URL + '/crear/municipio/', data, {
      headers: {
        Authorization: 'Token ' + token,
      },
    })
      .then((response) => {
        console.log(response);
		showSnackbar('¡Se creó el municipio con éxito!', 'success');
        getMunicipios();
      })
      .catch((error) => {
        console.log(error);
      });

    handleClose();
  };




  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) { // 27 es el código de la tecla "Esc"
        handleClose();
      }
    };

    if (open) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [open]);


  return (
    <>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        onMouseEnter={() => setSnackbarOpen(false)}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
      </Snackbar>

      <Modal open={open} onClose={handleClose}>
        <Box  sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "column", backgroundColor: "#f4f6f8", padding: "40px", borderRadius: "5px", width: "80%", gap: 2 }}>

            <Typography variant="h4" sx={{ mb: 2 }}>
              Nuevo municipio
            </Typography>

            <FormControl>
              <TextField
                label="Nombre de municipio"
                id="nombre"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
              />
            </FormControl>

            <FormControl>
              <TextField
                label="Intendente"
                id="intendente"
                value={intendente}
                onChange={(e) => setIntendente(e.target.value)}
              />
            </FormControl>

            <FormControl>
              <InputLabel htmlFor="seccionElectoral">Sección Electoral</InputLabel>
              <Select
                id="seccionElectoral"
                label="Seccion electoral"
                value={seccionElectoral}
                onChange={(e) => setSeccionElectoral(e.target.value)}
              >
                {initialInfo && initialInfo.secciones_electorales.map((seccion) => (
                  <MenuItem key={seccion.id} value={seccion}>
                    {seccion.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

			<FormControl>
          <InputLabel id="gobierno">Gobierno</InputLabel>
            <Select
              id="gobierno"
              label="gobierno"
              value={gobierno}
              onChange={(e) => setGobierno(e.target.value)}
            >
              {initialInfo && initialInfo.gobiernos.map((gobierno) => (
                <MenuItem key={gobierno.id} value={gobierno}>
                  {gobierno.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>


            <FormControl>
              <InputLabel id="region" htmlFor="region">Región</InputLabel>
              <Select
                id="region"
                label='region'
                value={region}
                onChange={(e) => setRegion(e.target.value)}
              >
                {initialInfo && initialInfo.regiones.map((region) => (
                  <MenuItem key={region.id} value={region}>
                    {region.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button sx={{m:1}} onClick={onClose} variant="outlined">
                  Cancelar
              </Button>
              <Button sx={{m:1}} variant="contained" onClick={handleAddMunicipio}>
                Guardar
              </Button>
            </Box>
          </Box>
        </Box>


      </Modal>
    </>

  );
};

export default CreateMunicipioModal;
