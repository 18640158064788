import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
import { CircularProgress } from "@mui/material";
import { Button, Typography, Snackbar, Alert } from "@mui/material";
import { Container,Stack,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Card,Box } from "@mui/material";
import CreateRegionModal from "src/modal/CreateRegionModal.js";
import BorrarRegionModal from "src/modal/child/BorrarRegionModal.js";
import { useMediaQuery, useTheme } from "@mui/material";

export default function RegionPage() {
  const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  if (!user_data) window.location.replace("/login");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const token = localStorage.getItem("token");
  const [modalOpen, setOpenModal] = useState(false);
  const [modalDelete, setOpenModalDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [Regiones, setRegiones] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  // Alertas sucess - err
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");

  // Función para manejar clic en la fila de la tabla
  const handleOpenModalEdit = (region) => {
    setOpenModal(true);
    setIsEdit(true);
    setSelectedRegion(region);
  };


     //BORRAR REGION
     const handleDeleteRegion = (id) => {
      console.log("Id que llego " + id)
      axios
        .put(
          SERVER_URL+"/eliminar/region/",
          {
            id,
          },
          { headers: { Authorization: "Token " + token } }
        )
        .then((response) => {
          console.log(response.data)
          setOpenSnackbar(true);
          setRegiones(Regiones.filter((regionDB) => regionDB.id !== id));
          setSnackbarSeverity("success");
          setSnackbarMessage("La region ha sido eliminada con éxito");
        })
        .catch((error) => {
          setSnackbarSeverity("error");
          setSnackbarMessage("Hubo un error al eliminar la región.");
          console.log(error.response.data.error);
        });
    };

    const get_regiones = () => {
      axios
        .get(SERVER_URL+"/get_regiones/", {
          headers: {
            Authorization: "Token " + token,
          },
        })
        .then((response) => {
          setRegiones(response.data.regiones);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error.response.data.error);
          setLoading(false);
        });
    };
  
  //CREAR REGION
  const handleCreateRegion = (nombre) => {
    axios
      .post(
        SERVER_URL+"/crear/region/",
        {
          nombre_region: nombre,
        },
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setOpenSnackbar(true);
          setSnackbarSeverity("success");
          setSnackbarMessage(response.data.msg);
          get_regiones();
          console.log("Recurso creado exitosamente: ", response.status);
        } else {
          console.log("La respuesta no fue exitosa: ", response.status);
        }
      })
      .catch((error) => {
        setOpenSnackbar(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(error.response.data.error);
        console.log(error.error);
      });
  };

  //EDITAR REGION
  const handleEditRegion = (region, nombre) => {
    axios
      .put(
        SERVER_URL+"/actualizar/region/",
        {
          id: region.id,
          nombre_region: nombre,
        },
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response) => {
        setOpenSnackbar(true);
        setSnackbarSeverity("success");
        setSnackbarMessage(response.data.msg);
        get_regiones();
        console.log("Recurso actualizado exitosamente:", response.status);
      })
      .catch((error) => {
        setOpenSnackbar(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(error.response.data.error);
        console.log(error.response.data.error);
      });
  };




  useEffect(() => {
    get_regiones();
  }, []);

  return (
    <>
      <Helmet>
        <title> Regiones | Lista </title>
      </Helmet>

      <Container
        maxWidth="xl"
        className="MuiContainer-root MuiContainer-fullWidth"
      >
         <Stack
					direction={isMobile ? 'column' : 'row'} 
					alignItems={isMobile ? 'flex-start' : 'center'} 	
      		justifyContent={isMobile ? 'flex-start' : 'space-between'} en dispositivos móviles
          mt={isMobile ? 4 : 0} 

					mb={5}
				>  
          <Typography variant="h4" gutterBottom>
            Regiones
          </Typography>

          {user_data.group === "administrator" && (
            <Button
              variant="contained"
              onClick={() => {
                setOpenModal(true);
                setIsEdit(false);
              }}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Nueva región
            </Button>
          )}

          <CreateRegionModal
            open={modalOpen}
            onClose={() => setOpenModal(false)}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            selectedRegion={selectedRegion}
            title={isEdit ? "Editar región" : "Crear región"}
            handleEditRegion={handleEditRegion}
            handleCreateRegion={handleCreateRegion}
          />
        </Stack>

        <Card
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {loading ? (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 2, height: 540 }}>
          <CircularProgress />
        </Box>
      ) : Regiones && Regiones.length > 0 ? (
        <Scrollbar sx={{ p: 1 }}>
          <TableContainer sx={{ overflow: "auto" }}>
            <Table>
              <TableHead>
                <TableRow sx={{ height: "83px" }}>
                  <TableCell>Nombre</TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "83px",
                    }}
                  >
                    Acciones
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Regiones.map((region) => (
                  <React.Fragment key={region.id}>
                    <TableRow
                      sx={{
                        "&:hover": {
                          backgroundColor: "rgba(99, 115, 129, 0.08)",
                        },
                        height: "35px",
                      }}
                    >
                      <TableCell
                        onClick={() =>  handleOpenModalEdit(region)}
                        label
                      >
                        {region.nombre}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <Button
                          sx={{ m: 1 }}
                          variant="outlined"
                          color="error"
                          onClick={(e) => {
                            setSelectedRegion(region);
                            setOpenModalDelete(true);
                          }}
                        >
                          <Iconify icon="bi:trash" />
                        </Button>
                        <Button sx={{ m: 1 }}variant="outlined" color="primary" onClick={() => handleOpenModalEdit(region)}>
                              <Iconify icon="bi:pencil" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 2, height: 540 }}>
          <Typography variant="subtitle1">No hay regiones</Typography>
        </Box>
      )}
    </Card>

        <Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={() => setOpenSnackbar(false)}
          message={snackbarMessage}
        >
          <Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
        </Snackbar>
        <BorrarRegionModal 
            open={modalDelete}
            onClose={() => setOpenModalDelete(false)}
            handleDeleteRegion={handleDeleteRegion}
            selectedRegion = {selectedRegion}
            />
      </Container>
    </>
  );
}
