import React, { useState } from "react";
import { Grid, Modal, Button, Box, Typography } from "@mui/material";

export default function BorrarRegionModal({
  open,
  onClose,
  handleDeleteRegion,
  selectedRegion,
}) {

  function onDelete() {    
    handleDeleteRegion(selectedRegion.id);
    onClose();
  }

  return (

<Modal open={open} onClose={onClose}>
<Box
  sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "84%",
    height: "100%",
    margin: "auto",
  }}
>
  <Box
    sx={{
      backgroundColor: "#f4f6f8",
      padding: "40px",
      borderRadius: "5px",
    }}
  >
        <Typography variant="h4" sx={{ marginBottom: 2, textAlign: "center", color: "#333333" }}>
        ¿Estás seguro de que quieres borrar la region { selectedRegion && selectedRegion.nombre} ?
          

          </Typography>
    <Box sx={{display:'flex', justifyContent:"center", alignItems:"center", mt:2}}>

    <Button sx={{m:1}} onClick={onClose}>Cancelar</Button>
    <Button variant="outlined" color="error" sx={{m:1}} onClick={onDelete}>Borrar</Button>
    </Box>
  </Box>
</Box>
</Modal>
  );
}
