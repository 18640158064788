import { configureStore  } from "@reduxjs/toolkit";
import userReducer from './userSlice';
import propuestasReducer from './propuestaSlice';
import InitialInfoReducer from './Initial_info_slice';


export const store = configureStore({
    reducer: {
        user: userReducer,
        propuestas:propuestasReducer,
        initial_info: InitialInfoReducer,
    }
})
