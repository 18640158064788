import React, { useState, useEffect } from 'react';
import { Modal, TextField, Typography, Button, Box, Select, InputLabel, MenuItem, FormControl, Alert, Snackbar } from '@mui/material';
import axios from 'axios';

const EditMunicipioModal = ({ municipio, open, onClose, initialInfo, getMunicipios }) => {
  const token = localStorage.getItem('token');
  const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV;
  const user_data = JSON.parse(localStorage.getItem('user_data'));

  const [nombre, setNombre] = useState('');
  const [seccionElectoral, setSeccionElectoral] = useState('');
  const [gobierno, setGobierno] = useState('');
  const [region, setRegion] = useState('');
  const [intendente, setIntendente] = useState('');


  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [editSuccessSnackbarOpen, setEditSuccessSnackbarOpen] = useState(false);

  const showEditSuccessSnackbar = () => {
    setEditSuccessSnackbarOpen(true);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleClose = () => {
    setNombre('');
    setSeccionElectoral('');
    setRegion('');
    setGobierno('');
    setIntendente('');
    onClose();
  };

  const handleEditMunicipio = () => {

    if (nombre === '' || seccionElectoral === '' || gobierno === '' || region === '') {
      showSnackbar('Por favor, completa todos los campos.', 'error');
      return;
    }

    const data = {
      municipioId: municipio.id,
      nombre: nombre,
      seccionElectoral: seccionElectoral.id,
      gobierno: gobierno.id,
      region: region.id,
      intendente: intendente
    };

    axios.post(SERVER_URL + '/actualizar/municipio/', data, {
      headers: {
        Authorization: 'Token ' + token,
      },
    })
      .then((response) => {
        console.log(response);
        getMunicipios();
        showSnackbar('¡Se creó el municipio con éxito!', 'success');
      })
      .catch((error) => {
        console.log(error);
      });

    handleClose();
  };


  useEffect(() => {
    if (municipio && initialInfo) {
      setNombre(municipio.nombre);
      setIntendente(municipio.intendente);
      const seccionElectoralInicial = initialInfo.secciones_electorales.find(seccion => seccion.nombre === municipio.seccion_electoral_nombre);
      console.log('secciones_electorales', initialInfo.secciones_electorales);
      console.log('seccionElectoral', municipio.seccion_electoral_nombre);
      setSeccionElectoral(seccionElectoralInicial);

      const gobiernoInicial = initialInfo.gobiernos.find(gobierno => gobierno.nombre === municipio.gobierno_nombre);
      setGobierno(gobiernoInicial);

      const regionInicial = initialInfo.regiones.find(region => region.nombre === municipio.region_nombre);
      setRegion(regionInicial);
    }
  }, [municipio, initialInfo]);


  return (
    <>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
      </Snackbar>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f4f6f8', padding: '40px', borderRadius: '5px', width: '50%', gap: 2 }}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              Editar municipio
            </Typography>

            <FormControl>
              <TextField label="Nombre de municipio" id="nombre" value={nombre} onChange={(e) => setNombre(e.target.value)} />
            </FormControl>

            <FormControl>
              <TextField label="Intendente" id="intendente" value={intendente} onChange={(e) => setIntendente(e.target.value)} />
            </FormControl>

            <FormControl>
              <InputLabel htmlFor="gobierno">Gobierno</InputLabel>
              <Select
                id="gobierno"
                label="Gobierno"
                value={gobierno}
                onChange={(e) => setGobierno(e.target.value)}
                renderValue={(value) => value.nombre} // Mostrar el nombre del valor seleccionado
              >
                {initialInfo &&
                  initialInfo.gobiernos.map((gobierno) => (
                    <MenuItem key={gobierno.id} value={gobierno}>
                      {gobierno.nombre}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel htmlFor="region">Región</InputLabel>
              <Select
                id="region"
                label="Región"
                value={region}
                onChange={(e) => setRegion(e.target.value)}
                renderValue={(value) => value.nombre} // Mostrar el nombre del valor seleccionado
              >
                {initialInfo &&
                  initialInfo.regiones.map((region) => (
                    <MenuItem key={region.id} value={region}>
                      {region.nombre}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel htmlFor="seccionElectoral">Sección Electoral</InputLabel>
              <Select
                id="seccionElectoral"
                label="Sección Electoral"
                value={seccionElectoral}
                onChange={(e) => setSeccionElectoral(e.target.value)}
                renderValue={(value) => value.nombre} // Mostrar el nombre del valor seleccionado
              >
                {initialInfo &&
                  initialInfo.secciones_electorales.map((seccion) => (
                    <MenuItem key={seccion.id} value={seccion}>
                      {seccion.nombre}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
              <Button onClick={onClose} variant="outlined" sx={{ marginRight: 2 }}>
                Cancelar
              </Button>
              <Button variant="contained" onClick={handleEditMunicipio}>
                Editar
              </Button>
            </Box>


          </Box>
        </Box>
      </Modal>
    </>

  );
};

export default EditMunicipioModal;
