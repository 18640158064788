import { useState, useRef, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Stack, IconButton, InputAdornment, TextField, Snackbar, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/iconify';
import axios from 'axios';
import { useSelector, useDispatch} from "react-redux";
import { userActions } from "../../../redux/userSlice";

export default function LoginForm() {
  const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV
  const navigate = useNavigate();
  const usernameRef = useRef();
  const passwordRef = useRef();
  
  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState(null);
  
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Agregado
  const dispatch = useDispatch();
 
  const handleClick = () => {
    setLoading(true); // Se activa el spinner al iniciar la autenticación

    axios.post(SERVER_URL + "/login/", {
      username: usernameRef.current.value,
      password: passwordRef.current.value,
    })
      .then((response) => {
        setLoading(false); // Se desactiva el spinner al recibir la respuesta

        if (response.status === 200) {

          localStorage.setItem('user_data', JSON.stringify({
            'group': response.data.msg.user_groups[0].name,
            'username': response.data.msg.username
          }))

          localStorage.setItem( 'token', response.data.msg.access);
          dispatch(userActions.addUser({
            first_name: response.data.msg.first_name,
            last_name: response.data.msg.last_name,
            email: response.data.msg.email,
            username: response.data.msg.username, 
            user_group: response.data.msg.user_groups[0].name,
            is_authenticated: true,
            id: response.data.msg.id
          }));


          dispatch(userActions.changeIsAuthenticated({ 
            is_authenticated: true
          }));
          // setUserData(response.data.msg.user);
          navigate('/', { replace: true });
        } else {
          setError(true);
          setLoading(false); // Se desactiva el spinner en caso de error
          setSnackbarOpen(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setSnackbarOpen(true);
        setLoading(false)
      });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="username"
          label="Usuario"
          error={error}
          inputRef={usernameRef}
          InputLabelProps={ error ? {borderColor:'red'} : {}}
          InputProps={
            error ? {style:{borderColor:'red'}} : {}
          }
        />
        <TextField
          name="password"
          label="Contraseña"
          type={showPassword ? 'text' : 'password'}
          error={error}
          inputRef={passwordRef}
          InputLabelProps={error ? {shrink:true, style:{color:'red'}} : {}}
          InputProps={{
            onKeyDown: (e) => {
              if (e.key === 'Enter') {
                handleClick();
              }
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Link sx={{m:1}} to="/change_password">Olvidé mi contraseña</Link>

      <LoadingButton sx={{mt:1}} fullWidth size="large" type="submit" variant="contained" onClick={handleClick} loading={loading}>
        Entrar
      </LoadingButton>


      <Snackbar onMouseEnter={() => setSnackbarOpen(false)} open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose}>
      <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
      Error al iniciar sesión. Por favor, inténtelo de nuevo
      </Alert>
    </Snackbar>
    </>
  );
}
