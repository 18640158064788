import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, TextField, Box, CircularProgress, Typography, Grid, Snackbar, Alert } from '@mui/material';
import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import axios from 'axios';

const ModalEvaluacion = ({ evaluacion, isEdit, open, onClose, selectedSolicitudToEvaluate, get_solicitudes, cargarEvaluaciones }) => {

    const [openModal, setOpenModal] = useState(false);
    const token = localStorage.getItem('token')
    const formRef = useRef(null);
    const [edit, setEdit] = useState(false);

    // Snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [successSnackbarSeverity, setSuccessSnackbarSeverity] = useState('success');
    const [errorSnackbarSeverity, setErrorSnackbarSeverity] = useState('error');
    const [openGreenSnackbar, setOpenGreenSnackbar] = useState(false);
    const [greenSnackbarMessage, setGreenSnackbarMessage] = useState('');
    const [showSpinner, setShowSpinner] = useState(false);

    // Valores por defecto para cada input
    const initialValues = {
        id_solicitud: selectedSolicitudToEvaluate?.id || '',
        artistica_definida: evaluacion?.artistica_definida || '',
        inversion_estimada: evaluacion?.inversion_estimada || '',
        cantidad_asistentes_estimada: evaluacion?.cantidad_asistentes_estimada || '',
        flyer_difusion: evaluacion?.flyer_difusion || '',
        articulacion_promotora: evaluacion?.articulacion_promotora || '',
        notas_periodisticas: evaluacion?.notas_periodisticas || '',
        fotos: evaluacion?.fotos || '',
    };

    const handleSubmitEvaluacion = (event) => {
        event.preventDefault();

        const formData = new FormData(formRef.current);

        isEdit ? formData.append('id_evaluacion', evaluacion.id) : formData.append('id_solicitud', selectedSolicitudToEvaluate.id);

        const data = {};
        formData.forEach((value, key) => {
            data[key] = value;
        });

        const SERVER_URL = process.env.REACT_APP_API_BACKEND_SV;

        const endpoint = isEdit ? '/actualizar/evaluacion/' : '/crear/evaluacion/';
        setShowSpinner(true)
        axios.post(SERVER_URL + endpoint, data, {
            headers: {
                'Authorization': 'Token ' + token,
            },
        })
            .then((response) => {
                setShowSpinner(false)

                setSuccessSnackbarSeverity('success'); // Se asigna 'success' para casos exitosos
                setOpenGreenSnackbar(true);

                setGreenSnackbarMessage(isEdit ? 'Evaluacion actualizada con éxito!' : 'Evaluacion creada con éxito!');
                isEdit ? cargarEvaluaciones() : get_solicitudes()
                onClose();
            })
            .catch((error) => {
                setShowSpinner(false)

                setSuccessSnackbarSeverity('error'); // Se asigna 'success' para casos exitosos
                setSnackbarMessage(error.response.data.error);
                setOpenSnackbar(true);
            });


    };

    const handleCloseModal = () => {
        setOpenModal(false);
        formRef.current.reset()
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            handleCloseModal();
            onClose();
        }
    };


    return (
        <>
            <Modal onKeyDown={handleKeyDown} open={open} onClose={handleCloseModal} >
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", margin: "auto", overflow: 'auto' }}>
                    <Box sx={{ backgroundColor: "#f4f6f8", padding: "40px", borderRadius: "5px" }}>

                        {evaluacion && (
                            <div>
                                <Typography variant="h4" sx={{ color: '#1c3c6a', marginBottom: 2 }}>
                                    Información de la Solicitud:
                                </Typography>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <div style={{ flex: '1 1 50%' }}>
                                        <Typography variant="body1">
                                            <strong>Actividad:</strong> {evaluacion.solicitud.actividad}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Área Responsable:</strong> {evaluacion.solicitud.area_responsable}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Artística Sugerida:</strong> {evaluacion.solicitud.artistica_sugerida}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Cargo Responsable Local:</strong> {evaluacion.solicitud.cargo_responsable_local}
                                        </Typography>
                                    </div>
                                    <div style={{ flex: '1 1 50%' }}>
                                        <Typography variant="body1">
                                            <strong>Contacto Responsable Local:</strong> {evaluacion.solicitud.contacto_responsable_local}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Descripción:</strong> {evaluacion.solicitud.descripcion}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Espacio Colectivo:</strong> {evaluacion.solicitud.espacio_colectivo}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Estado de Solicitud:</strong> {evaluacion.solicitud.estado_solicitud}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <div style={{ flex: '1 1 50%' }}>
                                        <Typography variant="body1">
                                            <strong>Fecha de Evento:</strong> {evaluacion.solicitud.fecha_evento}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Fecha de Solicitud Recibida:</strong> {evaluacion.solicitud.fecha_solicitud_recibida}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Gestión de Solicitud:</strong> {evaluacion.solicitud.gestion_solicitud}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Horario Estimado:</strong> {evaluacion.solicitud.horario_estimado}
                                        </Typography>
                                    </div>
                                    <div style={{ flex: '1 1 50%' }}>
                                        <Typography variant="body1">
                                            <strong>ID de Solicitud:</strong> {evaluacion.solicitud.id}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Lugar:</strong> {evaluacion.solicitud.lugar}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Modalidad:</strong> {evaluacion.solicitud.modalidad}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Municipio:</strong> {evaluacion.solicitud.municipio_nombre}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <div style={{ flex: '1 1 50%' }}>
                                        <Typography variant="body1">
                                            <strong>Nombre Responsable Local:</strong> {evaluacion.solicitud.nombre_responsable_local}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Responsable de Producción:</strong> {evaluacion.solicitud.responsable_produccion}
                                        </Typography>
                                    </div>
                                </div>

                                <Typography variant="h4" sx={{ color: '#1c3c6a', marginBottom: 2 }}>
                                    Información de la Validación:
                                </Typography>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <div style={{ flex: '1 1 50%' }}>
                                        <Typography variant="body1">
                                            <strong> Estado:</strong> {evaluacion.validacion.estado_validacion}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong> Justificación:</strong> {evaluacion.validacion.justificacion}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong> Área de Acción:</strong> {evaluacion.validacion.area_accion}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong> Responsable de Producción:</strong> {evaluacion.validacion.responsable_produccion}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        )}


                        {/* Agrega más campos de solicitud aquí */}
                        <form ref={formRef} onSubmit={handleSubmitEvaluacion}>
                            <Typography variant="h4" sx={{ color: '#1c3c6a', my: 3 }}>
                                {isEdit ? 'Editar evaluacion' : 'Registrar nueva evaluacion en solicitud'}
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="artistica_definida"
                                        label="Artística definida"
                                        defaultValue={initialValues.artistica_definida}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        type="number"
                                        name="inversion_estimada"
                                        label="Inversión estimada"
                                        defaultValue={initialValues.inversion_estimada}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        type="number"
                                        name="cantidad_asistentes_estimada"
                                        label="Cantidad de asistentes estimada"
                                        defaultValue={initialValues.cantidad_asistentes_estimada}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="flyer_difusion"
                                        label="Flyer de difusión"
                                        defaultValue={initialValues.flyer_difusion}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="articulacion_promotora"
                                        label="Articulación con el promotor"
                                        defaultValue={initialValues.articulacion_promotora}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="notas_periodisticas"
                                        label="Notas periodísticas"
                                        defaultValue={initialValues.notas_periodisticas}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="fotos"
                                        label="Fotos"
                                        variant="outlined"
                                        fullWidth
                                        defaultValue={initialValues.fotos}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid container justifyContent="flex-end" item xs={12}>
                                    <Button sx={{ m: 1 }} onClick={onClose} type="button" variant="outlined" color="primary">
                                        Cancelar
                                    </Button>

                                    <Button
                                        sx={{ m: 1 }}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={showSpinner}
                                    >
                                        {showSpinner ? <CircularProgress size={24} /> : 'Enviar'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>

                    </Box>
                </Box>
            </Modal>

            <Snackbar
                open={openSnackbar}
                onMouseEnter={() => setOpenSnackbar(false)}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => setOpenSnackbar(false)}
                severity={successSnackbarSeverity} // Utiliza successSnackbarSeverity en lugar de snackbarSeverity
            >
                <Alert onClose={() => setOpenSnackbar(false)} autoHideDuration={6000} severity={successSnackbarSeverity} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <Snackbar
                open={openGreenSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenGreenSnackbar(false)}
                onMouseEnter={() => setOpenGreenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setOpenSnackbar(false)} autoHideDuration={6000} severity={'success'} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    {greenSnackbarMessage}
                </Alert>
            </Snackbar>

        </>
    );
};

export default ModalEvaluacion;