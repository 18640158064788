import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    user_group: "",
    id: null,
    is_authenticated: false,
    user_modules: []
};

export const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        addUser: (state, action) => {
            state.first_name = action.payload.first_name;
            state.last_name = action.payload.last_name;
            state.email = action.payload.email;
            state.username = action.payload.username;
            state.user_group = action.payload.user_group;
            state.user_modules = action.payload.user_modules;
            state.id = action.payload.id;
        } ,

        addUserModules: (state, action) => {
            state.user_modules = action.payload.user_modules
        },

        changeIsAuthenticated: (state, action) => {
            state.is_authenticated = action.payload.is_authenticated
        },

        getAllAttributes: (state) => {
            return state;
          },
        
        updateUserData: (state, action) => {
        const { first_name, last_name, email, username, id, user_group, is_authenticated } = action.payload;
        state.first_name = first_name !== undefined ? first_name : state.first_name;
        state.last_name = last_name !== undefined ? last_name : state.last_name;
        state.email = email !== undefined ? email : state.email;
        state.username = username !== undefined ? username : state.username;
        state.id = id !== undefined ? id : state.id;
        state.user_group = user_group !== undefined ? user_group : state.user_group;
        state.is_authenticated = is_authenticated !== undefined ? is_authenticated : state.is_authenticated;
        },
    },
})




export const userActions = userSlice.actions;
export default userSlice.reducer;